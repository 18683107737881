import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { fDate } from '../../frontend/common/formatTime'
import Dropdown from 'react-bootstrap/Dropdown';

import AlertToast from '../../../utils/AlertToast'

import DataTableExtensions from "react-data-table-component-extensions";
import { GetTradeHistoryClientsTrad, GetAllClientS, getAllEditSignals, UpdateSignals } from "../../../services";


const EditSignals = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }
    const [client, setClient] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [EditSignalsdata, setEditSignalsdata] = useState([]);
    const [fromdata, setFromDate] = useState('');
    const [todate, setToDate] = useState('');
    const [updatedPri, setUpdatedpri] = useState('')
    const [Id, setId] = useState('')

    // Alert Toast-----------------------------------------

    const [alertMsg, setAlertMsg] = useState(false)
    const [alertColor, setAlertColor] = useState("")
    const [textAlert, setTextAlert] = useState("")



    const hideClose = () => {
        setAlertMsg(false)
    }


    const AuthLogin = localStorage.getItem("user_token");
    const AuthRole = localStorage.getItem("Role")
    const UserId = localStorage.getItem("UserId");

    const Clientdata = async () => {

        var data1 = {
            "admin_id": UserId,
        }
        const response1 = await GetTradeHistoryClientsTrad(data1, AuthLogin);
        // console.log("response1",response1);

        if (response1.status === 200) {
            setClient(response1.data.data);
        // setRefresh(!refresh)

        }
        else {
            console.log("error", response1.status);
        }
    }


    const colums = [
        {
            name: "S No.",
            selector: (row, index) => index + 1,
            width: "80px",
        }, {
            name: 'Username',
            selector: row => row.username
            ,
        },
        {
            name: 'Fullname',
            selector: row => row.fullname,
        },

        {
            name: 'Start Date',
            selector: row => fDate(row.start_date),
        },
        {
            name: 'End Date',
            selector: row => fDate(row.end_date),
        },

        {
            name: "Action",
            cell: row => (<div className='action-btn'>
                <Tooltip title="View Detail" arrow><button className='btn btn-warning  rounded-circle' onClick={(e) => { GetOrderList(row.id, e) }}><i className="fa-solid fa-eye "></i></button></Tooltip>
            </div>),
        },
    ]



    const GetOrderList = async (id, e) => {
        setRefresh(!refresh)
        e.preventDefault();
        setShow(true);
        setId(id)

        const data = {
            "userid": id,
        }
        const response = await getAllEditSignals(data, AuthLogin);
        if (response.data.status) {

            setEditSignalsdata(response.data.data);
        } else {
            setEditSignalsdata([response.data.data]);

        }
    }

    const colums1 = [
        {
            name: 'S.No ',
            selector: (row, index) => index + 1,
            width: '70px'
        },
        {
            name: 'Trading Symbol',
            selector: row => row.symbol,
            width: '180px'
        },
        {
            name: 'Price',
            width: '120px',
            selector: row => row.price,

            // cell: (row) => (
            //     <div>
            //         <input
            //             className="hidebg"
            //             type="number"
            //             style={{ width: '90px' }}
            //             onChange={(e) => {
            //                 // setUpdatedpri(e.target.value)
            //                 // setId(row.id)
            //                 inputChangePrices(e, row.id)
            //             }}
            //             defaultValue={row.price}
            //         />
            //     </div>
            // ),
        },
        {
            name: 'Lot',
            selector: row => row.qty,
            width: '80px',
        },
        // {
        //     name: 'Segment',
        //     selector: row => row.exch_seg
        // },
        {
            name: 'Lot Size',
            selector: row => row.lot
        },
        // {
        //     name: 'Type ',
        //     selector: row => row.type,

        // },
        {
            name: 'Type ',
            cell: (row) => (
                <div>
                    <select name="cars" id="cars" defaultValue={row.type} disabled={row.type_entry_exit != "ENTRY"}
                     onChange={(e) => {
                            // setUpdatedpri(e.target.value)
                            // setId(row.id)
                            inputChangePrices(e, row.order_id)
                        }} >
                        <option value="buy">Buy</option>
                        <option value="sell">Sell</option>
                    </select>
                </div >
            ),

        },
{
    name: 'Expiry',
        selector: row => fDate(row.expiry)
},
    ]



var updatePrice = []
const inputChangePrices = (e, id) => {
    updatePrice.push({
        "id": id,
        "type": e.target.value
    });
}



const updateSignls = async () => {

    const unique = [...new Map(updatePrice.map((m) => [m.id, m])).values()];
    var req = {
        "prices": {
            "admin_id": UserId,
            "user_id": Id,
            "signaldata": unique
        }
    }
    const response = await UpdateSignals(req, AuthLogin);
// console.log("response" ,response);

    if (response.status === 200) {
        // setRefresh(!refresh)
        setAlertMsg(true)
        setAlertColor("success")
        setTextAlert(response.data.message)
        setShow(false);
        window.reload()
        // setTimeout(() => navigate('/admin/client'), 1000)
    }
    else {
        setAlertMsg(true)
        setAlertColor("error")
        setTextAlert(response.data.message)
    }

}




useEffect(() => {
    Clientdata();

}, [todate, fromdata  , refresh])


useEffect(() => {
}, [refresh ])


return (
    <div>
        <div id="conteudo">
            <div className="conteudo-inner">
                <div className="conteudo-wrapper">
                    <div className="conteudo-titleSection">
                        <div className='row'>
                            <div className='col-md-8'>
                                <strong className="conteudo-title">Edit Signals</strong></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="conteudo-box">
                                <div className="conteudo-boxInner p-0">
                                    {/* {client.length == 1 ?
                                     <p>No Data Found</p> : <> */}

                                        <DataTableExtensions

                                            columns={colums}
                                            data={client}
                                            export={false}
                                            print={false}
                                        >
                                            <DataTable
                                                fixedHeader
                                                fixedHeaderScrollHeight="400px"
                                                defaultSortField="id"
                                                defaultSortAsc={false}
                                                pagination
                                                highlightOnHover
                                                paginationRowsPerPageOptions={[10, 50, 100]}
                                                paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }} />

                                        </DataTableExtensions>
                                    {/* </>} */}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>


        <Modal size="lg" show={show} onHide={handleClose} className="trade-history-modal-table">
            <Modal.Header closeButton>

                <button type="button" class="btn-close" aria-label="Close" onClick={handleClose}><i className="fa-solid fa-x"></i></button>
                <Modal.Title>Edit Signals</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {EditSignalsdata.length == 1 ? <p className='text-center'>No Data Found</p> : <>
                    <DataTableExtensions

                        columns={colums1}
                        data={EditSignalsdata}

                        export={false}
                        print={false}
                    >
                        <DataTable
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            defaultSortField="id"
                            defaultSortAsc={false}
                            pagination
                            highlightOnHover
                            paginationRowsPerPageOptions={[10, 50, 100]}
                            paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }} />
                    </DataTableExtensions>
                </>}

                <Button variant="primary" onClick={() => updateSignls()}>
                    Update Price
                </Button>
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
        </Modal>

        {
            alertMsg && (
                <AlertToast
                    hideAlert={hideClose}
                    showAlert={alertMsg}
                    message={textAlert}
                    alertColor={alertColor}
                />
            )
        }

    </div >
)
}

export default EditSignals