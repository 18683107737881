import React, { useEffect, useState } from 'react'
import { GetProfileDetails } from "../../../services";
import { Link } from 'react-router-dom'

const Profile = () => {

  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const [ProfileData, setProfileData] = useState('');
  // console.log("ProfileData", ProfileData);
  const GetProfileInfo = async () => {
    const data = {
      "userid": userid
    }
    const response = await GetProfileDetails(data, accesstoken)
    // console.log("response", response.data);
    if (response.data.status) {
      setProfileData(response.data.data)
    }
  }

  useEffect(() => {
    //     fetch('https://api.ipify.org/').then(
    //   r => r.text()
    // ).then(console.log);
    GetProfileInfo()
  }, [])

  //  set Broker Type 

  const setBrokragetype = (item) => {
    if (item == 1) {
      return "Per Lot"
    }
    else if (item == 2) {
      return "Per Trade"
    }
    else if (item == 3) {
      return "Turn Over"
    }
    else if (item == 4) {
      return "Minimum Brokrage"
    }
  }


  const { username, mobile, email, fullname, end_date, start_date, perlot, turn_over_percentage, pertrade, brokerage } = ProfileData
  return (
    <div>
      {/* <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <strong className="conteudo-title">Admin Profile</strong>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">
                    <div className="student-profile py-4">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="card shadow-sm">
                              <div className="card-header bg-transparent text-center">
                                <img className="profile_img" src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png" alt="" />
                                <h3>{username}</h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="card shadow-sm">
                              <div className="card-header bg-transparent border-0">
                                <h3 className="mb-0"><i className="far fa-clone pr-1" />General Information</h3>
                              </div>
                              <div className="card-body pt-0">
                                <table className="table table-bordered">
                                  <tbody><tr>
                                    <th width="30%">Name</th>
                                    <td width="2%">:</td>
                                    <td>{fullname}</td>
                                  </tr>
                                    <tr>
                                      <th width="30%">Company Name</th>
                                      <td width="2%">:</td>
                                      <td>{company_name}</td>
                                    </tr>
                                    <tr>
                                      <th width="30%">Email	</th>
                                      <td width="2%">:</td>
                                      <td>{email}</td>
                                    </tr>
                                    <tr>
                                      <th width="30%">Mobile</th>
                                      <td width="2%">:</td>
                                      <td>{mobile}</td>
                                    </tr>
                                  </tbody></table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div id="appCapsule">

        <div className="row">
          <div className="col-md-12">
            <div className="conteudo-box">
              <div className="conteudo-boxInner p-0">
                <div className="student-profile py-4">
                  <div className="container">
                  
                    <div className="row ">
                      <div className="col-12">
                      <div className="text-right" >
                        <Link to="/Profile" className='px-3 py-2 rounded mb-0 btn-5' ><i className="fa-solid fa-arrow-left mr-1"></i>Back</Link>
                    </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card shadow-sm">
                          <div className="card-header bg-transparent text-center">
                            <img className="profile_img" src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png" alt="" />
                            <h3>{username}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">

                        <div className="card shadow-sm">
                          <div className="card-header bg-transparent border-0">
                            <h3 className="mb-0"><i className="far fa-clone pr-1" />General Information</h3>
                          </div>
                          <div className="card-body pt-0">
                            <table className="table table-bordered text-center">
                              <tbody><tr>
                                <th width="30%">Name</th>
                                <td width="2%">:</td>
                                <td>{fullname}</td>
                              </tr>

                                <tr>
                                  <th width="30%">Start Date	</th>
                                  <td width="2%">:</td>
                                  <td>{start_date}</td>
                                </tr>

                                <tr>
                                  <th width="30%">End Date</th>
                                  <td width="2%">:</td>
                                  <td>{end_date}</td>
                                </tr>

                              </tbody></table>
                          </div>
                          <div className="card-header bg-transparent border-0">
                            <h3 className="mb-0"><i className="far fa-clone pr-1" />Service Information </h3>
                          </div>
                          <div className="card-body pt-0">
                            <table className="table table-bordered table-responsive  text-center">
                              <tbody><tr>
                                <th className="text-center" width="20%">Brokerage Type</th>

                                <th className="text-center" width="20%">Per Trade  {brokerage == 1 ? <i style={{ color: 'green' }} class="fa-solid fa-check"></i> : ""}</th>

                                <th className="text-center" width="20%">Per Lot  {brokerage == 2 ? <i style={{ color: 'green' }} class="fa-solid fa-check"></i> : ""}</th>

                                <th className="text-center" width="20%">Turn over percentage  {brokerage == 3 ? <i style={{ color: 'green' }} class="fa-solid fa-check"></i> : ""}</th>

                                <th className="text-center" width="20%">Min. Brokrage  {brokerage == 4 ? <i style={{ color: 'green' }} class="fa-solid fa-check"></i> : ""}</th>

                              </tr>
                                <tr>

                                  {/* <td width="30%">{setBrokragetype(brokerage)}	</td> */}
                                  <td width="30%"> Brokerage	</td>

                                  <td>{(brokerage == 1 || brokerage == 4) ? pertrade : "-"}</td>

                                  <td width="2%">{(brokerage == 2 || brokerage == 4) ? perlot : "-"}</td>

                                  <td width="2%">{(brokerage == 3 || brokerage == 4) ? turn_over_percentage : "-"}</td>

                                  {/* <td width="2%">{brokerage == 4 ? turn_over_percentage : "-" }</td> */}

                                  <td></td>

                                </tr>

                                {/* <tr>
                                
                                  <td width="30%">{setBrokragetype(brokerage)}	</td>
                                  <td width="30%">	</td>

                                  <td>{pertrade != "" && perlot != "" && turn_over_percentage != '' ? "-" : pertrade}</td>

                                  <td width="2%">{pertrade != "" && perlot != "" && turn_over_percentage != '' ? "-" : perlot}</td>

                                  <td>{pertrade != "" && perlot != "" && turn_over_percentage != '' ? "-" : turn_over_percentage}</td>

                                  <td>{pertrade != "" || perlot != "" || turn_over_percentage != "" || pertrade == "" && perlot == "" && turn_over_percentage == '' ? "-" : `${perlot}/${pertrade}/${turn_over_percentage}`}</td>

                                </tr> */}

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Profile