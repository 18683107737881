import axios from 'axios';
import React, {useState ,useEffect} from 'react'
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component'
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom'
import {fDateTime} from '../../../component/frontend/common/formatTime'
import { GetTradingStatus } from "../../../services";


const Trading_status = () => {
    const [tradinstatusdata, setTradinstatusdata] = useState([]);
    const AuthLogin = localStorage.getItem("user_token");
    const AuthRole = localStorage.getItem("Role")
  const GetTradeStatusData= async()=>
  {
    // var config = {
    //   method: 'get',
    //   url: 'http://180.149.241.128:5008/api/admin/tradingstatus',
    //   headers: { 
    //     'x-access-token':  AuthLogin,
    //   },
      
    // };
    
    // axios(config)
    // .then(function (response) {
    //   console.log("neha",response);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
    
    const response = await GetTradingStatus(AuthLogin);

    if (response.data.status === true) {
      setTradinstatusdata(response.data.data);
      //console.log("tradinstatusdata11111",response.data.data)
    }
    else {
      console.log("error", response.data);
    }
  }
   const colums=[
    {
      name: 'S. No.',
      selector: (row , index) =>index +1 
      ,
    },
    {
      name:'User Name',
      selector: row => row.username.fullname,
    },
    {
      name:'Panel Status',
      selector: row => row.panel_status,
    },
    {
      name:' Date And Time',
      selector: row => fDateTime(row.createdAt),
    },
   
  
   ]
  useEffect(
    ()=>{
      GetTradeStatusData();
    },[]
  )
  useEffect(() => {
    const table = document.querySelector('.rdt_Table');

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener('dragstart', preventDrag);
      table.addEventListener('dragover', preventDrag);
      table.addEventListener('drop', preventDrag);

      return () => {
        table.removeEventListener('dragstart', preventDrag);
        table.removeEventListener('dragover', preventDrag);
        table.removeEventListener('drop', preventDrag);
      };
    }
  }, []);
    {/*
      name: "Action",
      cell:row=>(  <div className='action-btn'>
    
        <Tooltip title="Edit" arrow><button className='btn btn-success  mr-2 rounded-circle'><i className="fa-solid fa-pen-to-square "></i></button></Tooltip>
 <Tooltip title="Delete" arrow><button className='btn btn-danger  rounded-circle'><i className="fa-solid fa-trash "></i></button></Tooltip>
        </div>),
  */}

  const filterInput = document.querySelector('.filter-text');

// Check if the input element exists
if (filterInput) {
  // Set the new placeholder value
  filterInput.placeholder = "Search Here...";
}
  return (
    <div>
    <div id="conteudo">
      <div className="conteudo-inner">
        <div className="conteudo-wrapper">
          <div className="conteudo-titleSection">
          <div className='row'>
          <div className='col-md-8'>
          <strong className="conteudo-title">Trading Status</strong></div>
          <div className='col-md-4 text-right'>
          </div>
          </div>
           
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="conteudo-box">
                <div className="conteudo-boxInner p-0">
                <DataTableExtensions
                columns={colums}
                data={tradinstatusdata}
                export={false}
                print={false}
              >
                <DataTable
                  fixedHeader
                fixedHeaderScrollHeight="400px"
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                
               
                highlightOnHover
                paginationRowsPerPageOptions={[10, 50, 100]}
                paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }}/>
</DataTableExtensions>
   
               
                </div>
              </div>
            </div>

          </div>
     
       {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  )
}

export default Trading_status