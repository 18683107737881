import React from 'react'
import {Link , NavLink } from 'react-router-dom'

const Footer = () => {
  return (
   <div>
   
    {/* App Bottom Menu */}
    <div className="appBottomMenu">
    <NavLink  to='/watchlist' className="item">
        <div className="col">
        <ion-icon name="bookmarks-outline"></ion-icon>
          <strong>Watchlist</strong>
        </div>
        </NavLink>
        <NavLink  to='/order' className="item">
        <div className="col">
        <ion-icon name="document-text-outline"></ion-icon>
          <strong>Orders</strong>
        </div>
        </NavLink>
      
      <NavLink  to='/dashboard_home' className="item">
        <div className="col">
        <div className="action-button large">
        <ion-icon name="grid-outline"></ion-icon>
                        </div>
       
          <strong>Dashboard</strong>
        </div>
      </NavLink>
      <NavLink  to='/Portfolio' className="item">
        <div className="col">
        <ion-icon name="medkit-outline"></ion-icon>
          <strong>Portfolio</strong>
        </div>
      </NavLink>
      <NavLink  to='/Profile' className="item">
        <div className="col">
          <ion-icon name="people-outline" />
          <strong>Profile</strong>
        </div>
      </NavLink>
    </div>
    {/* * App Bottom Menu */}
  </div>

  )
}

export default Footer