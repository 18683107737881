// import React, { useState, useEffect } from 'react'
// import axios from "axios";
// import CryptoJS from "crypto-js";
// import $ from "jquery";
// import "../dashboard.css"
// import { UserHoldingsFund, GetPosition } from "../../../services";
// import * as Config from '../common/Config';
// import Positions from "./Positons"
// import Holdings from "./Holdings"

// // import { io } from "socket.io-client";

// // const socket = io()

// const Portfolio = () => {

//   // const [getHoldingFund, setGetHoldingFund] = useState([])
//   // const [getInvestedAmmount, setGetInvestedAmmount] = useState('')

//   // const [GetPostionfund, setGetPostionfund] = useState("")
//   // const [holdingCount, setHoldingCount] = useState('')
//   // const [socketUserId, setSocketUserId] = useState('')
//   // const [socketUserToken, setSocketUserToken] = useState('')
//   // // console.log("socketUserId" ,socketUserId);
//   // // console.log("socketUserToken" ,socketUserToken);

//   // // console.log("holdingCount" ,holdingCount);

//   // const [showAlert, setShowAlert] = useState(false);
//   // // const [textAlert, setTextAlert] = useState("");
//   // // const [alertColor, setAlertColor] = useState("");
//   // const [tokenMatch, setTokenMatch] = useState("");
//   // const [tokenPrice, setTokenPrice] = useState("");
//   // const [tokenMatchArray, setTokenMatchArray] = useState([]);
//   // const [arrayobject, setArrayobject] = useState([]);
//   // const [sockets, setSockets] = useState(null);
//   // const [allResponseSocket, setAllResponseSocket] = useState([]);

//   // const UserToken = localStorage.getItem("user_token");
//   // const user_id = localStorage.getItem("UserId");
//   // const AuthRole = localStorage.getItem("Role");

//   // const [auth, setAuth] = useState("");
//   // const [adddata, setAdddata] = useState([]);
//   // const [posts, setPosts] = useState([]);
//   // const [uid, setUid] = useState("");
//   // const [searcdata, setSearcdata] = useState();
//   // // console.log("searcdata", searcdata);
//   // const [watchdata, setWatchdata] = useState([]);
//   // const [refresh, setrefresh] = useState(true);
//   // const [show, setShow] = useState(false);
//   // const [listData, setListData] = useState([]);
//   // // console.log("listData", listData);
//   // const [tokenSymbol, setTokenSymbol] = useState([]);
//   // const [socketuserid, setSocketuserid] = useState("");
//   // const [socketuserSession, setSocketuserSession] = useState("");

//   // /// Socket
//   // var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
//   // let AuthorizationToken;
//   // let type = "API";

//   // function TokenAndClientCode() {
//   //   // alert(token);

//   //   var config = {
//   //     method: "get",
//   //     url: `${Config.base_url}api/alicebluetoken`,
//   //     headers: {},
//   //   };

//   //   axios(config)
//   //     .then(function (response) {
//   //     
//   //       if (response.data.status == "true") {
//   //         //  console.log('Token Available', response.data.data[0].client_code + '  ' + response.data.data[0].access_token);
//   //         setSocketuserid(response.data.data[0].client_code);
//   //         setSocketuserSession(response.data.data[0].access_token);

//   //         invalidateSession(
//   //           response.data.data[0].client_code,
//   //           response.data.data[0].access_token

//   //         );
//   //       } else {
//   //         //  console.log('Token Not Available');
//   //       }
//   //     })
//   //     .catch(function (error) {
//   //       console.log(error);
//   //     });
//   // }

//   // function invalidateSession(userId, userSession) {
//   //   //alert('0');

//   //   AuthorizationToken = "Bearer " + userId + " " + userSession;
//   //   //console.log('AuthorizationToken',AuthorizationToken);
//   //   let jsonObj = {
//   //     loginType: type,
//   //   };
//   //   $.ajax({
//   //     url: BASEURL + "api/ws/invalidateSocketSess",
//   //     headers: {
//   //       Authorization: AuthorizationToken,
//   //     },
//   //     type: "post",
//   //     data: JSON.stringify(jsonObj),
//   //     contentType: "application/json",
//   //     dataType: "json",
//   //     async: false,
//   //     success: function (msg) {
//   //       var data = JSON.stringify(msg);
//   //       if (msg.stat == "Ok") {
//   //         createSession(userId, userSession);
//   //       }
//   //     },
//   //   });
//   // }

//   // function createSession(userId, userSession) {
//   //   //alert('create session')

//   //   AuthorizationToken = "Bearer " + userId + " " + userSession;

//   //   //console.log('AuthorizationToken cratesession',AuthorizationToken);

//   //   let jsonObj = {
//   //     loginType: type,
//   //   };
//   //   $.ajax({
//   //     url: BASEURL + "api/ws/createSocketSess",
//   //     headers: {
//   //       Authorization: AuthorizationToken,
//   //     },
//   //     type: "post",
//   //     data: JSON.stringify(jsonObj),
//   //     contentType: "application/json",
//   //     dataType: "json",
//   //     async: false,
//   //     success: function (msg) {
//   //       var data = JSON.stringify(msg);
//   //       if (msg.stat == "Ok") {
//   //         connect(userId, userSession, "");
//   //       } else {
//   //         alert(msg);
//   //       }
//   //     },
//   //   });
//   // }

//   // const url = "wss://ws1.aliceblueonline.com/NorenWS/";
//   // let socket;
//   // function connect(userId, userSession, token = "") {
//   //   // abcc(token)

//   //   socket = new WebSocket(url);

//   //   // else{
//   //   //   console.log('elseeee')
//   //   // }

//   //   socket.onopen = function () {
//   //     connectionRequest(userId, userSession);
//   //     //alert('okk socket open')
//   //     //  console.log('token ffffffff',token)

//   //     setSockets(socket)

//   //     //  console.log('xyzzzzz',xyz(socket,token))
//   //   };
//   //   socket.onmessage = function (msg) {
//   //     var response = JSON.parse(msg.data);

//   //     if (response.s == "OK") {
//   //       // console.log("response portfolio --", response);

//   //       var listDataPrice = [];

//   //       listData && listData.forEach((element) => {

//   //         console.log("portfolio --", element);

//   //         // var channel = 'NFO|248399#NFO|55272#NFO|53522';

//   //         var channel = "NFO|" + element;
//   //         //console.log("channel", channel);
//   //         let json = {
//   //           k: channel,
//   //           t: "t",
//   //         };

//   //         socket.send(JSON.stringify(json));

//   //         socket.onmessage = function (msg) {
//   //           var response = JSON.parse(msg.data);
//   //           // console.log('response socket ggg', response)

//   //           if (response.tk) {

//   //             if (response.bp1 !== undefined) {

//   //               listDataPrice.push(response.bp1)

//   //               //  if (response.lp == undefined) {
//   //               //   listDataPrice.push(response.bp1);
//   //               // } else if (response.lp == undefined || response.bp1 == undefined) {
//   //               //   listDataPrice.push(response.sp1);
//   //               // } else {
//   //               //   listDataPrice.push(response.lp);
//   //               // }
//   //               // console.log('listDataPrice', listDataPrice)

//   //              
//   //               var keys = listData;
//   //               var values = listDataPrice;
//   //               var result = {};
//   //               keys.forEach((key, i) => (result[key] = values[i]));

//   //               if (listData.length == listDataPrice.length) {
//   //                 setArrayobject(result);
//   //               }

//   //             }

//   //             if (response.c && response.tk) {
//   //               // array.some(code => code.name === 'js'); // true
//   //               if (!allResponseSocket.some(code => code.tk === response.tk)) {
//   //                 console.log('response.tk', response.tk)
//   //                 setAllResponseSocket(current => [...current, response])
//   //               }
//   //             }

//   //             // console.log('response socket inside', response)
//   //             setTokenMatch(response.tk);
//   //             setTokenPrice(response.lp);

//   //             console.log("check response sssss- ", response);

//   //           }
//   //         };
//   //       });
//   //     }
//   //   };
//   // }

//   // var rr = 0;
//   // if (arrayobject && arrayobject) {
//   //   console.log("arrayobject ", arrayobject);
//   //   // sss(arrayobject)
//   //   rr = 1;
//   // }

//   // if (allResponseSocket && allResponseSocket) {
//   //   //console.log('allResponseSocket', allResponseSocket)

//   // }

//   // function connectionRequest(userId, userSession) {
//   //   var encrcptToken = CryptoJS.SHA256(
//   //     CryptoJS.SHA256(userSession).toString()
//   //   ).toString();
//   //   // alert(encrcptToken);
//   //   var initCon = {
//   //     susertoken: encrcptToken,
//   //     t: "c",
//   //     actid: userId + "_" + type,
//   //     uid: userId + "_" + type,
//   //     source: type,
//   //   };
//   //   // console.log('initCon', initCon);
//   //   socket.send(JSON.stringify(initCon));
//   // }

//   // // Socket End

//   // const GetOrderList = async () => {

//   //   const data = {
//   //     "userid": user_id
//   //   }
//   //   const response = await UserHoldingsFund(data, UserToken);
//   //   setGetHoldingFund(response.data.data)
//   //   setHoldingCount(response.data.data.length)

//   //   if (response.data.status) {
//   //     let initialValue = 0;
//   //     var tokenArr = [];
//   //     response.data.data.map((total) => {
//   //       initialValue += parseFloat(total.invest_amount);
//   //       tokenArr.push(total.token);
//   //       setListData(tokenArr);
//   //     }
//   //     )
//   //     return setGetInvestedAmmount(initialValue)
//   //   }

//   // };

//   // const GetPositionData = async () => {

//   //   const data = {
//   //     "userid": user_id
//   //   }
//   //   const response = await GetPosition(data, UserToken);
//   //   // console.log('get postiob', response)
//   //   if (response.data.status) {
//   //     setGetPostionfund(response.data.data)
//   //   }
//   //   else {
//   //     setGetPostionfund([response.data.data])
//   //   }

//   // };
//   // useEffect(() => {
//   //   GetPositionData()
//   //   GetOrderList()
//   //   TokenAndClientCode()
//   // }, [sockets])

//   // function UpDownPrice(token) {

//   //   var rr;
//   //   allResponseSocket && allResponseSocket.forEach(element => {
//   //     if (element.tk == token) {
//   //       rr = element.c
//   //     }
//   //   });
//   //   return rr
//   // }

//   // function colorprice(currentAmount, investAmount) {
//   //   // var res;
//   //   // allResponseSocket && allResponseSocket.forEach(element => {
//   //   //   if (element.tk == token) {
//   //   //     res = val - element.c;
//   //   //   }
//   //   // });

//   //   // if (res > 0) {
//   //   //   return "green"
//   //   // } else if (res < 0) {
//   //   //   return "red"
//   //   // } else {
//   //   //   return "black"
//   //   // }

//   //   if (currentAmount >= investAmount) {
//   //     return "green"
//   //   } else if (currentAmount < investAmount) {
//   //     return "red"
//   //   } else {
//   //     return "black"
//   //   }

//   // }

//   // function UpDownAero(currentAmount, investAmount) {
//   //   var total = parseFloat(currentAmount) - parseFloat(investAmount);
//   //   if (total > 0) {
//   //     return " ↑ "
//   //   } else if (total < 0) {
//   //     return " ↓ "
//   //   } else {
//   //     return ""
//   //   }

//   // }

//   // function totalReturnPercentage(currentAmount, investAmount) {

//   //   var val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
//   //   return '(' + val + '% )';
//   // }

//   // var currentAmountValue = 0;

//   // getHoldingFund && getHoldingFund.map((item) => {
//   //   const { invest_amount, qty, symbol, token, lot } = item

//   //   rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
//   //     k == token ? currentAmountValue += v * lot * qty : ""
//   //   )) : "";

//   //   //setGetcurrentAmountValue(currentAmountValue)

//   //   // console.log("currentAmountValue", currentAmountValue)
//   // })

//   return (
//     <div id="appCapsule">
//       {/* pilled tab */}
//       <div className="tab-pane fade show active" id="pilled" role="tabpanel">

//         <div class=" p-0">
//           <div className="section full">
//             <div className="section-title">Portfolio</div>
//           </div>
//           <ul class="nav nav-tabs lined" role="tablist">
//             <li class="nav-item">
//               <a class="nav-link active" data-toggle="tab" href="#photos" role="tab">
//                 Positions
//               </a>
//             </li>
//             <li class="nav-item">
//               <a class="nav-link" data-toggle="tab" href="#videos" role="tab">
//                 Holdings
//               </a>
//             </li>
//           </ul>
//         </div>
//         {/* * Extra Header */}
//         {/* App Capsule */}
//         <div className="extra-header-active">
//           <div className="tab-content mt-1">
//             {/* photos tab */}
//             <div className="tab-pane fade show active" id="photos" role="tabpanel">
//               <div className="section full mt-1">
//                 <Positions />
//               </div>
//             </div>
//             <div className="tab-pane fade" id="videos" role="tabpanel">
//               <Holdings/>
//             </div>
//           </div>
//         </div>
//       </div >

//     </div >
//   )
// }

// export default Portfolio

import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Positions from "./Positons";
import Holdings from "./Holdings";
import { useLocation } from "react-router-dom";
import { GetPosition, UserHoldingsFund } from "../../../services";
// import Sonnet from '../../components/Sonnet';

function Portfolio() {
  const [key, setKey] = useState("Positions");
  const [forRefresh, setForRefresh] = useState(false);

  const [holdingCount, setHoldingCount] = useState(0);
  const [positionCount, setPositionCount] = useState(0);
  const user_id = localStorage.getItem("UserId");
  const UserToken = localStorage.getItem("user_token");

  const GetPositionCount = async () => {
    const data = {
      userid: user_id,
    };
    const response = await GetPosition(data, UserToken);

    setPositionCount(response.data.data.length);
  };

  const GetholdingCount = async () => {
    const data = {
      userid: user_id,
    };
    const response = await UserHoldingsFund(data, UserToken);

    setHoldingCount(response.data.data.length);
  };




  useEffect(() => {
    GetPositionCount();
    GetholdingCount();
  }, []);

  


  const setKeyFunction = () => {
    if (key == "Positions") {
      // setForRefresh("Positions")
      setForRefresh(!forRefresh);
      // alert("position")
    } else {
      //  alert("Holdings")
      setForRefresh(!forRefresh);
      // /      setForRefresh("Holdings")
    }
  };

  useEffect(() => {
    setKeyFunction();
  }, [key]);


  
  return (
    <div id="appCapsule">
      <div className="section full mt-1">
        <div className="wide-block pt-2 pb-2">
          <div className="row">
            <div className="col-md-7 col-12 mx-auto">
              <ul className="nav nav-tabs style1" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#position"
                    role="tab"
                    aria-selected="true"
                  >
                    <i className="fa-solid fa-house-circle-xmark pr-1"></i>
                    Positions({positionCount && positionCount})
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#holding"
                    role="tab"
                    aria-selected="false"
                  >
                    <i className="fa-solid fa-hands-holding pr-1"></i>
                    Holdings({holdingCount && holdingCount})
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="tab-content mt-2">
            <div
              className="tab-pane fade active show"
              id="position"
              role="tabpanel"
            >
              <Positions forRefresh={forRefresh} />
            </div>
            <div className="tab-pane fade" id="holding" role="tabpanel">
              <Holdings forRefresh={forRefresh} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
