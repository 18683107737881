import axios from 'axios';
import React, {useState ,useEffect} from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component'

const Add_strategy_client = () => {
  const [student, setStudent] = useState([]);
  const getstudent= async()=>
  {
    try{
      const response =await axios.get("https://restcountries.com/v2/all")
     setStudent(response.data);
  }
  catch(error)
  {console.log(error)}
  }
   const colums=[
    {
      name:'Client UserName',
      selector: row => row.name,
    },
    
  
   ]
  useEffect(
    ()=>{
      getstudent();
    },[]
  )
  return (
    <div id="conteudo" className='add-stratergy-to-client'>
    <div className="conteudo-inner">
      <div className="conteudo-wrapper">
        <div className="conteudo-titleSection">
        <div className='row'>
        <div className='col-md-8'>
        <strong className="conteudo-title">Add Strategy</strong>
        </div>
        <div className='col-md-4 text-right'>
        <Link to="/admin/stratergy" className='px-3 py-2 rounded mb-0 btn-5'><i className="fa-solid fa-arrow-left mr-1"></i>Back</Link>

        </div>
        </div>
         
        </div>
        <div className="row">
          <div className="col-md-12">
          <DataTable
          columns={colums}
          data={student}
          fixedHeader
                fixedHeaderScrollHeight="400px"
        defaultSortField="id"
        defaultSortAsc={false}
        selectAllRowsItem
        selectableRows
        highlightOnHover
/>
          
          </div>

        </div>
   
     {/*   <div className="conteudo-box mt-4">
          <div className="conteudo-boxInner">
            
          </div>
        </div> */}
      </div>
    </div>
  </div>

  )
}

export default Add_strategy_client