import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ChangeAdminPassword } from "../../services/login.service";
import AlertToast from "../../utils/AlertToast";

const ChangePassword = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [alertColor, setAlertColor] = useState("");

    const [oldpassword, setOldpassword] = useState("");
    const [oldpasswordErr, setOldpasswordErr] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [newpasswordErr, setNewpasswordErr] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [confirmpasswordErr, setConfirmpasswordErr] = useState("");

    const [oldPasswordType, setOldPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const navigate = useNavigate();

    const onAlertClose = () => {
        setShowAlert(false);
    };

    const AuthLogin = localStorage.getItem("user_token");
    const AuthRole = localStorage.getItem("Role");
    const UserId = localStorage.getItem("UserId");

    const submit = async () => {
        if (oldpassword === "") {
            setOldpasswordErr("Please Enter Old Password");
            return;
        }
        if (newpassword === "") {
            setNewpasswordErr("Please Enter New Valid Password");
            return;
        }
        if (confirmpassword === "") {
            setConfirmpasswordErr("Please Enter Confirm Valid Password");
            return;
        }
        if (newpassword !== confirmpassword) {
            setShowAlert(true);
            setTextAlert("New password and Confirm Password are Not Matched");
            setAlertColor("error");
            return;
        }

        const request = {
            id: UserId,
            old_password: oldpassword,
            password: newpassword,
        };
        const response = await ChangeAdminPassword(request, AuthLogin);

        if (response.status === 200) {
            setShowAlert(true);
            setTextAlert(response.data.message);
            setAlertColor("success");
            setTimeout(() => navigate("/admin/dashboard"), 1000);
        } else {
            setShowAlert(true);
            setTextAlert(response.data.message);
            setAlertColor("error");
        }
    };

    const toggleOldPasswordVisibility = () => {
        setOldPasswordType(oldPasswordType === "password" ? "text" : "password");
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordType(newPasswordType === "password" ? "text" : "password");
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
    };

    
  
   

    return (
        <div >
            <div>
                <div
                    style={{
                        height: "200px",
                        background: "#2F55A4",
                        borderRadius: "0px 0px 20px 20px",
                    }}
                ></div>

                <div className="login-form mt-3">
                    <div className="section mt-3">
                        <img src="assets/img/logo.png" alt="logo" className="form-image" />
                    </div>
                    <div className="section mt-1">
                        <h3>Change Password</h3>
                    </div>
                    <div className="section full mt-2 mb-2">
                        <div className="wide-block pt-2 pb-3">
                            <div className="form-group boxed">
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="oldpassword">
                                        Old Password
                                    </label>
                                    <input
                                        type={oldPasswordType}
                                        className="form-control"
                                        placeholder="Old Password"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setOldpassword(e.target.value);
                                            setOldpasswordErr("");
                                        }}
                                    />
                                    <i
                                        className={
                                            oldPasswordType === "text"
                                                ? "fa-solid fa-eye-slash password-eye"
                                                : "fa-solid fa-eye password-eye"
                                        }
                                        onClick={toggleOldPasswordVisibility}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Password Visibility"
                                    ></i>
                                    <p className="text-danger text-left">{oldpasswordErr}</p>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="newpassword">
                                        New Password
                                    </label>
                                    <input
                                        type={newPasswordType}
                                        className="form-control"
                                        placeholder="New Password"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setNewpassword(e.target.value);
                                            setNewpasswordErr("");
                                        }}
                                    />
                                    <i
                                        className={
                                            newPasswordType === "text"
                                                ? "fa-solid fa-eye-slash password-eye"
                                                : "fa-solid fa-eye password-eye"
                                        }
                                        onClick={toggleNewPasswordVisibility}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Password Visibility"
                                    ></i>
                                    <p className="text-danger text-left">{newpasswordErr}</p>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="confirmpassword">
                                        Confirm Password
                                    </label>
                                    <input
                                        type={confirmPasswordType}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Confirm Password"
                                        onChange={(e) => {
                                            setConfirmpassword(e.target.value);
                                            setConfirmpasswordErr("");
                                        }}
                                    />
                                    <i
                                        className={
                                            confirmPasswordType === "text"
                                                ? "fa-solid fa-eye-slash password-eye"
                                                : "fa-solid fa-eye password-eye"
                                        }
                                        onClick={toggleConfirmPasswordVisibility}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Password Visibility"
                                    ></i>
                                    <p className="text-danger text-left">{confirmpasswordErr}</p>
                                </div>
                            </div>
                            <div className="mt-2 frame">
                                <button className="custom-btn btn-5 btn-block mb-2" onClick={submit}>
                                    <span>
                                        Change Password<i className="fa-solid fa-arrow-right-long pl-2"></i>
                                    </span>
                                </button>
                                <Link to="/dashboard_home">Back to dashboard</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAlert && (
                <AlertToast
                    hideAlert={onAlertClose}
                    showAlert={showAlert}
                    message={textAlert}
                    alertColor={alertColor}
                />
            )}
        </div>
    );
};

export default ChangePassword;
