import React from 'react'
import {Link} from 'react-router-dom'

const Message_broadcast = () => {
  return (
    <div id="conteudo">
      <div className="conteudo-inner">
        <div className="conteudo-wrapper">
          <div className="conteudo-titleSection">
          <div className='row'>
          <div className='col-md-8'>
          <strong className="conteudo-title">Message Broadcast</strong></div>

          </div>
           
          </div>
          <div className="row">
            <div className="col-md-12">

              
             <div className="section full mt-2 mb-2">
             {/*<div className="section-title">Boxed</div>*/}
               <div className="wide-block pb-1 pt-2 row px-2">
             
               <div className="form-group boxed col-sm-6">
               <div className="input-wrapper">
                 <label className="label" htmlFor="city5">Broadcast to</label>
                 <select className="form-control custom-select" id="city5">
                   <option value={0}>Subadmin</option>
                   <option value={1}>Client</option>
                 </select>
               </div>
             </div>
                   
                  
                   <div className="form-group boxed ">
                   <div className="input-wrapper">
                     <label className="label" htmlFor="phone5">Message</label>
                     <textarea  rows="4" className="form-control"></textarea>
                     
                   </div>
                 </div>
                 <div className="form-group boxed ">
                 <button  className='px-3 mt-4 rounded mb-0 btn-5'>Submit<i className="fa-solid fa-hand-pointer ml-1"></i></button>
                </div>
             

             

   
               
                </div>
              </div>
            </div>

          </div>
     
       {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Message_broadcast