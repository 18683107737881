import React, { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import jwtDecode from 'jwt-decode'
import AdminNotification from "./AdminNotification/AdminNotification"
import { TextField } from '@mui/material';
import * as Config from '../frontend/common/Config';
import axios from "axios";
import { confirm } from "react-confirm-box";
import { getSystemUpdate } from '../../services';


const Header = () => {

  // const currentUrlhost = window.location.href;
  // const currenthost = currentUrlhost.split("#")[0];
  const userid = localStorage.getItem('UserId');

  const location = useLocation()
  const navigate = useNavigate();
  const name = localStorage.getItem("UserName");
  const user_token = localStorage.getItem("user_token");

  const [secretKeyAlice, setSecretKeyAlice] = useState("")
  const [appIdAlice, setAppIdAlice] = useState("")
  const [adminTradingType, setAdminTradingType] = useState("");

  const [App_id, setApp_id] = useState("");
  const [ApiSercretKey, setApiSercretKey] = useState("");


  // console.log("ApiSercretKey", ApiSercretKey);
  // console.log("App_id", App_id);

  const [getBrokerKey, setGetBrokerKey] = useState([])

  const [refreshscreen, setRefreshscreen] = useState(false);

  // console.log("getBrokerKey app_id",getBrokerKey.app_id)
  // console.log("getBrokerKey api_secret",getBrokerKey.api_secret)

  // console.log("secretKeyAlice",secretKeyAlice)
  // console.log("appIdAlice",appIdAlice)



  const [updatelogo,setUpdatelogo] = useState("")
 


  const getipdatedata = async () => {

    const data = {id: userid };


    try {
        const response = await getSystemUpdate(data);
        if (response.status) {
            console.log("getting ", response.data);
            setUpdatelogo(response.data[0].company_logo)
        } else {
            console.log("errr");
        }
    } catch (error) {
        console.error("Error in getipdatedata:", error);
    }
};


useEffect(() => {
    getipdatedata();
}, []);


  const { exp } = jwtDecode(user_token)
  // console.log("exp", exp);
  const ExpirationTime = (exp * 1000) - 60000
  if (Date.now() >= ExpirationTime) {
    localStorage.removeItem("user_token");
    localStorage.removeItem("Role");
    localStorage.removeItem("UserId");
    localStorage.removeItem("UserName");
    navigate('/');
  }

  const logout = () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("Role");
    localStorage.removeItem("UserId");
    localStorage.removeItem("UserName");
    navigate('/');
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  }
  useEffect(() => {
    alicebluetoken_etBrokerKey()

  }, [refreshscreen]);


  function alicebluetoken_etBrokerKey() {
    // alert(token);
    var config = {
      method: "get",
      url: `${Config.base_url}api/alicebluetoken/getBrokerKey`,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        
        if (response.data.status == "true") {
          setGetBrokerKey(response.data.data[0])
          // setSecretKeyAlice(response.data.data[0].app_id)
          // setAppIdAlice(response.data.data[0].api_secret)
          setAdminTradingType(response.data.data[0].trading)
          // --------

          setApiSercretKey(response.data.data[0].api_secret)
          setApp_id(response.data.data[0].app_id)

        } else {
          //  console.log('Token Not Available');
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }



  const UpdateBrokerKey = (e) => {
    e.preventDefault();




    var data = {
      "app_id": App_id,
      "api_secret": ApiSercretKey
    };

    // console.log("dat -", data)

    var config = {
      method: 'post',
      url: `${Config.base_url}api/updateAdminBrokerKey`,
      headers: {
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        //   console.log("fffff", response.data);
        setRefreshscreen(!refreshscreen);
      })
      .catch(function (error) {
        console.log(error);
      });
    setShow(false)
  }


  const Brokertoggle = (e, getBrokerKey) => {
    // console.log("admiintokendetails ", admiintokendetails[0].client_code)
    if (e.target.checked == true) {
      if (getBrokerKey.broker == 1) {
        window.location.href = "https://ant.aliceblueonline.com/?appcode=" + getBrokerKey.app_id;
      }
    } else {
      tradingOffAdminToken();
    }
  };


  async function tradingOffAdminToken() {
    var r = await confirm("Are you sure you want off trading ?");
    if (r == true) {
      var config = {
        method: 'get',
        url: `${Config.base_url}api/alicebluetoken/tradingOff`
      };
      axios(config).then(function (response) {
        // console.log("openpostionapihit", response.data);
        setRefreshscreen(!refreshscreen);
      })
        .catch(function (error) {
          console.log(error);
        });
    }
  }



  return (
    <div>
      <div id="hit-menu" className="col-xs-hide " />
      <div className="menu-mobile-background " />
      <div id='header' className={`${location.pathname === '/admin/adchangepassword' ? 'd-none' : 'd-block'}`}>
        <div className="header-inner">
          <div className="header-topo d-flex align-items-center">
            <div className='container-fluid'>
              <div className='row '>
                <div className='col-2 col-md-3 pr-0'>
                  <span className="header-nomeEmpresa header-nomeEmpresa_mobile col-xs-hide col-sm-hide" style={{ width: '200px' }}>
                    <a href="#" >
                      {/* <img src="../../images/bearbulls.png" style={{ width: '100%' }} /> */}
                       {updatelogo && <img src={updatelogo} alt="Updated Logo" style={{ width: "75%" }} />}

                    </a>
                  </span>

                  <button className="button-menu-mobile ml-0">
                    <div className="box-stripes">
                      <span className="stripe-top" />
                      <span className="stripe-middle" />
                      <span className="stripe-bottom" />
                    </div>
                  </button>
                </div>

                <div className="col-6 col-md-5 d-flex align-items-center justify-content-start ps-lg-5 pl-0">
                &nbsp;&nbsp;&nbsp;&nbsp;
                  <Button variant="primary" onClick={handleShow}>
                    Set Key
                  </Button>
                  &nbsp;&nbsp;
                  <label className="switch">
                    <input
                      type="checkbox"
                      // checked={isToggled}
                      // onChange={handleToggle}
                      checked={adminTradingType == 1 ? true : false}
                      onChange={(e) => {
                        Brokertoggle(e, getBrokerKey);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                  {/* <div className="switch ml-3">
                    <Form.Check
                      id="switch-1"
                      className="broker_switch mb-2 switch-input"
                      size="lg"
                      type="switch"
                      name="trading"
                      checked={adminTradingType == 1 ? true : false}
                      onChange={(e) => {
                        Brokertoggle(e, getBrokerKey);
                      }}
                    />
                    <label for="switch-1" class="switch-label">Switch</label>
                  </div> */}
                  <h5 className="mb-0 ml-2 login-text">Login with Get Live Price</h5>
                </div>

                <div className='col-4 col-md-4 d-flex align-items-center justify-content-end'>



                  {/* <div className="navbar-wrapper text-end">
                      <h5>Login with Get Live Price</h5>
                      <Form.Check
                        className="broker_switch mb-2"
                        size="lg"
                        type="switch"
                        name="trading"
                       checked={adminTradingType == 1 ? true : false}
                       onChange={(e) => {
                        Brokertoggle(e, getBrokerKey);
                       }}
                      
                      />
                      </div> */}


                  <AdminNotification />
                  <div className="dropdown mx-2 ">
                    <button className="btn btn-primary dropdown-toggle px-1 px-lg-2 bg-white" type="button" data-toggle="dropdown" aria-expanded="false">
                      <div className="d-flex align-items-center">
                        <div className="image-wrapper d-flex">
                          <ion-icon name="person-circle-outline" style={{ marginRight: '0px' }}></ion-icon>
                        </div>
                        <div className="">
                          <strong>{name}</strong>
                        </div>
                      </div>
                    </button>
                    <div className="dropdown-menu w-100" style={{}}>
                      {/*<button className='btn btn-light w-100' >Profile</button> */}
                      <Link to='/admin/adchangepassword' className='btn btn-light w-100'>Change Password</Link>
                      <Link to='/admin/profile' className='btn btn-light w-100' >Profile</Link>
                      <div className="dropdown-divider"></div>
                      <button className='btn btn-light w-100' onClick={logout}>Logout</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>API Process Alice Blue</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p><b>API Process Alice Blue - </b> Kindly click on below mention brokerage firm link it will redirect to your concern brokerage API link and generate API with this. Kindly follow instruction as your broker or sub broker link guide to you and update our link and connect your demat with our Algo software</p>
          <br />
          <p><b>Step 1 - </b> Click blow link and Login </p>
          <p>https://ant.aliceblueonline.com/?appcode=G9EOSWCEIF9ARCB</p>
          <br />
          <p><b>Step 2:-</b> Enter your Details and Redirect url</p>
          <p>{`${Config.base_url}AliceBlue`}</p>
          <br />
          <p><b>Step 3:-</b> Create Api </p>
          <p>you will get Api Secret Key And App code please Update</p>

          <TextField
            id="outlined-required"
            label="SECRET KEY"
            defaultValue={ApiSercretKey}
            onChange={(e) => setApiSercretKey(e.target.value)}
          />
          <TextField
            id="outlined-required"
            label="APP ID"
            defaultValue={App_id}
            onChange={(e) => setApp_id(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => UpdateBrokerKey(e)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Header