import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Tooltip from '@mui/material/Tooltip';
import { fDate } from '../../frontend/common/formatTime';
import { GetAllClientS, Activestatus } from "../../../services";
import { Link, useNavigate } from 'react-router-dom'
import DataTableExtensions from "react-data-table-component-extensions";
import swal from "sweetalert";




const Client = () => {
  const [client, setClient] = useState([]);
  const navigate = useNavigate();
  const EditClient = (rowId) => {
    navigate('/admin/edit_client/' + rowId)
  }

  const AuthLogin = localStorage.getItem("user_token");
  const AuthRole = localStorage.getItem("Role")
  const UserId = localStorage.getItem("UserId");

  const Clientdata = async () => {
    var data = {
      "admin_id": UserId,
    }

    const response = await GetAllClientS(data, AuthLogin);
    if (response.data.status) {
      setClient(response.data.data);
    }

    const filterInput = document.querySelector('.filter-text');

    // Check if the input element exists
    if (filterInput) {
      // Set the new placeholder value
      filterInput.placeholder = "Search Here...";
    }

    // var config = {
    //   method: 'post',
    //   url: 'http://180.149.241.128:5008/api/admin/getallClient',
    //   headers: {
    //     'x-access-token': AuthLogin,
    //     'Content-Type': 'application/json'
    //   },
    //   data: data
    // };

    // axios(config)
    //   .then(function (response) {
    //     //console.log(JSON.stringify(response.data));
    //     console.log("clinet", response.data.data);
    //     setClient(response.data.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }

  const updateactivestatus = async (event, id) => {
    const status = event.target.checked ? 1 : 0;
    try {
        const confirmSave = await swal({
            title: 'Are you sure?',
            text: "Do you want to save the changes?",
            icon: 'warning',
            buttons: ["No, cancel", "Yes!"],
            dangerMode: true,
        });

        if (confirmSave) {
            const response = await Activestatus({ user_id: id, status });
            if (response.status) {
                swal("Saved!", "", "success");
                Clientdata();
            }
        } else {
         
        }
    } catch (error) {
        swal("There was an error processing your request.", "", "error");
    }
};


  const colums = [
    {
      name: 'S. No.',
      selector: (row, index) => index + 1
      ,
    },

    {
      name: 'User Name',
      selector: row => row.username
      ,
    },
    {
      name: 'Full Name',
      selector: row => row.fullname,
    },
    {
      name: 'ActiveStatus',
      selector: row => (
        <label className="form-check form-switch">
          <input
            id={`rating_${row.id}`}
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={(event) => updateactivestatus(event, row.id)}
            checked={row.is_active === 1}
          />
          <label htmlFor={`rating_${row.id}`} className="checktoggle checkbox-bg"></label>
        </label>
      ),
    },

      {
      name: 'Created At Date',
      selector: row => fDate(row.createdAt),
    },
    {
      name: 'End Date',
      selector: row => fDate(row.end_date),
    },

    {
      name: "Action",
      cell: row => (<div className='action-btn'>

        <Tooltip title="Edit" arrow><button className='btn btn-success  mr-2 rounded-circle' onClick={() => EditClient(row.id)}><i className="fa-solid fa-pen-to-square "></i></button></Tooltip>
        {/* <Tooltip title="Delete" arrow><button className='btn btn-danger  rounded-circle'><i className="fa-solid fa-trash "></i></button></Tooltip> */}
      </div>),
    },
  ]
  useEffect(
    () => {
      Clientdata();
    }, []
  )

  useEffect(() => {
    const table = document.querySelector('.rdt_Table');

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener('dragstart', preventDrag);
      table.addEventListener('dragover', preventDrag);
      table.addEventListener('drop', preventDrag);

      return () => {
        table.removeEventListener('dragstart', preventDrag);
        table.removeEventListener('dragover', preventDrag);
        table.removeEventListener('drop', preventDrag);
      };
    }
  }, []);
  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <div className='row'>
                <div className='col-md-8'>
                  <strong className="conteudo-title">Client</strong></div>
                <div className='col-md-4 text-right'>
                  <Link to="/admin/add_client" className='px-3 py-2 rounded mb-0 btn-5'>Add Client<i className="fa-solid fa-plus ml-1"></i></Link>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">

                    <DataTableExtensions
                      columns={colums}
                      data={client}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination


                        highlightOnHover
                        paginationRowsPerPageOptions={[10, 50, 100]}
                        paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }} />
                    </DataTableExtensions>



                  </div>
                </div>
              </div>

            </div>

            {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Client