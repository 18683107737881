import React from 'react'
import { Link } from 'react-router-dom';

const Add_strategy = () => {
  return (
    <div id="conteudo">
      <div className="conteudo-inner">
        <div className="conteudo-wrapper">
          <div className="conteudo-titleSection">
          <div className='row'>
          <div className='col-md-8'>
          <strong className="conteudo-title">Add Strategy</strong>
          </div>
          <div className='col-md-4 text-right'>
          <Link to="/admin/stratergy" className='px-3 py-2 rounded mb-0 btn-5'><i className="fa-solid fa-arrow-left mr-1"></i>Back</Link>

          </div>
          </div>
           
          </div>
          <div className="row">
            <div className="col-md-12">

              
             <div className="section full mt-2 mb-2">
             {/*<div className="section-title">Boxed</div>*/}
               <div className="wide-block pb-1 pt-2 row px-2">
             
             
                   
                  
                   <div className="form-group boxed col-6">
                   <div className="input-wrapper">
                     <label className="label" htmlFor="phone5">Enter Strategy Name</label>
                     <input type="text"  className="form-control" placeholder='Strategy Name'/>
                     
                   </div>
                 </div>
                 <div className="form-group boxed ">
                 <button  className='px-3 mt-3 rounded mb-0 btn-5'>Submit<i className="fa-solid fa-hand-pointer ml-1"></i></button>
                </div>
             

             

   
               
                </div>
              </div>
            </div>

          </div>
     
       {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
        </div>
      </div>
    </div>

  )
}

export default Add_strategy