import React, { useState, useEffect, useRef } from "react";
import {
  useNavigate,
  Link,
  useParams,
  useLocation,
  NavLink,
} from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import CryptoJS from "crypto-js";
import * as Config from "../common/Config";
import { UserAvailabeFunds, getmargiRreqPerUser } from "../../../services";
import AlertToast from "../../../utils/AlertToast";
import { useForm } from "react-hook-form";
import socketIOClient from 'socket.io-client';
const BuySell = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { id } = useParams();
  const [fund, setfund] = useState("");
  const [sockets, setSockets] = useState(null);
  const [tokenMatch, setTokenMatch] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [tokenPC, setTokenPC] = useState("");
  const [tokenPCAero, setTokenPCAero] = useState("");

  const [stockLot, setStockLot] = useState("");
  const [stockBuyPrice, setStockBuyPrice] = useState("");
  const [stockSellPrice, setStockSellPrice] = useState("");
  const [requiredFund, setRequiredFund] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityeer, setQuantityerr] = useState("");
  const [nfomargin, setNfomargin] = useState("");
  const [mcxmargin, setMcxmargin] = useState("");
  const [cdsmargin, setCdsmargin] = useState("");
  // const [typesubmit, setTypesubmit] = useState("");
  const [allResponseSocket, setAllResponseSocket] = useState([]);

  // const [avalableFund, setAvalableFund] = useState(0)
  const [alertMsg, setAlertMsg] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [textAlert, setTextAlert] = useState("");
  const [avalableFund, setAvalableFund] = useState(0);
  const [ButtonDisabled, setButtonDisabled] = useState(false);

  const qauntityRef = useRef(0);

  const hideClose = () => {
    setAlertMsg(false);
  };

  const handleKeyPress = (event) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleKeyPressForexCrypto = (event) => {
    if (!/[\d.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const location = useLocation();
  const locationDatanew = location.state;

 


  const endpoint = Config.base_url;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Establish WebSocket connection when component mounts
    const socket = socketIOClient(endpoint);

    // Define event handlers
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('receive_data_forex', (message) => {
   
      if (message.type != undefined && message.type == 'crypto') {
          setTokenMatch(message.data[1]);
          setTokenPrice(message.data[6].toFixed(2));
          //  console.log("message.data",message.data)
          $(".showdatalp" + message.data[1]).html(message.data[6].toFixed(3));
          setStockBuyPrice(message.data[5].toFixed(3));
          setStockSellPrice(message.data[8].toFixed(3));
          setStockLot(100000);
        
       
       }
       else if (message.type != undefined && message.type == 'forex') {
          setTokenMatch(message.data[1]);
          setTokenPrice(message.data[5].toFixed(2));
          // console.log("message.data",message.data)
          $(".showdatalp" + message.data[1]).html(message.data[6].toFixed(6));
          setStockBuyPrice(message.data[4].toFixed(6));
          setStockSellPrice(message.data[7].toFixed(6));
          setStockLot(100000);
         
       
       }


    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    // Clean up WebSocket connection when component unmounts
    return () => {
      socket.disconnect();
    };
  }, [endpoint]); // Only re-run effect if endpoint changes


  // console.log("locationDatanew",locationDatanew)


  const url = "wss://ws1.aliceblueonline.com/NorenWS/";
  let socket;

  // locationData.stock.expiry

  var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
  let AuthorizationToken;
  let type = "API";

  //  ge tUser Avilable Fund

  function getAvailableFund(userid) {
    var data = JSON.stringify({
      userid: userid,
    });

    var config = {
      method: "post",
      url: `${Config.base_url}api/useravailabefunds`,
      headers: {
        "x-access-token": accesstoken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == true) {
          setAvalableFund(response.data.availablefund);
        }
      })
      .catch(function (error) {
        //   console.log(error);
      });
  }

  const getUserAvilableFund = async () => {
    const data = {
      userid: userid,
    };
    const response = await UserAvailabeFunds(
      data,
      accesstoken,
      setButtonDisabled(true)
    );

    if (response) {
      setButtonDisabled(false);
      setfund(response.data.availablefund.toFixed(2));
    }
  };

  const getMarginReqPer = async () => {
    const data = {
      userid: userid,
    };
    const response = await getmargiRreqPerUser(data, accesstoken);

    if (response.data.status) {
      setNfomargin(response.data.data[0].NFO);
      setMcxmargin(response.data.data[0].MCX);
      setCdsmargin(response.data.data[0].CDS);
    }
  };

  useEffect(() => {
    if (locationDatanew.userId != "") {
      setQuantity(locationDatanew.qty);
      qauntityRef.current = locationDatanew.qty;
      connect(
        locationDatanew.userId,
        locationDatanew.userSession,
        locationDatanew.token,
        locationDatanew.segment,
        locationDatanew.qty
      );
    }

    getUserAvilableFund();
    getMarginReqPer();
    if (userid) {
      getAvailableFund(userid);
    }
  }, []);



  function connect(userId, userSession, token = "", exch_seg = "") {
    socket = new WebSocket(url);
   
    socket.onopen = function () {
      connectionRequest(userId, userSession);

      setSockets(socket);
    };
    socket.onmessage = function (msg) {
      var response = JSON.parse(msg.data);
      
      if (response.s == "OK") {
        // var tokensss = 53402
        var channel = exch_seg + "|" + token;
        //console.log("channel", channel);
        let json = {
          k: channel,
          t: "t",
        };
        
        socket.send(JSON.stringify(json));
        
        socket.onmessage = function (msg) {
           
          var response = JSON.parse(msg.data);
          // console.log('response socket ggg sssss', response)
          
          if (response.tk) {
            setTokenMatch(response.tk);

            if (response.lp != undefined) {
              setTokenPrice(response.lp);
              //enterLot("",locationDatanew.qty);
            }

            if (response.lp != undefined) {
              $(".showdatalp" + response.tk).html(response.lp);
            }

            if (response.pc != undefined) {
              setTokenPC(response.pc);
              
              if (response.pc > 0) {
                setTokenPCAero(" ↑ ");
              } else if (response.pc < 0) {
                setTokenPCAero(" ↓ ");
              } else if (response.pc == 0) {
                setTokenPCAero("");
              }
            }

            if (response.ls) {
              setStockLot(response.ls);
              // console.log("response",response)
              // enterLot("",locationDatanew.qty);
            }

            if (response.bp1) {
              setStockBuyPrice(response.bp1);
            }

            if (response.sp1) {
              setStockSellPrice(response.sp1);
            }

            if (response.c && response.tk) {
              // array.some(code => code.name === 'js'); // true
              if (!allResponseSocket.some((code) => code.tk === response.tk)) {
                // console.log('response.tk', response.tk)
                setAllResponseSocket((current) => [...current, response]);
              }
            }
          }
        };
      }
    };
  }

  function connectionRequest(userId, userSession) {
    var encrcptToken = CryptoJS.SHA256(
      CryptoJS.SHA256(userSession).toString()
    ).toString();
    // alert(encrcptToken);
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: userId + "_" + type,
      uid: userId + "_" + type,
      source: type,
    };
    // console.log('initCon', initCon);
    socket.send(JSON.stringify(initCon));
  }

  const enterLot = (e, value) => {
    if (value) {
      setQuantity(value);
      qauntityRef.current = value;
      var totalrequiredfundwithoutmargin = value * stockLot  * tokenPrice;

      var marginvalue = "";
      marginvalue = 13;
     

      var margintotalrequiredfund =
        (totalrequiredfundwithoutmargin / 100) * parseInt(marginvalue);
      
      setRequiredFund(margintotalrequiredfund.toFixed(2));
    } else {
      setRequiredFund("");
      setQuantity("");
    }
  };

  useEffect(() => {
    enterLot("", qauntityRef.current);
  }, [stockLot, tokenPrice, quantity]);

  const confirm = () => {

    if (
      quantity == "" ||
      quantity === "0" ||
      quantity === "00" ||
      quantity === "000" ||
      quantity === "0000" ||
      quantity === "00000" ||
      quantity === "000000" ||
      quantity === "0000000" ||
      quantity === "00000000" ||
      quantity === "000000000" ||
      quantity === "0000000000" ||
      quantity === "00000000000"
    ) {
      setQuantityerr("Please enter a valid qty");
      return;
    }
    setButtonDisabled(true);
    var marginvalue = 13;

    var price = 0;
    var totalamount = 0;

    if (locationDatanew.type_submit == "buy") {
      price = stockSellPrice;
      totalamount = (
        (stockSellPrice *
          locationDatanew.lot *
          quantity *
          parseInt(marginvalue)) /
        100
      ).toFixed(2);

      var data = {
        userid: userid,
        symbol: locationDatanew.symbol,
        qty: quantity,
        type: locationDatanew.type_submit,
        price: price,
        expiry: locationDatanew.expiry,
        totalamount: totalamount,
        lot: locationDatanew.lot,
        avalableFund: avalableFund,
        token: locationDatanew.token,
        segment: locationDatanew.segment,
      };
        
      if (stockSellPrice && stockSellPrice) {
        // setButtonDisabled(false)
        paceorder_confirm(data);
      } else {
        setButtonDisabled(false);
        setAlertMsg(true);
        setAlertColor("error");
        setTextAlert("Order is not Placed");
      }
    } else if (locationDatanew.type_submit == "sell") {
      price = stockBuyPrice;
      totalamount = (
        (stockBuyPrice *
          locationDatanew.lot *
          quantity *
          parseInt(marginvalue)) /
        100
      ).toFixed(2);
      var data = {
        userid: userid,
        symbol: locationDatanew.symbol,
        qty: quantity,
        type: locationDatanew.type_submit,
        price: price,
        expiry: locationDatanew.expiry,
        totalamount: totalamount,
        lot: locationDatanew.lot,
        avalableFund: avalableFund,
        token: locationDatanew.token,
        segment: locationDatanew.segment,
      };

      if (stockBuyPrice && stockBuyPrice) {
        // setButtonDisabled(false)

        paceorder_confirm(data);
      } else {
        setButtonDisabled(false);

        setAlertMsg(true);
        setAlertColor("error");
        setTextAlert("Order is not Placed");
      }
    }
  };

  function paceorder_confirm(data) {
    // setButtonDisabled(true)

    var config = {
      method: "post",
      url: `${Config.base_url}api/placeorder`,
      headers: {
        "x-access-token": accesstoken,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          setButtonDisabled(false);

          setAlertMsg(true);
          setAlertColor("success");
          setTextAlert(response.data.message);
          setTimeout(() => navigate("/order"), 1000);
        } else {
          setButtonDisabled(false);
          setAlertMsg(true);
          setAlertColor("error");
          setTextAlert(response.data.message);
          //   setTimeout(() => navigate('/watchlist'), 1000)
        }
      })
      .catch(function (error) {
        // alert('error')
        setButtonDisabled(false);

        setAlertMsg(true);
        setAlertColor("error");
        setTextAlert(error.data.message);
      });
  }

  function colorpriceNew(pcValue) {
    if (pcValue > 0) {
      return "green";
    } else if (pcValue < 0) {
      return "red";
    } else if (pcValue == 0) {
      return "black";
    }
  }

  return (
    <div>
      {" "}
      <div id="appCapsule">
        <div className="section full">
          <div className="">
            <div className="section-title w-100 justify-content-start">
              {" "}
              <span>
                <Link to="/Portfolio" className="pr-1 py-2 rounded mb-0">
                  <i class="fa-regular fa-arrow-left"></i>
                </Link>
              </span>
              Trade Buy/Sell
            </div>

            {/* <div className="col-md-4 text-right">
              <Link to="/Portfolio" className="px-3 py-2 rounded mb-0 btn-5">
                <i className="fa-solid fa-arrow-left "></i>
              </Link>
            </div> */}
          </div>
           
          <div className="d-flex flex-row justify-content-center mt-2 mb-4">
            <h3 className="m-0 w-auto">{locationDatanew.symbol}</h3>
            {tokenPC != "" ? (
              <>
                <span className="mx-2"> &#8377;</span>
              </>
            ) : (
              ""
            )}
          
            <div style={{ fontSize: "12px", color: colorpriceNew(tokenPC) }}>
              <span className={"showdatalp" + locationDatanew.token}></span>
              <span>
                {tokenPC != ""
                  ? " ( " + tokenPC + " % )  " + tokenPCAero + " Depth"
                  : ""}
              </span>
            </div>
          </div>

          <div className="d-flex col-12 flex-column mt-2">
            <div className="d-flex col-12 py-2 align-items-center ">
              {/* <h4 className="d-flex flex-grow-1 px-2">Qty <span className='px-2' style={{ fontWeight: '900' }}> {locationDatanew.segment}</span></h4> */}
              <h4 className="d-flex flex-grow-1 ">
                Lot Size :
                <span className="px-2" style={{ fontWeight: "900" }}>
                  {" "}
                  {locationDatanew.lot}
                </span>
              </h4>
              <div className=" w-50">
                <input
                  type="number"
                  className="form-control w-100"
                  id="email5"
                  onKeyPress={
                    locationDatanew.segment === "FOREX" ||
                    locationDatanew.segment === "CRYPTO"
                      ? handleKeyPressForexCrypto
                      : handleKeyPress
                  }
                  defaultValue={locationDatanew.qty}
                  placeholder="Lot"
                  min="0.01"
                  onLoad="enterLot()"
                  onChange={(e) => {
                    enterLot(e, e.target.value);
                    setQuantityerr("");
                  }}
                  // onChange={(e) => { setPasswordAdd(e.target.value); setPasswordErr("") }}
                />
                <p className="mb-0 bg-danger">
                  {" "}
                  {quantityeer ? quantityeer : ""}
                </p>
              </div>
            </div>

            <div className="d-flex col-12 py-2  justify-content-start">
              <h4 className="d-flex flex-grow-1">
                Price :{" "}
                <span className="px-2" style={{ fontWeight: "900" }}>
                  {" "}
                  Market
                </span>
              </h4>
              <input
                type="number"
                className="form-control w-50"
                id="email5"
                placeholder="At market"
                value="AT MARKET"
                disabled={true}
                // onChange={(e) => { setPasswordAdd(e.target.value); setPasswordErr("") }}
              />
            </div>
            {/*  <div>
                        <h4 className="d-flex flex-grow-1 px-2">Lot Size<span className='px-2' style={{ fontWeight: '900' }}> {locationDatanew.lot}</span></h4>
                    </div> */}

            <div className="row px-2 ">
              <div className="col-6">
                {" "}
                <h4
                  className="text-left fw-bolder"
                  style={{ fontWeight: "900" }}
                >
                  Balance : &#8377; {fund}
                </h4>
              </div>
              <div className="col-6 text-left">
                {" "}
                <p className="fw-bolder" style={{ fontWeight: "900" }}>
                  Required Fund : {requiredFund && requiredFund}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group boxed mt-5">
          <button
            className={`btn mb-1 d-flex w-50 mx-auto ${
              locationDatanew.type_submit === "buy"
                ? "btn-success"
                : "btn-danger"
            }`}
            disabled={ButtonDisabled}
            onClick={() => confirm()}
          >
            {locationDatanew.type_submit === "buy" ? "BUY" : "SELL"}
            <i className="fa-solid fa-hand-pointer ml-1"></i>
          </button>
        </div>
      </div>
      {alertMsg && (
        <AlertToast
          hideAlert={hideClose}
          showAlert={alertMsg}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default BuySell;
