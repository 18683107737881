import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getsigninuser ,deletesigindata} from "../../../services/admin.service";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import { fDate } from "../../frontend/common/formatTime";
import swal from 'sweetalert'

const SignIn = () => {
  const [client, setClient] = useState([]);
  const navigate = useNavigate();
  const [currentClient, setCurrentClient] = useState(null);
  const [refersh,setRefersh] = useState(false)


  const EditClient = (rowId) => {
    const clientData = client.find((item) => item.id === rowId);
    setCurrentClient(clientData);

    navigate("/admin/add_client", { state: { clientData } });
  };



  const colums = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "User Name",
      selector: (row) => row.username,
    },
    {
      name: "Full Name",
      selector: (row) => row.fullname,
    },
    {
      name: "Phone No.",
      selector: (row) => row.mobile,
    },
    {
      name: "Password",
      selector: (row) => row.show_password,
    },
    {
      name: "Updated At",
      selector: (row) => {
        const date = new Date(row.updatedAt);
        return date.toLocaleDateString("en-GB"); // 'en-GB' locale formats date as day/month/year
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="action-btn">
          <Tooltip title="Edit" arrow>
            <button
              className="btn btn-success mr-2 rounded-circle"
              onClick={() => EditClient(row.id)}
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </button>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <button
              className="btn btn-danger rounded-circle"
              onClick={() => DeleteClient(row.id)}
            >
              <i className="fa-solid fa-trash"></i>
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];
  



  
// delete for user 
async function DeleteClient(id) {
  try {
    const confirmation = await swal({
      title: "Do you want to delete this client?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (confirmation) {
      const response = await deletesigindata({ id: id });
      
      if (response.status) {
        swal("Deleted!", response.data.message, "success");
        getuserdata();
      } else {
        swal("Failed", "Client deletion failed!", "error");
       
      }
    } else {
      swal("Cancelled", "Client deletion was cancelled!", "info");
    }
  } catch (err) {
    swal("Error", "An error occurred during client deletion.", "error");
    
  }
}




// get data by sign user 
  const getuserdata = async () => {
    try {
      const response = await getsigninuser({});
      if (response.status) {
        setClient(response.data.users);
      
      } else {
        console.error("Failed response status:", response);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const filterInput = document.querySelector('.filter-text');

  // Check if the input element exists
  if (filterInput) {
    // Set the new placeholder value
    filterInput.placeholder = "Search Here...";
  }
  useEffect(() => {
    getuserdata();
  }, []);

 


  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <div className="row">
                <div className="col-md-8">
                  <strong className="conteudo-title">Client Request</strong>
                </div>
              
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">
                    <DataTableExtensions
                      columns={colums}
                      data={client}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        paginationRowsPerPageOptions={[10, 50, 100]}
                        paginationComponentOptions={{
                          selectAllRowsItem: true,
                          selectAllRowsItemText: "All",
                        }}
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
