
import * as Config from "../utils/Config";
import axios from "axios";

// const accesstoken = localStorage.getItem("user_token");


export function OrderList(data, token) {

    return axios.post(`${Config.baseUrl}api/AllgetUserOrderList`, data, {
        // return axios.post(`http://180.149.241.128:5008/api/AllgetUserOrderList`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

export function UserAvailabeFunds(data, token) {

    return axios.post(`${Config.baseUrl}api/useravailabefunds`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


 // User Pending Request BAlance
export function PendingUserAvailabeFunds(data, token) {

    return axios.post(`${Config.baseUrl}api/userpendingwithdrawl`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



export function UserHoldingsFund(data, token) {

    return axios.post(`${Config.baseUrl}api/holdings`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}




export function UserStatmentList(data, token) {

    return axios.post(`${Config.baseUrl}api/user/statementlist`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



export function GetProfileDetails(data, token) {

    return axios.post(`${Config.baseUrl}api/profile`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}





// -------------------------------------------- Admin Dashboard --------------------------------------------------------------




//  GetAll Admin 


export function GetAllClientS(data, token) {

    return axios.post(`${Config.baseUrl}api/admin/getallClient`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  TradeHistory


export function GetTradeHistoryClientsTrad(data, token) {

    return axios.post(`${Config.baseUrl}api/admin/getalltradeClient`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  TradeHistory


export function GetTradeHistoryClients(data, token) {

    return axios.post(`${Config.baseUrl}api/admin/showClientTradehistory`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

//services 

export function GetAllServices(token) {

    return axios.get(`${Config.baseUrl}api/admin/allservices`, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export function Activestatus(data, token) {

    return axios.post(`${Config.baseUrl}api/admin/ClientStatus`, data, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



//    Update Widrow Fund (Pending)


export function RequestPaymentWithdrawal(data, token) {
    return axios.post(`${Config.baseUrl}api/requestpaymentwithdrawal`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}




// ----------------------------------------------------------------- PortFolio -> posiotion -----------------------------------------------------------------------------


export function GetPosition(data, token) {

    return axios.post(`${Config.baseUrl}api/positions`, data, {

        headers: {
            'x-access-token': token,
        },
        'Content-Type': 'application/json',
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


// Trading status

export function GetTradingStatus(token) {

    return axios.get(`${Config.baseUrl}api/admin/tradingstatus`, {

        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}
//Reports



export function GetReports(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/reports`, data, {
        headers: {
            'x-access-token': token,
            // 'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



//Edit_client
export function GetEditData(data, token) {
    return axios.post(`${Config.baseUrl}api/client/update`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}
///dashboard
export function GetUserData(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/getAllUserDetailsCount`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

///dashboard
export function GetLicenseAccount(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/licencecount`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

// -----------------get Margin User End ------------------

export function getmargiRreqPerUser(data, token) {
    return axios.post(`${Config.baseUrl}api/margiRreqPerUser`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



//  -----------------  Admin Require  ------------------


export function getmargiRreqPerAdmin(data, token) {
    return axios.post(`${Config.baseUrl}api/margiRreqPerAdmin`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  Update Admin margin persentage

export function UpdateExitTimeAdmin(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/updateExitTime`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  Update Admin margin persentage

export function UpdatemargiRreqPerAdmin(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/updateMargin`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  get Exit Time Admin Details


export function getExitTimeAdminDetails(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/adminExitTime`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  get Exit Time Admin Details


export function getAllAvailablePostions(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/allposition`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

//  get All Edit Signals


export function getAllEditSignals(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/editsignals`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  Update  Edit Signals


export function UpdateSignals(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/editsignalsprice`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



// ------------------------------------ Widrow   -------------------------------

//    get Widrow histry (Pending)


export function getUserPendingAmmount(data, token) {
    return axios.post(`${Config.baseUrl}admin/getuserwithdrawalhistoriesPending`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//    get Widrow histry (Complete)


export function getUserCompleteAmmount(data, token) {
    return axios.post(`${Config.baseUrl}admin/getuserwithdrawalhistoriesCompelete`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//    get Widrow histry (Reject)


export function getUserRejectAmmount(data, token) {
    return axios.post(`${Config.baseUrl}admin/getuserwithdrawalhistoriesReject`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

//    Update Widrow Fund (Pending)


export function getUserUpdateAmmount(data, token) {
    return axios.post(`${Config.baseUrl}admin/withdrawalstatusupdate`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  client - fund hisotry

export function ClientFundHistory(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/fundhistorydata`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  Admin Fund Deposite / Wirthdrowal  Notification

export function AdminNotifiaction(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/storenotification`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//Get ALL  Notification admin

export function GetAllAdminNotifiaction(data, token) {
    return axios.post(`${Config.baseUrl}admin/getallPendinghistory`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}

//Get ALL  Notification Client 

export function GetAllClientNotifiaction(data, token) {
    return axios.post(`${Config.baseUrl}api/user/getallhistory`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//  



export function getNiftyBankNiftyToken(data, token) {
    return axios.get(`${Config.baseUrl}Banknifty-nifty-token`, {
        headers: {
            // 'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


//For Widhraw Page

export function getWithrawData(data, token) {
    return axios.post(`${Config.baseUrl}admin/userbalanceWithdrawl`, data, {
        headers: {
            'x-access-token': token,
            'Content-Type': 'application/json'
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


// Entry Token

export function EntryTokenEnterJson() {

    return axios.get(`${Config.baseUrl}EntryTokenEnterJson`, {

        headers: {
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


// system update 

export function SystemUpdate(data) {

    
    return axios.post(`${Config.baseUrl}api/system`,data, {

        headers: {
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



//  get system update 


export function getSystemUpdate(data) {
    
    return axios.get(`${Config.baseUrl}api/systemget`,data,{

        headers: {
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


// user sign in data

export function signinuser(data) {
  
    return axios.post(`${Config.baseUrl}api/signinuser`,data,{

        headers: {
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export function getsigninuser(data) {
    
    return axios.get(`${Config.baseUrl}api/getsigninuserdetail`,data,{

        headers: {
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



export function deletesigindata(data) {
    
    return axios.post(`${Config.baseUrl}api/deletesignindata`,data,{

        headers: {
        },
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}