import React from 'react';

const Payment = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img 
        src="images/Scanner.jpeg" 
        alt="Payment" 
        style={{ height: '300px', objectFit: 'cover' }}
      />
    
    </div>
  );
}

export default Payment;
