import React, {useState,useEffect}from 'react'
import { useNavigate, Link, useParams, useLocation, NavLink } from 'react-router-dom'
import axios from 'axios'
import * as Config from '../common/Config';
import { fDate } from '../../../component/frontend/common/formatTime'
import $ from "jquery";
import CryptoJS from "crypto-js";


import AlertToast from "../../../utils/AlertToast";
import socketIOClient from 'socket.io-client';

const Confirm_trade = () => {

  
  const [alertMsg, setAlertMsg] = useState(false)
  const [alertColor, setAlertColor] = useState("")
  const [textAlert, setTextAlert] = useState("")
  const [avalableFund, setAvalableFund] = useState(0)
  const [sockets, setSockets] = useState(null);
  const [stockBuyPrice, setStockBuyPrice] = useState("");
  const [stockSellPrice, setStockSellPrice] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleClickDisabled = () => {
    setDisabled(true);
  }
  

  const hideClose = () => {
    setAlertMsg(false)
  }




  const location = useLocation();
  const locationDatanew = location.state;
  // console.log("locationData nnnnn -", locationDatanew)
  // console.log("locationData nnnnn stokckdetails -", locationDatanew.locationData)
  // console.log("locationData nnnnn stokckdetails -", locationDatanew.locationData.userId)
  // console.log("locationData nnnnn stokckdetails -", locationDatanew.locationData.userSession)


  const endpoint = Config.base_url;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Establish WebSocket connection when component mounts
    const socket = socketIOClient(endpoint);

    // Define event handlers
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('receive_data_forex', (message) => {
      // console.log('Received message:', message);
      // console.log('Received message:', message[1]);
   
      if (message.type != undefined && message.type == 'crypto') {
        if (locationDatanew.locationData.stock.token == message.data[1]) {
            setStockBuyPrice(message.data[5].toFixed(3));
            setStockSellPrice( message.data[8].toFixed(3));   
        }
      }
      else if (message.type != undefined && message.type == 'forex') {
        if (locationDatanew.locationData.stock.token == message.data[1]) {
          setStockBuyPrice(message.data[4].toFixed(6));
          setStockSellPrice( message.data[7].toFixed(6));   
        }
      }

    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    // Clean up WebSocket connection when component unmounts
    return () => {
      socket.disconnect();
    };
  }, [endpoint]); // Only re-run effect if endpoint changes


  

  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const url = "wss://ws1.aliceblueonline.com/NorenWS/";
  let socket;

 


  var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
  let AuthorizationToken;
  let type = "API";



  useEffect(() => {
    if (locationDatanew.locationData.userId != "") {
      connect(locationDatanew.locationData.userId, locationDatanew.locationData.userSession, locationDatanew.locationData.stock.token,locationDatanew.locationData.stock.exch_seg)
    }

  }, []);

  useEffect(() => {

  }, [sockets]);


  function connect(userId, userSession, token = "",exch_seg = "") {


    socket = new WebSocket(url);


    socket.onopen = function () {
      connectionRequest(userId, userSession);

      setSockets(socket)


    };
    socket.onmessage = function (msg) {

      var response = JSON.parse(msg.data);


      if (response.s == "OK") {
        //alert(response.bp1)

        // console.log("response socket next url sss", response);


        // var tokensss = 53402
        var channel = exch_seg+"|"+ token;
        //console.log("channel", channel);
        let json = {
          k: channel,
          t: "t",
        };

        socket.send(JSON.stringify(json));

        socket.onmessage = function (msg) {
          var response = JSON.parse(msg.data);
        //  console.log('response Confirm trade page', response)



          if (response.tk) {

           //  codee  
          
           if (response.bp1 != undefined) {
             setStockBuyPrice(response.bp1);
          }

          if (response.sp1 != undefined) {
             setStockSellPrice(response.sp1);   
          }

          }
        };

       





      }
    };
  }


  function connectionRequest(userId, userSession) {
    var encrcptToken = CryptoJS.SHA256(
      CryptoJS.SHA256(userSession).toString()
    ).toString();
    // alert(encrcptToken);
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: userId + "_" + type,
      uid: userId + "_" + type,
      source: type,
    };
    // console.log('initCon', initCon);
    socket.send(JSON.stringify(initCon));
  }



 

  const navigate = useNavigate();
  const backbtn = () => {
    navigate("/stock_detail",{ state: locationDatanew.locationData });
  }

    useEffect(() => {
      if (userid) {
        getAvailableFund(userid)
      }
    }, []);

   function getAvailableFund(userid){
    var data = JSON.stringify({
      "userid": userid
    });
    
    var config = {
      method: 'post',
      url: `${Config.base_url}api/useravailabefunds`,
      headers: { 
        'x-access-token': accesstoken, 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
     // console.log(JSON.stringify(response.data));
      // console.log("dd",typeof response.data.availablefund);
      if(response.data.status == true){
        setAvalableFund(response.data.availablefund)
      }
    })
    .catch(function (error) {
      console.log(error);
    });
      

   }

  
  //  console.log("locationDatanew.totalamount",locationDatanew.totalamount);
// console.log("Tokennnnnn",locationDatanew.locationData.stock.token)

  const confirm = () => {

    // buy sp1 price
    // sell bp1 price

    // alert(stockBuyPrice)
    // alert(stockSellPrice)
    var totalamount;
    var price;

    if(locationDatanew.type == 'buy'){
     // alert(stockSellPrice)
      totalamount = ((((stockSellPrice * locationDatanew.stockLot * locationDatanew.Quantity)*parseInt(locationDatanew.marginvalue))/100)).toFixed(2);
      price = stockSellPrice;

      // console.log("setAvalableFund",avalableFund);
  
    var data = {
      userid: userid,
      symbol: locationDatanew.locationData.stock.symbol,
      qty: locationDatanew.Quantity,
      type: locationDatanew.type,
      price: price,
      expiry: locationDatanew.locationData.stock.expiry,
      totalamount: totalamount,
      lot: locationDatanew.stockLot,
      avalableFund:avalableFund,
      token:locationDatanew.locationData.stock.token,
      segment:locationDatanew.segment
     };
     
  
     if(stockSellPrice && stockSellPrice){

      paceorder_confirm(data)

     }else{
      setAlertMsg(true)
      setAlertColor("error")
      setTextAlert("Limit is not Set")
     }



    }
    else if(locationDatanew.type == 'sell'){
    //  alert(stockBuyPrice)
      totalamount = ((((stockBuyPrice * locationDatanew.stockLot * locationDatanew.Quantity)*parseInt(locationDatanew.marginvalue))/100)).toFixed(2);
      price = stockBuyPrice;

      // console.log("setAvalableFund",avalableFund);
  
      var data = {
        userid: userid,
        symbol: locationDatanew.locationData.stock.symbol,
        qty: locationDatanew.Quantity,
        type: locationDatanew.type,
        price: price,
        expiry: locationDatanew.locationData.stock.expiry,
        totalamount: totalamount,
        lot: locationDatanew.stockLot,
        avalableFund:avalableFund,
        token:locationDatanew.locationData.stock.token,
        segment:locationDatanew.segment
      };


      if(stockBuyPrice && stockBuyPrice){
      
        paceorder_confirm(data)
  
       }else{
        setAlertMsg(true)
        setAlertColor("error")
        setTextAlert("Limit is not Set")
       }




    }


    

    // console.log("confirm button is working.....");



  }
     

 function paceorder_confirm(data){
  
  
  var config = {
    method: "post",
    url: `${Config.base_url}api/placeorder`,
    headers: {
      "x-access-token": accesstoken,
    },
    data: data,
  };

  

  axios(config)
    .then(function (response) {
     
      if (response.data.status) {
        setAlertMsg(true)
        setAlertColor("success")
        setTextAlert(response.data.message)
        setTimeout(() => navigate('/order'), 1000)
      }else{
        setAlertMsg(true)
        setAlertColor("error")
        setTextAlert(response.data.message)
        setTimeout(() => navigate('/watchlist'), 1000)
      }

    })
    .catch(function (error) {
      setAlertMsg(true)
      setAlertColor("error")
      setTextAlert(error.data.message)
    });

 }




  return (
    <div id="appCapsule">

      <div className="section full mb-2">

        <div className="wide-block p-0 border-0">
          <div className="section-title d-block text-left px-0"><span><button onClick={backbtn}><i class="fa-regular fa-arrow-left"></i></button></span>Confirm Trade</div>
          <div className="table-responsive">
            <table className="table table-borderless">

              <tbody>
                <tr>

                  <td>Stock Name</td>
                  <td>{locationDatanew.locationData
                    .stock.symbol_name
                  }</td>
                </tr>
                <tr>

                  <td>Expiry</td>

                  <td>

                    {fDate(locationDatanew.locationData
                      .stock.expiry)
                    }

                  </td>
                </tr>
                <tr>

                  <td>Market lot size</td>
                  <td>{locationDatanew.stockLot}</td>
                </tr>
                <tr>

                  <td>Lot Qty</td>
                  <td>
                    {locationDatanew.Quantity}
                  </td>
                </tr>
               
                <tr>
                  <td>Type</td>
                  <td>
                    {locationDatanew.type=="buy" ? "BUY" : "SELL"}
                  </td>
                </tr>

                <tr>
                  <td>Price</td>
                  <td>
                    {locationDatanew.type == "buy" ? stockSellPrice : stockBuyPrice}
                  </td>
                 </tr>
                
                

                <tr>
                  <td>Required Funds</td>
                  <td>
                    {locationDatanew.type == "buy" ? ((((stockSellPrice * locationDatanew.stockLot * locationDatanew.Quantity)*parseInt(locationDatanew.marginvalue))/100)).toFixed(2) : ((((stockBuyPrice * locationDatanew.stockLot * locationDatanew.Quantity)*parseInt(locationDatanew.marginvalue))/100)).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="wide-block pt-2 pb-1 d-flex justify-content-center">
          {/* do not forget to delete mr-1 and mb-1 when copy / paste codes */}



          <button type="button" className="btn confirm-trade btn-success mr-1 mb-1 w-25 d-block" disabled={disabled} onClick={() =>{ confirm(); handleClickDisabled()}}><p className='mb-0'>Confirm</p>

          </button>


        </div>

      </div>

      {
        alertMsg && (
          <AlertToast
            hideAlert={hideClose}
            showAlert={alertMsg}
            message={textAlert}
            alertColor={alertColor}
          />
        )
      }
    </div>
  )
}

export default Confirm_trade