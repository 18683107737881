import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import AlertToast from "../../../utils/AlertToast";
import * as Config from "../../../utils/Config";
import { useLocation } from "react-router-dom";

const Add_client = () => {
  var user_token = localStorage.getItem("user_token");
  var user_Id = localStorage.getItem("UserId");
  const navigate = useNavigate();

  const location = useLocation();
  const clientData = location.state?.clientData || {};



  const [usenameAdd, setUsenameAdd] = useState(clientData.username || "");
  const [fullnameAdd, setFullnameAdd] = useState(clientData.fullname || "");
  const [passwordAdd, setPasswordAdd] = useState(clientData.password || "");
  const [confirmPasswordAdd, setConfirmPasswordAdd] = useState(clientData.password || "");
  const [licenceToMonthAdd, setLicenceToMonthAdd] = useState("1");
  const [clientCreatedByAdd, setClientCreatedByAdd] = useState("Admin");
  const [rechargeAdd, setRechargeAdd] = useState("0");
  const [limitAdd, setLimitAdd] = useState("1");
  const [usenameErr, setUsenameErr] = useState("");
  const [fullnameErr, setFullnameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [licenceToMonthErr, setLicenceToMonthErr] = useState("");
  const [clientCreatedByErr, setClientCreatedByErr] = useState("");
  const [rechargeErr, setRechargeErr] = useState("");
  const [limitErr, setLimitErr] = useState("");
  const [tradeRadio, settradeRadio] = useState(true);
  const [LotRadio, setLotRadio] = useState("");
  const [persentageRadio, setPersentageRadio] = useState("");
  const [MinBrokrageRadio, setMinBrokrageRadio] = useState("");
  const [tradeInputvalue, settradeInputvalue] = useState("");
  const [LotInputvalue, setLotInputvalue] = useState("");
  const [persentageInputvalue, setPersentageInputvalue] = useState("");
  const [tradeRadiovalues, settradeRadiovalues] = useState(1);
  const [tradeRadioErr, settradeRadioErr] = useState("");
  const [LotRadioErr, setLotRadioErr] = useState("");
  const [persentageRadioErr, setPersentageRadioErr] = useState("");
  const [MinBrokrageRadioErr, setMinBrokrageRadioErr] = useState("");
  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [PasswordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const togglePasswordVisibility = () => {
    setPasswordType(PasswordType === "password" ? "text" : "password");
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };

  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  const addClientbtn = () => {
    if (usenameAdd == "") {
      setUsenameErr("Please enter Username");
      return;
    }

    if (fullnameAdd == "") {
      setFullnameErr("Please enter Fullname");
      return;
    }

    if (passwordAdd == "") {
      setPasswordErr("Please enter Password");
      return;
    }

    if (passwordAdd.length < 6) {
      setPasswordErr("Must be Greater then 6");
      return;
    }

    if (confirmPasswordAdd == "") {
      setConfirmPasswordErr("Please enter Confirm Password");
      return;
    }

    if (passwordAdd !== confirmPasswordAdd) {
      setConfirmPasswordErr("Password Not Match");
      return;
    }

    if (licenceToMonthAdd == "") {
      setLicenceToMonthErr("Please select Licence");
      return;
    }

    if (clientCreatedByAdd == "") {
      setClientCreatedByErr("Please select Created By");
      return;
    }

    if (limitAdd == "") {
      setLimitErr("Please enter Limit");
      return;
    }

    if (rechargeAdd == "") {
      setRechargeErr("Please enter Recharge");
      return;
    }

    if (tradeRadio == true && tradeInputvalue == "") {
      settradeRadioErr("Please enter Per Trade Amount");
      return;
    }
    if (LotRadio == true && LotInputvalue == "") {
      setLotRadioErr("Please enter Per Lot Amount");
      return;
    }
    if (persentageRadio == true && persentageInputvalue == "") {
      setPersentageRadioErr("Please enter Percentage value ");
      return;
    }

    if (MinBrokrageRadio == true) {
      if (tradeInputvalue == "") {
        settradeRadioErr("Please enter Per TradeAmount");
        return;
      } else if (LotInputvalue == "") {
        setLotRadioErr("Please enter Per Lot Amount");
        return;
      } else if (persentageInputvalue == "") {
        setPersentageRadioErr("Please enter Percentage value ");
        return;
      }
    }

    setButtonDisabled(true);

    var data = JSON.stringify({
      username: usenameAdd,
      fullname: fullnameAdd,
      password: passwordAdd,
      // confirm_password: confirmPasswordAdd,
      licence: licenceToMonthAdd,
      parent_id: clientCreatedByAdd == "Admin" ? user_Id : clientCreatedByAdd,
      recharge: rechargeAdd,
      multiplelimit: limitAdd,
      button_value: tradeRadiovalues,
      per_trade: tradeInputvalue,
      per_lot: LotInputvalue,
      turn_over_percentage: persentageInputvalue,
    });


    var config = {
      method: "post",
      url: `${Config.baseUrl}api/admin/addclient`,
      headers: {
        "x-access-token": user_token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("response", response.data);
        if (response.status == 200) {
          setButtonDisabled(false);
          setShowAlert(true);
          setTextAlert("User Added Successfully");
          setAlertColor("success");
          setTimeout(() => navigate("/admin/client"), 1000);
        }
        // else if()
      })
      .catch(function (error) {
        // console.log("response", error);
        if (error.response.status == 400) {
          setShowAlert(true);
          setButtonDisabled(false);
          setTextAlert(error.response.data.message);
          setAlertColor("error");
        }
      });
  };

  return (
    <div id="conteudo">
      <div className="conteudo-inner">
        <div className="conteudo-wrapper">
          <div className="conteudo-titleSection">
            <div className="row">
              <div className="col-md-8">
                <strong className="conteudo-title">Add Client</strong>
              </div>
              <div className="col-md-4 text-right">
                <Link
                  to="/admin/client"
                  className="px-3 py-2 rounded mb-0 btn-5"
                >
                  <i className="fa-solid fa-arrow-left mr-1"></i>Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="conteudo-box">
                <div className="conteudo-boxInner ">
                  <div className="section full mt-2 mb-2">
                    {/*<div className="section-title">Boxed</div>*/}
                    <div className="wide-block pb-1 pt-2 row">
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="name5">
                            UserName
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name5"
                            placeholder="UserName"
                            defaultValue={clientData && clientData.username}
                            onChange={(e) => {
                              setUsenameAdd(e.target.value);
                              setUsenameErr("");
                            }}
                          />

                          <p className="text-danger">{usenameErr}</p>
                          <i className="clear-input">
                            <ion-icon
                              name="close-circle"
                              role="img"
                              className="md hydrated"
                              aria-label="close circle"
                            />
                          </i>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="name5">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name5"
                            placeholder="Full Name"
                            defaultValue={clientData && clientData.fullname}
                            onChange={(e) => {
                              setFullnameAdd(e.target.value);
                              setFullnameErr("");
                            }}
                          />
                          <p className="text-danger">{fullnameErr}</p>
                          <i className="clear-input">
                            <ion-icon
                              name="close-circle"
                              role="img"
                              className="md hydrated"
                              aria-label="close circle"
                            />
                          </i>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="name5">
                            Password
                          </label>
                          <input
                            type={PasswordType}
                            className="form-control"
                            id="name5"
                            placeholder="Password"
                            defaultValue={
                              clientData && clientData.show_password
                            }
                            onChange={(e) => {
                              setPasswordAdd(e.target.value);
                              setPasswordErr("");
                            }}
                          />
                          <i
                            className={`fa ${PasswordType === "password"
                                ? "fa-eye password-eye"
                                : " password-eye fa-eye-slash"
                              }`}
                            onClick={togglePasswordVisibility}
                          ></i>
                          {passwordErr && (
                            <p style={{ fontSize: 12, color: "red" }}>
                              {passwordErr}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="name5">
                            Confirm Password
                          </label>
                          <input
                            type={confirmPasswordType}
                            className="form-control"
                            id="name5"
                            defaultValue={
                              clientData && clientData.show_password
                            }
                            placeholder="Confirm Password"
                            onChange={(e) => {
                              setConfirmPasswordAdd(e.target.value);
                              setConfirmPasswordErr("");
                            }}
                          />
                          <i
                            className={`fa ${confirmPasswordType === "password"
                                ? "password-eye fa-eye"
                                : "password-eye fa-eye-slash"
                              }`}
                            onClick={toggleConfirmPasswordVisibility}
                          ></i>
                          {confirmPasswordErr && (
                            <p style={{ fontSize: 12, color: "red" }}>
                              {confirmPasswordErr}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="city5">
                            License to Month
                          </label>
                          <select
                            className="form-control custom-select"
                            id="city5"
                            onChange={(e) => {
                              setLicenceToMonthAdd(e.target.value);
                              setLicenceToMonthErr("");
                            }}
                          >
                            <option selected>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                          </select>
                        </div>
                        <p className="text-danger">{licenceToMonthErr}</p>
                      </div>

                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label">Client Created By</label>
                          <select
                            className="form-control custom-select"
                            id="city5"
                            onChange={(e) => {
                              setClientCreatedByAdd(e.target.value);
                              setClientCreatedByErr("");
                            }}
                          >
                            <option selected>Admin</option>
                          </select>
                        </div>
                        <p className="text-danger">{clientCreatedByErr}</p>
                      </div>

                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="email5">
                            Recharge
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email5"
                            placeholder="Recharge"
                            value={rechargeAdd}
                            onChange={(e) => {
                              setRechargeAdd(
                                e.target.value.replace(/[^0-9]/g, "")
                              );
                              setRechargeErr("");
                            }}
                          />
                          <p className="text-danger">{rechargeErr}</p>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="limitInput">
                            Limit
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="limitInput"
                            value={limitAdd}
                            onChange={(e) => {
                              setLimitAdd(e.target.value);
                              setLimitErr("");
                            }}
                          />
                        </div>
                        <p className="text-danger">{limitErr}</p>
                      </div>

                      <label className="ms-4">Brokerage</label>

                      {/* -Radio Buttons */}
                      <div className="form-group boxed ">
                        <div className="d-flex">
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="Pertrade"
                                value={1}
                                onChange={(e) => {
                                  settradeRadiovalues(e.target.value);
                                  settradeRadio(e.target.checked);
                                  setMinBrokrageRadio(false);
                                  setLotRadio(false);
                                  setPersentageRadio(false);
                                }}
                                checked={tradeRadio}
                              />
                              <label
                                className="form-check-label"
                                for="Pertrade"
                              >
                                Per trade
                              </label>
                            </div>
                          </div>
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="PerLot"
                                value={2}
                                onChange={(e) => {
                                  settradeRadiovalues(e.target.value);
                                  setLotRadio(e.target.checked);
                                  settradeRadio(false);
                                  setPersentageRadio(false);
                                  setMinBrokrageRadio(false);
                                }}
                              />
                              <label className="form-check-label" for="PerLot">
                                Per Lot
                              </label>
                            </div>
                          </div>
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="TurnOverPersentage"
                                value={3}
                                onChange={(e) => {
                                  settradeRadiovalues(e.target.value);
                                  setPersentageRadio(e.target.checked);
                                  settradeRadio(false);
                                  setMinBrokrageRadio(false);
                                  setLotRadio(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="TurnOverPersentage"
                              >
                                Turn Over Percentage
                              </label>
                            </div>
                          </div>
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="MinimumBrokerage"
                                value={4}
                                onChange={(e) => {
                                  settradeRadiovalues(e.target.value);
                                  setMinBrokrageRadio(e.target.checked);
                                  settradeRadio(false);
                                  setLotRadio(false);
                                  setPersentageRadio(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                for="MinimumBrokerage"
                              >
                                Minimum Brokerage
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* ---------------------------------------- */}

                        {/* for Per Trade */}
                        {tradeRadio ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  settradeInputvalue(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                  settradeRadioErr("");
                                }}
                                value={tradeInputvalue}
                              />
                            </div>
                            {tradeRadioErr ? (
                              <p className="text-danger">{tradeRadioErr}</p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {/* for Per Lot */}
                        {LotRadio ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setLotInputvalue(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                  setLotRadioErr("");
                                }}
                                value={LotInputvalue}
                              />
                            </div>
                            {LotRadioErr ? (
                              <p className="text-danger">{LotRadioErr}</p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {/* for TurnOver */}
                        {persentageRadio ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                %{" "}
                              </span>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="Percentage"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setPersentageInputvalue(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                  setPersentageRadioErr("");
                                }}
                                value={persentageInputvalue}
                              />
                            </div>
                            {persentageRadioErr ? (
                              <p className="text-danger">
                                {persentageRadioErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {/* for Minimum Brokrage */}
                        {MinBrokrageRadio ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Per Trade Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  settradeInputvalue(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                  settradeRadioErr("");
                                }}
                                value={tradeInputvalue}
                              />
                            </div>
                            {tradeRadioErr ? (
                              <p className="text-danger">{tradeRadioErr}</p>
                            ) : (
                              ""
                            )}
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Per Lot Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setLotInputvalue(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  );
                                  setLotRadioErr("");
                                }}
                                value={LotInputvalue}
                              />
                            </div>
                            {LotRadioErr ? (
                              <p className="text-danger">{LotRadioErr}</p>
                            ) : (
                              ""
                            )}
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                %
                              </span>
                              <input
                                type="number"
                                class="form-control"
                                placeholder=" Turnover"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setPersentageInputvalue(e.target.value);
                                  setPersentageRadioErr("");
                                }}
                                value={persentageInputvalue}
                              />
                            </div>
                            {persentageRadioErr ? (
                              <p className="text-danger">
                                {persentageRadioErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group boxed ">
                      <button
                        className="px-3  rounded mb-0 btn-5"
                        onClick={addClientbtn}
                        disabled={ButtonDisabled}
                      >
                        Submit<i className="fa-solid fa-hand-pointer ml-1"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default Add_client;
