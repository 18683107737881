import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const Terms_condition = () => {
  const naviagte = useNavigate();
  const backbtn = () => {
    naviagte('/profile');
  }
  return (
    <div id="appCapsule">

      <div className="section full mt-2">
        <div className="section-title d-block text-left p-0"><span><button onClick={backbtn} className="mx-0"><i class="fa-regular fa-arrow-left"></i></button></span>Terms and Condition</div>
        <div className="wide-block pt-2 pb-1 text-left">
          <p className='text-justify  p-2'>
            All subscription fees paid to  Offline Trading  is Non refundable. We

            do not provide trading tips nor we are investment adviser. Our

            service is solely restricted to automated trading application

            development, deployment and maintenance. All algorithms are

            based on backtested data but we do not provide any guarantee for

            their performance in future. The algorithm running in an

            automated system is agreed with the user prior deployment and we

            do not take any liability for any loss generated by the same.

            Past performance of advise/strategy/model does not indicate the

            future performance of any current or future strategy/model or

            advise by Offline Trading Trades and actual returns may differ

            significantly from that depicted herein due to various factors

            including but not limited to impact costs, expense charged,

            timing of entry/exit, timing of additional flows/redemptions,

            individual client mandates, specific portfolio construction

            characteristics etc. There is no assurance or guarantee that the

            objectives of any strategy/model or advice provided by  Offline Trading Trades will be achieved. Offline Trading

            Trades or any of its

            partner/s or principal officer/employees do not assure/give

            guarantee for any return on the investment in

            strategies/models/advice given to the Investor. The value of

            investment can go up/down depending on factors & forces

            affecting securities markets. Offline Trading Trades or its

            associates are not liable or responsible for any loss or

            shortfall arising from operations and affected by the market

            condition.

          </p>    </div>
      </div>


    </div>

  )
}

export default Terms_condition