import React, { useEffect, useState } from "react";
import { SystemUpdate, getSystemUpdate } from "../../../services";
import { encode } from "js-base64";
import swal from 'sweetalert'


const System = () => {
  const userid = localStorage.getItem("UserId");

  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    company_logo: "",
    company_favicon: "",
  });

  const [updatelogo, setUpdatelogo] = useState(""); // State to store the logo image URL
  const [name,setName] = useState("")   



  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 40 * 1024) {
        swal(`File size exceeds 40KB: ${file.size} bytes. Please upload a smaller file.`);
        
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = `data:${file.type};base64,${reader.result.split(",")[1]}`;
        setCompanyDetails({ ...companyDetails, [type]: base64String });
      };
      reader.readAsDataURL(file);
    }
  };




  const updateData = async () => {
    try {
      if (
        companyDetails.company_logo &&
        companyDetails.company_logo.length > 40 * 1024
      ) {
        swal("Company Logo size exceeds 40KB. Please upload a smaller file.");
       
        return;
      }
      if (
        companyDetails.company_favicon &&
        companyDetails.company_favicon.length > 40 * 1024
      ) {
  
        swal("Company Favicon size exceeds 40KB. Please upload a smaller file.");
        return;
      }


      const data = {
        id: userid,
        company_name: companyDetails.company_name,
        company_logo: companyDetails.company_logo,
        company_favicon: companyDetails.company_favicon,
      };

      const response = await SystemUpdate(data);
      if (response.status) {
        swal("Update successful");
        window.location.reload()
      } else {
        swal("Failed to update");
       
      }
    } catch (error) {
    
      swal("Failed to update. Please try again.");
    }
  };




  const getipdatedata = async () => {
    try {
      const data = { id: userid };
      const response = await getSystemUpdate(data);
      if (response.status) {
        setUpdatelogo(response.data[0].company_logo); // Set the logo URL for display
        setName(response.data[0].company_name); // Set the logo URL for display
        setCompanyDetails({
          ...companyDetails,
          company_name: response.data[0].company_name,
          company_favicon: response.data[0].company_favicon,
        });
      } else {
        console.log("Error fetching data:", response.error);
      }
    } catch (error) {
      console.error("Error in getipdatedata:", error);
    }
  };






  useEffect(() => {
    getipdatedata(); // Fetch initial data when component mounts
  }, []);

  return (
    <div>
      <div>
        <div id="conteudo">
          <div className="conteudo-inner ">
            <div className="conteudo-wrapper">
              <div className="conteudo-titleSection">
                <strong className="conteudo-title">System</strong>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="conteudo-box">
                    <div className="conteudo-boxInner">
                      <div className="section full mt-2 mb-2">
                        <form>
                          <div className="wide-block pb-1 pt-2 row">
                            <div className="form-group boxed col-sm-12">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="name5">
                                  Company Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={companyDetails.company_name}
                                  defaultValue={name && name}
                                  onChange={(e) =>
                                    setCompanyDetails({
                                      ...companyDetails,
                                      company_name: e.target.value,
                                    })
                                  }
                                />
                              
                              </div>
                            </div>
                            <div className="form-group boxed ">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="name5">
                                  Company Logo
                                </label>
                                <div>
                                  <input
                                    className="form-control file-type"
                                    type="file"
                                    onChange={(e) =>
                                      handleFileChange(e, "company_logo")
                                    }
                                  />
                                  { updatelogo && (
                                    <img
                                      src={updatelogo}
                                      alt="Updated Logo"
                                      style={{ width: "20%" }}
                                    />

                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group boxed ">
                              <button
                                className="px-3 rounded mb-0 btn-5"
                                type="button"
                                onClick={updateData}
                              >
                                Submit
                                <i className="fa-solid fa-hand-pointer ml-1" />
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default System;
