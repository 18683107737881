import React from 'react'
import ExitTime from "./ExitTime.jsx"
import MarginRequired from "./MarginRequired.jsx"

const AuthorityMain = () => {



  return (
    <div>

      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <div className='row'>
                <div className='col-md-8'>
                  <strong className="conteudo-title">Authority Main</strong></div>
                <div className='col-md-4 text-right'>

                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-3">
                    <ExitTime />
                    <MarginRequired />
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default AuthorityMain