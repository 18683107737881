import React, { useState ,useEffect} from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { login, GetLoginStatus } from "../../services/login.service";
import AlertToast from "../.././utils/AlertToast";
import { getSystemUpdate } from '../../services';


const Login = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [changeType, setChangeType] = useState("password");
  const [visiablity, setVisiablity] = useState("");
  const navigate = useNavigate();

  const userid = localStorage.getItem('UserId');



  const [updatelogo,setUpdatelogo] = useState("")
 


  const getipdatedata = async () => {

    const data = {id: userid };
    try {
        const response = await getSystemUpdate(data);
        if (response.status) {
            
            setUpdatelogo(response.data[0].company_logo)
        } else {
            console.log("errr");
        }
    } catch (error) {
        console.error("Error in getipdatedata:", error);
    }
};

useEffect(() => {
    getipdatedata();
}, []);


  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  const submit = async () => {
    // console.log("ddddd", e);
    // if (e.code === "NumpadEnter" || e.code === "Enter" || e.target.type === "button") {
    if (username == "") {
      setUsernameErr("Please Enter Username");
      return;
    }
    if (password == "") {
      setPasswordErr("Please Enter Valid Password");
      return;
    }
    // } else {
    //   return "not match";
    // }

    const response = await login({
      username: username,
      password: password,
    });

    // console.log("response", response);
    const currentDate = new Date().toISOString().split("T")[0];
    if (response.status === 200) {
      if (response.data.roles[0] === "ROLE_USER") {
        if (new Date() > new Date(response.data.end_date)) {
          return alert("Your Plan Is Expired, Please Contact To Your Admin ");
        } else {
          localStorage.setItem("user_token", response.data.accessToken);
          localStorage.setItem("Role", response.data.roles);
          localStorage.setItem("UserId", response.data.id);
          localStorage.setItem("UserName", response.data.username);

          const userId = localStorage.getItem("UserId");
          const Token = localStorage.getItem("user_token");

          const data = {
            userid: userId,
          };
          const res = await GetLoginStatus(data, Token);
          if (res.data.status === true) {
            navigate("/watchlist", { replace: true });
          }
        }
      } else if (response.data.roles[0] === "ROLE_ADMIN") {
        localStorage.setItem("user_token", response.data.accessToken);
        localStorage.setItem("Role", response.data.roles);
        localStorage.setItem("UserId", response.data.id);
        localStorage.setItem("UserName", response.data.username);
        navigate("/admin/dashboard", { replace: true });
      }
    } else {
      console.log("msg", response);
      // setAlertMsg(true)
      // setAlertColor("error")
      alert(response.data.message);
    }
  };

  const toggle = (e) => {
    e.preventDefault();
    if (changeType === "password") {
      setChangeType("text");
      setVisiablity("eye");
    } else {
      setChangeType("password");
    }
  };

  return (
    <div >
      <div>
        <div
          style={{
            height: "200px",
            background: "#2F55A4",
            borderRadius: "0px 0px 20px 20px",
          }}
        ></div>
        <div className="login-form mt-5">
          <div className="section  mt-3">
            {/* <img src="assets/img/logo.png" alt="image" className="form-image" /> */}
            {/* <img src="images/bearbulls.png" alt="image" className="form-image" /> */}
            {updatelogo && <img src={updatelogo} alt="Updated Logo" className="w-75 mx-auto" />}

          </div>
          <div className="section mt-3">
            <h3>Login</h3>
          </div>
          

          <div className="section full mt-2 mb-2">
            <div className="wide-block pt-2 pb-3">
              <form
                value="Enter"
                // onKeyPress={(e) => { submit(e) }}
              >
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <label className="label" htmlFor="username22">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameErr("");
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          submit(e);
                        }
                      }}
                    />

                    <p className="text-danger text-left">{usernameErr}</p>
                  </div>
                </div>
                <div className="form-group boxed">
                  <div
                    className="input-wrapper"
                    style={{ position: "relative" }}
                  >
                    <label className="label" htmlFor="username2">
                      Password
                    </label>
                    <input
                      type={changeType}
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordErr("");
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          submit(e);
                        }
                      }}
                    />
                    <i
                      className={
                        changeType === "text"
                          ? "fa-solid fa-eye-slash password-eye"
                          : "fa-solid fa-eye password-eye"
                      }
                      onClick={(e) => toggle(e)}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="password Visiblity"
                    ></i>
                    <p className="text-danger text-left">{passwordErr}</p>
                  </div>
                </div>
                <div className="mt-2 frame">
                  <button
                    className="custom-btn btn-5 mb-2 btn-block"
                    onClick={(e) => {
                      submit(e);
                    }}
                    type="button"
                  >
                    <span>
                      Login{" "}
                      <i className="fa-solid fa-arrow-right-long pl-2"></i>
                    </span>
                  </button>
                  <Link to="/signin" className="link-class">Register Here</Link>

                  {/* <button className="btn btn-primary btn-block" onClick={submit}>Login</button>*/}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default Login;
