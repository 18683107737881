
import React, { useState } from "react";
import { NavLink, useNavigate, Link , useLocation } from "react-router-dom";
import axios from "axios";
import { ChangeAdminPassword } from "../../../services/login.service";
import AlertToast from "../../../utils/AlertToast";

const ChangePassword = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [alertColor, setAlertColor] = useState("");

    const [oldpassword, setOldpassword] = useState("");
    const [oldpasswordErr, setOldpasswordErr] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [newpasswordErr, setNewpasswordErr] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [confirmpasswordErr, setConfirmpasswordErr] = useState("");
    const [changeType, setChangeType] = useState("password");
    const [visiablity, setVisiablity] = useState("");
    const navigate = useNavigate();

    const onAlertClose = (e) => {
        setShowAlert(false);
    };





    const AuthLogin = localStorage.getItem("user_token");
    const AuthRole = localStorage.getItem("Role")
    const UserId = localStorage.getItem("UserId");



    const submit = async () => {
        if (oldpassword == "") {
            setOldpasswordErr("Please Enter Old Password");
            return;
        }
        if (newpassword == "") {
            setNewpasswordErr("Please Enter New Valid Password");
            return;
        }
        if (confirmpassword == "") {
            setConfirmpasswordErr("Please Enter Confirm Valid Password");
            return;
        }
        if (newpassword !== confirmpassword) {
            setShowAlert(true);
            setTextAlert(" New password and Confirm Password are Not Matched");
            setAlertColor('error')
            // setConfirmpasswordErr(" New password and Confirm Password are Not Matched");
            return;
        }

        const request = {
            "id": UserId,
            "old_password": oldpassword,
            "password": newpassword,
        }
        const response = await ChangeAdminPassword(request, AuthLogin)

        // console.log("response", response);

        if (response.status === 200) {
            setShowAlert(true);
            setTextAlert(response.data.message);
            setAlertColor('success')
            setTimeout(() => navigate("/admin/dashboard"), 1000)
        }
        else if (response.data.success === 'false') {
            setShowAlert(true);
            setTextAlert(response.data.message);
            setAlertColor('error')
            //     setTimeout(() => navigate("/admin/dashboard"), 1000)
        }
        else {
            setShowAlert(true);
            setTextAlert(response.data.message);
            setAlertColor('error')

        }

        // console.log("response", response.status);





    }




    


    const toggle = (e) => {
        e.preventDefault();
        if (changeType === "password") {
            setChangeType("text");
            setVisiablity("eye");
        } else {
            setChangeType("password");
        }
    };
    return (
        <div style={{ height: "100vh", overflowY: "hidden" }}>
            <div>
                <div
                    style={{
                        height: "200px",
                        background: "#2F55A4",
                        borderRadius: "0px 0px 20px 20px",
                    }}
                ></div>

                <div className="login-form mt-3">
                    <div className="section  mt-3">
                        <img src="/assets/img/logo.png" alt="image" className="form-image" />
                    </div>
                    <div className="section mt-1">
                        <h3 >Change Password</h3>
                    </div>
                    <div className="section full mt-2 mb-2">
                        <div className="wide-block pt-2 pb-3">
                            <div className="form-group boxed">
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="username2">
                                        Old Password
                                    </label>
                                    <input
                                        type={changeType}
                                        className="form-control"
                                        placeholder="Old Password"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setOldpassword(e.target.value);
                                            setOldpasswordErr("");
                                        }}
                                    />
                                    <i
                                        className={
                                            changeType === "text"
                                                ? "fa-solid fa-eye-slash password-eye"
                                                : "fa-solid fa-eye password-eye"
                                        }
                                        onClick={(e) => toggle(e)}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="password Visiblity"
                                    ></i>
                                    <p className="text-danger text-left">{oldpasswordErr}</p>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="username2">
                                        New Password
                                    </label>
                                    <input
                                        type={changeType}
                                        className="form-control"
                                        placeholder="New Password"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setNewpassword(e.target.value);
                                            setNewpasswordErr("");
                                        }}
                                    />
                                    <i
                                        className={
                                            changeType === "text"
                                                ? "fa-solid fa-eye-slash password-eye"
                                                : "fa-solid fa-eye password-eye"
                                        }
                                        onClick={(e) => toggle(e)}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="password Visiblity"
                                    ></i>
                                    <p className="text-danger text-left">{newpasswordErr}</p>
                                </div>
                            </div>
                            <div className="form-group boxed">
                                <div className="input-wrapper" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="username2">
                                        Confirm Password
                                    </label>
                                    <input
                                        type={changeType}
                                        className="form-control"
                                        autoComplete="off"
                                        placeholder="Confirm Password"
                                        onChange={(e) => {
                                            setConfirmpassword(e.target.value);
                                            setConfirmpasswordErr("");
                                        }}
                                    />
                                    <i
                                        className={
                                            changeType === "text"
                                                ? "fa-solid fa-eye-slash password-eye"
                                                : "fa-solid fa-eye password-eye"
                                        }
                                        onClick={(e) => toggle(e)}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="password Visiblity"
                                    ></i>
                                    <p className="text-danger text-left">{confirmpasswordErr}</p>
                                </div>
                            </div>
                            <div className="mt-2 frame">
                                <button className="custom-btn btn-5 btn-block" onClick={submit}>
                                    <span>
                                        Change Password<i className="fa-solid fa-arrow-right-long pl-2"></i>
                                    </span>
                                </button>
                                <Link className="custom-btn btn-5 btn-block" to="/admin/dashboard" >
                                    <span>
                                    <i className="fa-solid fa-arrow-left-long pl-2" /> Back
                                    </span>
                                </Link>


                                {/*<button className="btn btn-primary btn-block" onClick={submit}>Login</button>*/}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {showAlert && (
                <AlertToast
                    hideAlert={onAlertClose}
                    showAlert={showAlert}
                    message={textAlert}
                    alertColor={alertColor}
                />
            )}
        </div>
    )
}

export default ChangePassword