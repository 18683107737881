import React, { useState, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import { getExitTimeAdminDetails, UpdatemargiRreqPerAdmin, UpdateExitTimeAdmin } from "../../../services";
import AlertToast from '../../../utils/AlertToast'
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';



const ExitTime = () => {

    const accesstoken = localStorage.getItem("user_token");
    const userid = localStorage.getItem("UserId");
    const AuthRole = localStorage.getItem("Role");

    const [show, setShow] = useState(false);
    const [NFOtime, setNFOtime] = useState('');
    // console.log("NFOtime", NFOtime);
    const [MCXtime, setMCXtime] = useState('');
    const [CDStime, setCDStime] = useState('');
    const [adminID, setAdminID] = useState('');
    const [ExitTimevalue, setExitTimeValue] = useState('');
    // console.log("ExitTimevalue", ExitTimevalue && ExitTimevalue);
    const [refresh, setRefresh] = useState(false);

    //----------------- Alert Toast---------------------------
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [alertColor, setAlertColor] = useState("");

    const onAlertClose = e => {
        setShowAlert(false);
    }

    //----------------- Alert Toast---------------------------


    // alert(NFOtime)
    const handleClose = () => setShow(false);
    // const handleClose = () => setShow(true);
    const handleShow = (aid) => {
        setAdminID(aid)
        // alert(aid.adminid)
        setShow(true);
    }

    // console.log("aid", moment());
    // console.log("aid", adminID.NFO);
    // console.log("aid",typeof adminID.NFO);
    // console.log("NFOtime" ,NFOtime);
    // console.log("typeof" , typeof NFOtime);



    const UpdateMarginReqPerTime = async (e) => {
        setShow(true);

        e.preventDefault()

        const data = {
            "adminid": adminID.adminid,
            "NFO_time": NFOtime,
            "MCX_time": MCXtime,
            "CDS_time": CDStime
        }
        // const response = await UpdateTimeRreqPerAdmin(data, accesstoken);
        const response = await UpdateExitTimeAdmin(data, accesstoken);
        // console.log("UpdateMarginReqPer", response);
        if (response.data.status) {
            setShowAlert(true);
            setTextAlert(response.data.msg);
            setAlertColor('success')
            setRefresh(!refresh)
            setShow(false);
        }
    }

    const now = moment(ExitTimevalue && ExitTimevalue.CDS)
    const newdateee = now._i
    // console.log("newdateee", newdateee);

    const getMarginReqPer = async () => {

        const data = {
            "adminid": userid
        }
        const response = await getExitTimeAdminDetails(data, accesstoken);


        if (response.data.status) {
            setExitTimeValue(response.data.data[0]);
        }
    }

    useEffect(() => {
        getMarginReqPer()
    }, [refresh])

    const mcxValue = (e) => {
        // console.log("e", e);
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <h2 className="p-2">Exit Time</h2>
                    <table className="table table-bordered ExittimeTable">
                        <thead className="exit_time_color">
                            <tr>
                                <th scope="col">NFO</th>
                                <th scope="col">MCX</th>
                                <th scope="col">CDS</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>{ExitTimevalue && ExitTimevalue.CDS}</td>
                                <td>{ExitTimevalue && ExitTimevalue.MCX}</td>
                                <td>{ExitTimevalue && ExitTimevalue.NFO}</td>
                                <td> <Tooltip title="View Detail" arrow><button className='btn rounded-circle' onClick={(e) => { handleShow(ExitTimevalue && ExitTimevalue) }}><i className="fa-regular fa-pen-to-square " style={{ fontSize: '18px' }}></i></button></Tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <Modal size="lg" show={show} onHide={handleClose} className="trade-history-modal-table">
                <Modal.Header closeButton>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}><i className="fa-solid fa-x"></i></button>
                    <Modal.Title>Exit Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div >
                        <ul className="list-group w-75 mx-auto d-flex justify-content-center">
                            <li className="list-group-item d-flex ms-5 align-items-center" >
                                <span className='col-6'>
                                    NFO
                                </span>
                                <span className='col-6 ms-auto'>
                                    {/* <input type="time" className=' form-control input-timer' onChange={() => setNFOtime()} /> */}
                                    <TimePicker
                                        onChange={(e) => setNFOtime(e.format("HH:mm"))}
                                        //  value={ adminID && adminID.NFO}
                                        // defaultValue={moment().format('LT')}
                                        showSecond={false} style={{ width: '101% !important' }}
                                        defaultValue={moment()}
                                    />
                                </span>
                            </li>
                            <li className="list-group-item d-flex ms-5 align-items-center">
                                <span className='col-6'>
                                    MCX
                                </span>
                                <span className='col-6 ms-auto'>
                                    {/* <input type="time" className=' form-control input-timer' onChange={() => setMCXtime()} /> */}
                                    <TimePicker onChange={(e) => setMCXtime(e
                                        // format("HH:mm")
                                    )}
                                        showSecond={false} style={{ width: '101% !important' }}
                                        // defaultValue={adminID.MCX} 
                                        defaultValue={moment()}
                                    />
                                </span>
                            </li>
                            <li className="list-group-item d-flex ms-5 align-items-center">
                                <span className='col-6'>
                                    CDS
                                </span>
                                <span className='col-6 ms-auto'>
                                    {/* <input type="time" className=' form-control input-timer' onChange={() => setCDStime()} /> */}
                                    <TimePicker onChange={(e) => setCDStime(e.format("HH:mm"))} showSecond={false} style={{ width: '101% !important' }}
                                        // defaultValue={adminID.CDS}
                                        defaultValue={moment()}

                                    />

                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-12 mt-2  text-right  ms-auto'>
                        <button className='px-3  rounded  btn-5 ms' onClick={(e) => UpdateMarginReqPerTime(e)}>Update</button>
                    </div>
                </Modal.Body>
            </Modal>
            {showAlert &&
                <AlertToast
                    hideAlert={onAlertClose}
                    showAlert={showAlert}
                    message={textAlert}
                    alertColor={alertColor}
                />
            }
        </div >


    )
}

export default ExitTime