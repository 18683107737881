import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { encode, decode } from 'js-base64';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import socketIOClient from "socket.io-client";
import * as Config from "../../frontend/common/Config";
import { fDateTime } from '../../../component/frontend/common/formatTime'
import { GetAllAdminNotifiaction } from "../../../services";
import AlertToast from '../../../utils/AlertToast'



const AdminNotification = () => {


    const accesstoken = localStorage.getItem("user_token");
    const UserId = localStorage.getItem("UserId");

    const [alPrendingAmmount, setAllPendingAmmount] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const [socketState, setSocketState] = useState(null);
    // console.log("socketState", socketState);



    // Alert Toast-----------------------------------------
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [alertColor, setAlertColor] = useState("");



    
    const onAlertClose = e => {
        setShowAlert(false);
    }

    const GetNotification = async () => {
        // setRefresh(refresh)
        const req = {
            "adminid": UserId
        };

        const response = await GetAllAdminNotifiaction(req, accesstoken);
        // console.log("admin notification ", response.data.data);
        if (response.data.status) {

            setAllPendingAmmount(response.data.data)
        }


    }




    const navigateRoute = (type, id, key, withdrawal_payment, deposite_payment) => {

        if (withdrawal_payment != null) {
            //   return  console.log("withdrow");
            return `withdraw?key=${encode(JSON.stringify({ key: "0", id: id }))}`
        } else {
            // return console.log("deposite");
            return `depositehistory?key=${encode(JSON.stringify({ key: "1", id: id }))}`
        }

    }


    
    useEffect(() => {
        GetNotification()
 
    }, [])





    useEffect(() => {
        const newSocket = socketIOClient(`${Config.base_url}`);

        newSocket.on("res_deposite_message", (data) => {
            GetNotification()

            setShowAlert(true);
            setTextAlert(data);
            setAlertColor('success')
        });
        newSocket.on("res_withdrawal_message", (data) => {
        GetNotification()

            setShowAlert(true);
            setTextAlert(data);
            setAlertColor('success')
        });
        // setSocketState(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, []);





    return (

        <div className="section full">
            <Dropdown  >
                <Dropdown.Toggle className="button-light bell-button">
                    <i class="fa-sharp fa-solid fa-bell" id="dropdown-basic"></i>
                    <span className="notification_Count">{alPrendingAmmount.length < 10 ? alPrendingAmmount.length : "9+"}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ height: '400px',width:'400px', overflowY: 'scroll' }}>
                    <h3 className='text-center   border-bottom'>Notification</h3>
                      
                       
                    {
                        alPrendingAmmount.length > 0 ? 
                        alPrendingAmmount.map((item) => {
                            // { console.log("item", item); }
                            return <>
                                <Dropdown.Item className='d-flex flex-column float-left text-left'>
                                    <ListGroup>
                                        <Link to={navigateRoute(item.note, item.id, item.deposite_payment, item.withdrawal_payment)} className="d-flex flex-column border-0 p-0" style={{ fontSize: '14px' }}>{item.notification_title}
                                            <span className='text-secondary' style={{ fontSize: '12px' }}>{fDateTime(item.createdAt)}</span>
                                        </Link>
                                    </ListGroup>
                                </Dropdown.Item>
                            </>
                        })


                        :   <div className="no-data">
                        <p className="text-center">No Data Found!</p>
                        <img src="/images/nodata.webp" className="" />
                      </div>
                    }

                </Dropdown.Menu>
            </Dropdown>

            {showAlert &&
                <AlertToast
                    hideAlert={onAlertClose}
                    showAlert={showAlert}
                    message={textAlert}
                    alertColor={alertColor}
                />
            }
        </div>
    )
}

export default AdminNotification

