import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signinuser } from "../../services/admin.service";
import swal from "sweetalert";

const SignIn = () => {
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const [user, setUser] = useState({
    username: "",
    fullname: "",
    password: "",
    mobile: "",
    show_password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    fullname: "",
    password: "",
    mobile: "",
    show_password: "",
  });

  const validate = () => {
    let valid = true;
    const newErrors = {
      username: "",
      fullname: "",
      password: "",
      mobile: "",
      show_password: "",
    };

    if (!user.username) {
      newErrors.username = "Username is required";
      valid = false;
    }
    if (!user.fullname) {
      newErrors.fullname = "Full name is required";
      valid = false;
    }
    if (!user.password) {
      newErrors.password = "Password is required";
      valid = false;
    }
    if (!user.show_password) {
      newErrors.show_password = "Confirm password is required";
      valid = false;
    }
    if (user.password && user.password !== user.show_password) {
      newErrors.show_password = "Passwords do not match";
      valid = false;
    }
    if (!user.mobile) {
      newErrors.mobile = "Mobile number is required";
      valid = false;
    } else if (user.mobile.length !== 10) {
      newErrors.mobile = "Mobile number must be 10 digits";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const signindata = async () => {
    if (!validate()) {
      return;
    }

    try {
      const response = await signinuser({
        username: user.username,
        fullname: user.fullname,
        password: user.password,
        mobile: user.mobile,
        show_password: user.show_password,
      });

      if (response.status) {
        await swal(response.data.message);
        setUser({
          username: "",
          fullname: "",
          password: "",
          mobile: "",
          show_password: "",
        });
      } else {
        await swal("Sign-in failed", response.message, "error");
        console.log("Error in response:", response.message);
      }
    } catch (error) {
      await swal("An error occurred during sign-in", error.message, "error");
      console.error("Error in signindata:", error);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordType(newPasswordType === "password" ? "text" : "password");
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };

  return (
    <div>
      <div
        style={{
          height: "200px",
          background: "#2F55A4",
          borderRadius: "0px 0px 20px 20px",
        }}
      ></div>
      <div className="login-form mt-5">
        <div className="section mt-3">
          <h3>Register Here!</h3>
        </div>

        <div className="section full mt-2 mb-2">
          <div className="wide-block pt-2 pb-3">
            <form>
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="fullname">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    value={user.fullname}
                    onChange={(e) => {
                      setUser({ ...user, fullname: e.target.value });
                    }}
                  />
                  {errors.fullname && (
                    <div className="error">{errors.fullname}</div>
                  )}
                </div>
              </div>
              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="username">
                    User Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="User Name"
                    value={user.username}
                    onChange={(e) => {
                      setUser({ ...user, username: e.target.value });
                    }}
                  />
                  {errors.username && (
                    <div className="error">{errors.username}</div>
                  )}
                </div>
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper">
                  <label className="label" htmlFor="mobile">
                    Mobile No
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mobile Number"
                    value={user.mobile}
                    onChange={(e) => {
                      setUser({ ...user, mobile: e.target.value });
                    }}
                  />
                  {errors.mobile && (
                    <div className="error">{errors.mobile}</div>
                  )}
                </div>
              </div>

              <div className="form-group boxed">
                <div className="input-wrapper" style={{ position: "relative" }}>
                  <label className="label" htmlFor="password">
                    Password
                  </label>
                  <input
                    type={newPasswordType}
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    value={user.password}
                    onChange={(e) => {
                      setUser({ ...user, password: e.target.value });
                    }}
                  />
                  <i
                    className={
                      newPasswordType === "text"
                        ? "fa-solid fa-eye-slash password-eye"
                        : "fa-solid fa-eye password-eye"
                    }
                    onClick={toggleNewPasswordVisibility}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Password Visibility"
                  ></i>
                  {errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="form-group boxed">
                <div className="input-wrapper" style={{ position: "relative" }}>
                  <label className="label" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    type={confirmPasswordType}
                    className="form-control"
                    autoComplete="off"
                    placeholder="Confirm Password"
                    value={user.show_password}
                    onChange={(e) => {
                      setUser({ ...user, show_password: e.target.value });
                    }}
                  />
                  <i
                    className={
                      confirmPasswordType === "text"
                        ? "fa-solid fa-eye-slash password-eye"
                        : "fa-solid fa-eye password-eye"
                    }
                    onClick={toggleConfirmPasswordVisibility}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Password Visibility"
                  ></i>
                  {errors.show_password && (
                    <div className="error">{errors.show_password}</div>
                  )}
                </div>
              </div>

              <div className="mt-2 frame">
                <button
                  className="custom-btn btn-5 mb-2 btn-block"
                  type="button"
                  onClick={signindata}
                >
                  <span>
                    Submit
                    <i className="fa-solid fa-arrow-right-long pl-2"></i>
                  </span>
                </button>
                <Link to="/login" className="link-class">
                  Back To Login{" "}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
