import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  GetTradeHistoryClients,
  GetAllClientS,
  getAllAvailablePostions,
} from "../../../services";

const AvailablePositions = () => {
  const [client, setClient] = useState([]);
  const [TradeHistorydata, setTradeHistorydata] = useState([]);
  const [fromdata, setFromDate] = useState("");
  const [abc, setAbc] = useState();
  const [todate, setToDate] = useState("");

  // console.log("client", client);

  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");

  const Clientdata = async () => {
    var data1 = {
      adminid: UserId,
    };
    const response1 = await getAllAvailablePostions(data1, AuthLogin);
    // let abc = { groupBy: response1.data.data[0].symbol }
    // console.log("abc", abc);
    if (response1.status === 200) {
      
      setClient(response1.data.data);
    } else {
      // console.log("error", response.data.data);
    }
  };

  useEffect(() => {
    Clientdata();
    // getProfitLoss()
  }, []);

  const colums = [
    {
      name: "Trading Symbol",
      selector: (row) => row.symbol,
    },
    {
      name: "Buy Qty1",
      selector: (row) => row.fullname,
    },

    {
      name: "Sell Qty",
      selector: (row) => row.start_date,
    },
    {
      name: "Avg Position",
      selector: (row) => row.end_date,
    },
  ];

  var all_data_show = [];
  var single_symbol_check = [];
  var symbol_group_by = [];
  client &&
    client.forEach(function (val) {
      if (!single_symbol_check.includes(val.symbol)) {
        single_symbol_check.push(val.symbol);
        symbol_group_by.push(val.symbol);
      }
    });

  symbol_group_by &&
    symbol_group_by.map((element) => {
      var buyqty = 0;
      var sellqty = 0;
      client.map((element1) => {
        if (element == element1.symbol && element1.type == "buy") {
          buyqty += parseInt(element1.qty);
        } else if (element == element1.symbol && element1.type == "sell") {
          sellqty += parseInt(element1.qty);
        }
      });
      all_data_show.push({ symbol: element, buyqty: buyqty, sellqty: sellqty });
    });

  // console.log("hhhh", all_data_show && all_data_show)
  const filtered_data = all_data_show.filter(
    (trade) => trade.buyqty !== trade.sellqty
  );

  const ShowRemainingQTY = (sell, buy) => {
  

    if (
      parseInt(buy) - parseInt(sell) === 0 ||
      parseInt(sell) - parseInt(buy) === 0
    ) {
      return ` -`;
    } else if (parseInt(buy) - parseInt(sell) > 0) {
      return ` ${parseInt(buy) - parseInt(sell)} buy Available `;
    } else if (parseInt(buy) - parseInt(sell) < 0) {
      return ` ${parseInt(sell) - parseInt(buy)} sell Available `;
    }
   
  };

  
  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <div className="row">
                <div className="col-md-8">
                  <strong className="conteudo-title">
                    Available Positions
                  </strong>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">
                    <div className="table-responsive">
                      <table className="table availabel-position">
                        <thead className="text-center">
                          <tr className="text-center">
                            <th
                              className="text-center"
                              style={{ width: "200px" }}
                            >
                              S. No.
                            </th>
                            <th className="text-center">Trading Symbol</th>
                            <th
                              className="text-center"
                              style={{ width: "200px" }}
                            >
                              Buy QTY{" "}
                            </th>
                            <th
                              className="text-center"
                              style={{ width: "200px" }}
                            >
                              Sell QTY
                            </th>
                            <th
                              className="text-center"
                              style={{ width: "200px" }}
                            >
                              Avg Position
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filtered_data.length === 0 ? (
                            <th colSpan="8" className="text-center">
                              no data
                            </th>
                          ) : (
                            <>
                              {filtered_data.map((trade, i) => (
                                <tr key={i} className="text-center">
                                  <td>{i + 1}</td>
                                  <td>{trade.symbol}</td>
                                  <td>
                                    {trade.buyqty === 0 ? "-" : trade.buyqty}
                                  </td>
                                  <td>
                                    {trade.sellqty === 0 ? "-" : trade.sellqty}
                                  </td>
                                  <td>
                                    {ShowRemainingQTY(
                                      trade.sellqty,
                                      trade.buyqty
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailablePositions;
