import axios from "axios";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import * as Config from "../../frontend/common/Config";
import socketIOClient from "socket.io-client";
import { GetReports } from "../../../services";
import { fDateTime } from "../../../component/frontend/common/formatTime";
import {
  getUserPendingAmmount,
  getUserUpdateAmmount,
  getWithrawData,
} from "../../../services";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AlertToast from "../../../utils/AlertToast";
import { encode, decode } from "js-base64";

const Pending = () => {
  let location = useLocation();

  if (location.search !== "") {
    var Reqquery = JSON.parse(decode(location.search.split("=")[1]));
  }

  const [alPrendingAmmount, setAllPendingAmmount] = useState([]);
  const [rowData, setRowData] = useState("");
  const [alUpdateAmmount, setUpdateAmmount] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [alUpdateMessage, setUpdateMessage] = useState("");
  const [alUpdateMessageError, setUpdateMessageeError] = useState("");
  const [Updatestatus, setUpdatestatus] = useState("");
  const [UpdateNotificationtitle, setUpdateNotificationtitle] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");

  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  // console.log("rowData", rowData);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (data, event) => {
    if (event == "Complete") {
      setUpdatestatus(1);
      // setUpdateNotificationtitle(
      //   `Request of withdrawal Rs.${rowData.withdrawal_payment} has been Accepted by the admin`
      // );
    }
    if (event == "Reject") {
      setUpdatestatus(2);
      // setUpdateNotificationtitle(
      //   `Request of withdrawal Rs.${rowData.withdrawal_payment} has been Rejected by the admin`
      // );
    }

    if (
      event == "Complete" ||
      event == "Reject" ||
      (event == "" && !event == "Pending")
    ) {
      setShow(true);
      setRowData(data);
    }
  };

  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const GetWithTradeReports = async () => {
    const req = {
      adminid: UserId,
      type: "withdraw",
    };

    const response = await getUserPendingAmmount(req, AuthLogin);

    if (response.data.status) {
      setAllPendingAmmount(response.data.data);
      // setRefresh(!refresh)
      let abc = response.data.data.filter((item) => {
        if (Reqquery != undefined) {
          if (Reqquery.key == "0") {
            return parseInt(item.id) == parseInt(Reqquery.id);
          } else if (Reqquery.key == "1") {
            return parseInt(item.id) == parseInt(Reqquery.id);
          }
        } else {
          return [];
        }
      });

      if (abc.length == 0 || Reqquery == "" || Reqquery == undefined) {
        setAllPendingAmmount(response.data.data);
      } else {
        setAllPendingAmmount(abc);
      }
      //  setRefresh(!refresh)
    }
  };





  const UpdateFund = async (e) => {
    e.preventDefault();
    

    const createSocketAndEmit = (message, event) => {
      const socket = socketIOClient(Config.base_url);
      const data = {
        data: message,
        userid: rowData.userid,
      };
      setUpdateNotificationtitle(message);
      socket.emit(event, data);
    };

    let message;
    if (Updatestatus === 1) {
      message = `Request of Withdrawal Rs.${rowData.withdrawal_payment} has been Accepted by the admin`;
      createSocketAndEmit(message, "req_pendingwithdraw_accept_message");
    } else if (Updatestatus === 2) {
      message = `Request of Withdrawal Rs.${rowData.withdrawal_payment} has been Rejected by the admin`;
      createSocketAndEmit(message, "req_pendingwithdraw_reject_message");
    }

    const req = {
      id: rowData.id,
      adminid: rowData.adminid,
      userid: rowData.userid,
      status: Updatestatus,
      note: alUpdateMessage,
      payment: rowData.withdrawal_payment,
      type: "withdraw",
      notification_title: message,
    };


    try {
      const response = await getUserUpdateAmmount(req, AuthLogin);
      if (response.data.status) {
        setShowAlert(true);
        setTextAlert(response.data.message);
        setAlertColor("success");
        setShow(false);
        setAllPendingAmmount("");
        setRefresh(!refresh);
      }
    } catch (error) {
      console.error("Error updating user amount", error);
      // Handle error, e.g., show an alert with error message
    }
  };

  const colums = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Date & Time",
      selector: (row, index) => fDateTime(row.createdAt),
      width: "200px",
    },
    {
      name: "User Name",
      cell: (row) => row.username.username,
    },
    {
      name: "Payment",
      selector: (row) =>
        row.withdrawal_payment == "" ? "-" : row.withdrawal_payment,
    },
    {
      name: "Message",
      selector: (row) => (row.note == "" ? "no message" : row.note),
    },

    {
      name: "Action",
      cell: (row) => (
        <select
          onChange={(e) => {
            handleShow(row, e.target.value);
          }}
          selected
        >
          <option value="Pending">Pending</option>
          <option value="Complete">Complete</option>
          <option value="Reject">Reject</option>
        </select>
      ),
    },
  ];
  useEffect(() => {
    GetWithTradeReports();
  }, [refresh]);

  useEffect(() => {
    const table = document.querySelector(".rdt_Table");

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener("dragstart", preventDrag);

      return () => {
        table.removeEventListener("dragstart", preventDrag);
      };
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="conteudo-box">
            <div className="conteudo-boxInner p-0">
              <DataTable
                columns={colums}
                data={alPrendingAmmount}
                fixedHeaderScrollHeight="400px"
                fixedHeader
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Are You Want To Change Status</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <button
            className="btn btn-primary btn-block w-auto"
            type="submit"
            onClick={(e) => UpdateFund(e)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default Pending;
