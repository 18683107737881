import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Link ,useParams, useLocation } from 'react-router-dom';

import { UserHoldingsFund, GetPosition } from "../../../services";
import CryptoJS from "crypto-js";
import $ from "jquery";
import "../dashboard.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import * as Config from '../common/Config';
import { MdOutlineCancel } from "react-icons/md";
import socketIOClient from 'socket.io-client';


const Holdings = (props) => {
    // alert(props.key);

    // console.log("props" , props.forRefresh)


    const [getHoldingFund, setGetHoldingFund] = useState([])
    const [getInvestedAmmount, setGetInvestedAmmount] = useState('')

    const [GetPostionfund, setGetPostionfund] = useState("")
    const [holdingCount, setHoldingCount] = useState(0)
    const [socketUserId, setSocketUserId] = useState('')
    const [socketUserToken, setSocketUserToken] = useState('')
    const [showAlert, setShowAlert] = useState(false);
    // const [textAlert, setTextAlert] = useState("");
    // const [alertColor, setAlertColor] = useState("");
    const [tokenMatch, setTokenMatch] = useState("");
    const [tokenPrice, setTokenPrice] = useState("");
    const [tokenMatchArray, setTokenMatchArray] = useState([]);
    const [arrayobject, setArrayobject] = useState([]);
    const [sockets, setSockets] = useState(null);
    const [allResponseSocket, setAllResponseSocket] = useState([]);
    // model -boostrap

    const [show, setShow] = useState(false);

    const [showModaltoken, setShowModaltoken] = useState("");
    const [showModalsymbol, setShowModalsymbol] = useState("");
    const [showModalaverageprice, setShowModalaverageprice] = useState("");
    const [showModalcurrentprice, setShowModalcurrentprice] = useState("");
    const [showModalpercentupdown, setShowModalpercentupdown] = useState("");
    const [showModalqty, setShowModalqty] = useState("");
    const [showModalbuysell, setShowModalbuysell] = useState("");
    const [showModalexpiry, setShowModalexpiry] = useState("");
    const [showModallot, setShowModallot] = useState("");
    const [showModalsegment, setShowModalsegment] = useState("");
    const [socketStatus, setSocketStatus] = useState(false);
    
 // console.log("showModalcurrentprice",showModalcurrentprice)
   

    const handleClose = () =>{ setShow(false)
        setShowModaltoken('')
        setShowModalsymbol('')
        setShowModalaverageprice('')
        setShowModalcurrentprice('')
        setShowModalpercentupdown('')
        setShowModalqty('')
        setShowModalbuysell('')
        setShowModalexpiry('')
        setShowModallot('')
        setShowModalsegment('')
    };
    const handleShow = (symbol,token,price,currentprice,percentage,qty,type,expiry,lot,segment) =>{ setShow(true)
        setShowModalsymbol(symbol)
        setShowModaltoken(token)
        setShowModalaverageprice(price)
        setShowModalcurrentprice(currentprice)
        setShowModalpercentupdown(percentage)
        setShowModalqty(qty)
        setShowModalbuysell(type)
        setShowModalexpiry(expiry)
        setShowModallot(lot)
        setShowModalsegment(segment)
    };
   
    // console.log("showModaltoken",showModaltoken)
    // console.log("showModalsymbol",showModalsymbol)

    const UserToken = localStorage.getItem("user_token");
    const user_id = localStorage.getItem("UserId");
    const AuthRole = localStorage.getItem("Role");

    const [auth, setAuth] = useState("");
    const [adddata, setAdddata] = useState([]);
    const [posts, setPosts] = useState([]);
    const [uid, setUid] = useState("");
    const [searcdata, setSearcdata] = useState();
    // console.log("searcdata", searcdata);
    const [watchdata, setWatchdata] = useState([]);
    const [refresh, setrefresh] = useState(true);
    // const [show, setShow] = useState(false);
    const [listData, setListData] = useState([]);
    const [listDataExcSeg, setListDataExcSeg] = useState([]);

    // console.log("listData", listData);
    const [tokenSymbol, setTokenSymbol] = useState([]);
    const [socketuserid, setSocketuserid] = useState("");
    const [socketuserSession, setSocketuserSession] = useState("");
    const [previousReturnAmount, setPreviousReturnAmount] = useState("");
    const [checkStatus, setCheckStatus] = useState(false);
   


       const GetOrderList = async () => {
        //alert("holdings")
        const data = {
            "userid": user_id
        }
        const response = await UserHoldingsFund(data, UserToken);
        setGetHoldingFund(response.data.data)
        setHoldingCount(response.data.data.length)
        forexCrypto(response.data.data)
        if (response.data.status) {
            let initialValue = 0;
            var tokenArr = [];
            var tokenArrExcSeg = [];
            var tokenstring = "";
            response.data.data.map((total) => {

                // console.log("symbol",total.symbol)
                //initialValue += parseFloat(total.invest_amount);
                initialValue += parseInt(total.qty) * parseFloat(total.price) * total.lot;


                tokenstring += total.exch_seg+'|' + total.token + '#'

                // tokenArr.push(total.token);
                // tokenArrExcSeg.push({ token: total.token, exch_seg: total.exch_seg });
                // setListData(tokenArr);
                // setListDataExcSeg({ tokenArrExcSeg });

            }
            )

            var returntokenstring = tokenstring.substring(0, tokenstring.length - 1);
          //  console.log(" sd  returntokenstring  returntokenstring", returntokenstring);
 
         //   setListDataChannel(returntokenstring);
       if(sockets == null || socketStatus==false){ 
        setCheckStatus(true)     
        TokenAndClientCode(returntokenstring,response.data.data)
       }
           return setGetInvestedAmmount(initialValue)
        }

    

    };

    // const GetPositionData = async () => {

    //     const data = {
    //         "userid": user_id
    //     }
    //     const response = await GetPosition(data, UserToken);
    //     // console.log('get postiob', response)
    //     if (response.data.status) {
    //         setGetPostionfund(response.data.data)
    //     }
    //     else {
    //         setGetPostionfund([response.data.data])
    //     }



    // };
    useEffect(() => {
       
        GetOrderList()
      
    }, [sockets , props.key])



    useEffect(() => {

       }, [sockets])



    /// Socket 
    var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
    let AuthorizationToken;
    let type = "API";

    function TokenAndClientCode(returntokenstring,holdingloop) {
        // alert(token);

        var config = {
            method: "get",
            url: `${Config.base_url}api/alicebluetoken`,
            headers: {},
        };


        axios(config)
            .then(function (response) {
                
                if (response.data.status == "true") {
                    //  console.log('Token Available', response.data.data[0].client_code + '  ' + response.data.data[0].access_token);
                    setSocketuserid(response.data.data[0].client_code);
                    setSocketuserSession(response.data.data[0].access_token);

                    createSession(
                        response.data.data[0].client_code,
                        response.data.data[0].access_token,
                        returntokenstring,holdingloop


                    );
                } else {
                    //  console.log('Token Not Available');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function invalidateSession(userId, userSession,returntokenstring,holdingloop) {
        //alert('0');

        AuthorizationToken = "Bearer " + userId + " " + userSession;
        //console.log('AuthorizationToken',AuthorizationToken);
        let jsonObj = {
            loginType: type,
        };
        $.ajax({
            url: BASEURL + "api/ws/invalidateSocketSess",
            headers: {
                Authorization: AuthorizationToken,
            },
            type: "post",
            data: JSON.stringify(jsonObj),
            contentType: "application/json",
            dataType: "json",
            async: false,
            success: function (msg) {
                var data = JSON.stringify(msg);
                if (msg.stat == "Ok") {
                    createSession(userId, userSession,returntokenstring,holdingloop);
                }
            },
        });
    }

    function createSession(userId, userSession,returntokenstring,holdingloop) {
        //alert('create session')

        AuthorizationToken = "Bearer " + userId + " " + userSession;

        //console.log('AuthorizationToken cratesession',AuthorizationToken);

        let jsonObj = {
            loginType: type,
        };
        $.ajax({
            url: BASEURL + "api/ws/createSocketSess",
            headers: {
                Authorization: AuthorizationToken,
            },
            type: "post",
            data: JSON.stringify(jsonObj),
            contentType: "application/json",
            dataType: "json",
            async: false,
            success: function (msg) {
                var data = JSON.stringify(msg);
                if (msg.stat == "Ok") {
                    connect(userId, userSession,returntokenstring,holdingloop);
                } else {
                    alert(msg);
                }
            },
        });
    }

    const url = "wss://ws1.aliceblueonline.com/NorenWS/";
    let socket;
    function connect(userId, userSession,returntokenstring,holdingloop) {
        // abcc(token)

        socket = new WebSocket(url);

        // else{
        //   console.log('elseeee')
        // }

        socket.onopen = function () {
            connectionRequest(userId, userSession);
            //alert('okk socket open')
            //  console.log('token ffffffff',token)

            setSockets(socket)
            setSocketStatus(true)

            //  console.log('xyzzzzz',xyz(socket,token))
        };
        socket.onmessage = function (msg) {
            var response = JSON.parse(msg.data);
            // console.log("response - ",response);
           
            

            if (response.s == "OK") {
                
                var channel = returntokenstring;
                  //console.log("channel", channel);
                  let json = {
                      k: channel,
                      t: "t",
                  };

                  socket.send(JSON.stringify(json));

                  socket.onmessage = function (msg) {
                      var response = JSON.parse(msg.data);
                      // console.log('response socket ggg', response)

                      if (response.tk) {

                         var filtered = holdingloop.filter(item => item.token == response.tk);
                       
                          if (response.lp !== undefined) {
                              $('.showdatalp'+response.tk).html(response.lp); 
                          }
                          if (response.bp1 !== undefined) {
                            $('.showdatabp1'+response.tk).html(response.bp1); 
                       // console.log('response socket response.bp1', response.bp1)

                          }
                          if (response.sp1 !== undefined) {
                            $('.showdatasp1'+response.tk).html(response.sp1); 
                    //   console.log('response socket spi', response.sp1)

                        }

                         
                          if(filtered.length > 0){
                        if(response.bp1 !== undefined && response.sp1 !== undefined){

                               



                                if(filtered[0].type == "buy"){
                                    var val = ((response.bp1 * filtered[0].lot * filtered[0].qty) - (filtered[0].price * filtered[0].lot * filtered[0].qty)).toFixed(2)


                                   


                                    if(val > 0){
                                        $('.showdatapl'+filtered[0].token).html(val + " ↑ ");
                                        $('.showdatapl'+filtered[0].token).css({"color":"green"});
   
   
                                         }else if(val < 0){
                                       $('.showdatapl'+filtered[0].token).html(val + " ↓ ");
                                       $('.showdatapl'+filtered[0].token).css({"color":"red"});
   
   
                                         }else if(val == 0){
                                        $('.showdatapl'+filtered[0].token).html(val + " ");
                                        $('.showdatapl'+filtered[0].token).css({"color":"black"});
                                         }

                               
                                     var ReturnPcval = (((parseFloat((response.bp1 * filtered[0].lot * filtered[0].qty)) - parseFloat((filtered[0].price * filtered[0].lot * filtered[0].qty))) / parseFloat((response.bp1 * filtered[0].lot * filtered[0].qty))) * 100).toFixed(2)
                                   
                                     if(ReturnPcval > 0){
                                        $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↑ ');
                                        $('.showdataReturnPc'+filtered[0].token).css({"color":"green"});
                                        
                                        
   
                                    }else if(ReturnPcval < 0){
                                        $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↓ ');
                                        $('.showdataReturnPc'+filtered[0].token).css({"color":"red"});
                                        
                                        
                                    }else if(ReturnPcval == 0){
                                        $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% )  ');
                                        $('.showdataReturnPc'+filtered[0].token).css({"color":"black"});
                                    }
                                    
                                    
                                    var single_trdae_price_show = (response.bp1 * filtered[0].lot * filtered[0].qty).toFixed(2);
                                    
                                    var single_trdae_price = ((response.bp1 * filtered[0].lot * filtered[0].qty) -(filtered[0].price * filtered[0].lot * filtered[0].qty)).toFixed(2);
                                    
                                    if(single_trdae_price>0){
                                        
                                        $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↑ ");
                                        $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"green"});
                                    }
                                    else if(single_trdae_price < 0){
                                        
                                        $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↓ ");
                                        $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"red"});
                                        
                                    }
                                    else if(single_trdae_price == 0){
                                        
                                        $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ");
                                        $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"black"});
                                        
                                    }
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                }else if(filtered[0].type == "sell"){
                                    var val = ((filtered[0].price * filtered[0].lot * filtered[0].qty) - (response.sp1 * filtered[0].lot * filtered[0].qty)).toFixed(2);
                                    
                                    
                                    
                                    if(val > 0){
                                        $('.showdatapl'+filtered[0].token).html(val + " ↑ ");
                                        $('.showdatapl'+filtered[0].token).css({"color":"green"});
                                        
                                        
                                    }else if(val < 0){
                                        $('.showdatapl'+filtered[0].token).html(val + " ↓ ");
                                        $('.showdatapl'+filtered[0].token).css({"color":"red"});
                                        
                                        
                                    }else if(val == 0){
                                        $('.showdatapl'+filtered[0].token).html(val + " ");
                                        $('.showdatapl'+filtered[0].token).css({"color":"black"});
                                    }
                                    
                                    
                                    
                                    var ReturnPcval =  (((parseFloat((filtered[0].price * filtered[0].lot * filtered[0].qty))-parseFloat((response.sp1 * filtered[0].lot * filtered[0].qty))) / parseFloat((response.sp1 * filtered[0].lot * filtered[0].qty))) * 100).toFixed(2);
                                    
                                    if(ReturnPcval > 0 && ReturnPcval !="Infinity"){
                                        $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↑ ');
                                        $('.showdataReturnPc'+filtered[0].token).css({"color":"green"});
                                        
                                        
                                    }else if(ReturnPcval < 0 && ReturnPcval !="Infinity"){
                                        $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↓ ');
                                        $('.showdataReturnPc'+filtered[0].token).css({"color":"red"});
                                        
                                        
                                    }else if(ReturnPcval == 0 && ReturnPcval !="Infinity"){
                                        $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% )  ');
                                        $('.showdataReturnPc'+filtered[0].token).css({"color":"black"});
                                    }
                                    
                                    
                                    
                                    var single_trdae_price_show = (response.sp1 * filtered[0].lot * filtered[0].qty).toFixed(2);
                                    
                                    var single_trdae_price = ((filtered[0].price * filtered[0].lot * filtered[0].qty)-(response.sp1 * filtered[0].lot * filtered[0].qty)).toFixed(2);
                                    
                                    if(single_trdae_price>0){
                                        
                                        $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↑ ");
                                        $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"green"});
                                    }
                                    else if(single_trdae_price < 0){
                                        
                                        $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↓ ");
                                        $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"red"});
                                        
                                    }
                                    else if(single_trdae_price == 0){
                                        
                                        $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ");
                                        $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"black"});

                                   }    


                                }



                               // var totalreturn_live_totalparseFloat = (totalreturn_live_skt)+parseFloat(totalreturn_exit)
                                
                        var totalreturn_live_totalparseFloat= pls(holdingloop,response)

                                
                        $('.showdataTotalReturnHolding').html((totalreturn_live_totalparseFloat).toFixed(2));
                       // console.log("okkkk total return",totalreturn_live_totalparseFloat);
                        
                        if(parseFloat(totalreturn_live_totalparseFloat)>0){
                            
                            
                            $('.showdataTotalReturnHolding').css({"color":"green"});
                        }
                        else if(parseFloat(totalreturn_live_totalparseFloat) < 0){
    
                            $('.showdataTotalReturnHolding').css({"color":"red"});
  
                        }
                        else if(parseFloat(totalreturn_live_totalparseFloat) == 0){
                             
                        $('.showdataTotalReturnHolding').css({"color":"black"});

                        } 



                         }
                        }

                      }

                  };
                  

                  socket.onclose = function(event) {
                      if (event.wasClean) {
                       // alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
                       connect(userId, userSession, returntokenstring,holdingloop)
                      // connect(userId, userSession, returntokenstring)
                       setSocketStatus(false)
                      } else {
                        // e.g. server process killed or network down
                        // event.code is usually 1006 in this case
                      //  alert('[close] Connection died');
                      connect(userId, userSession, returntokenstring,holdingloop)
                      setSocketStatus(false)
                      }
                    };
                
                
                    socket.onerror = function(error) {
                      connect(userId, userSession, returntokenstring,holdingloop)
                      setSocketStatus(false)
                      //alert(`[error]`);
                    };


             
          }
        };
        
        
    }



    function connectionRequest(userId, userSession) {
        var encrcptToken = CryptoJS.SHA256(
            CryptoJS.SHA256(userSession).toString()
        ).toString();
        // alert(encrcptToken);
        var initCon = {
            susertoken: encrcptToken,
            t: "c",
            actid: userId + "_" + type,
            uid: userId + "_" + type,
            source: type,
        };
        // console.log('initCon', initCon);
        socket.send(JSON.stringify(initCon));
    }

    // Socket End


      /// Forex Crypto Socket
  const endpoint = Config.base_url;


  const forexCrypto = (holdingloop) => {

  
      // Establish WebSocket connection when component mounts
      const socket = socketIOClient(endpoint);
  
      // Define event handlers
      socket.on('connect', () => {
        console.log('Connected to WebSocket');
      });
  
      socket.on('receive_data_forex', (message) => {
       // console.log('Received message:', message);

        if(message.type != undefined){
        let live_price = message.type == 'crypto' ? message.data[6].toFixed(3) : message.data[5].toFixed(6);
        let bid_bp1_price = message.type == 'crypto' ? message.data[5].toFixed(3) : message.data[4].toFixed(6);
        let ask_sp1_price = message.type == 'crypto' ? message.data[8].toFixed(3) : message.data[7].toFixed(6);
        
        // console.log('token:', message.data[1]);
        // console.log('live_price:', live_price);
        // console.log('bid_bp1_price:', bid_bp1_price);
        // console.log('ask_sp1_price:', ask_sp1_price);


      
          var filtered = holdingloop.filter(
            (item) => item.token == message.data[1] && item.qty != 0
          );
        //    console.log('filtered',filtered[0])
           
          if (filtered[0] !== undefined) {

           
          
            $(".showdatalp" + message.data[1]).html(live_price);
          
      
            $(".showdatabp1" + message.data[1]).html(bid_bp1_price);
         
         
            $(".showdatasp1" + message.data[1]).html(ask_sp1_price);
            
             if(filtered.length > 0){
             
                   if(filtered[0].type == "buy"){

                    //   console.log('bid_bp1_price ',bid_bp1_price , 'filtered[0].lot',filtered[0].lot , 'filtered[0].qty',filtered[0].qty , 'filtered[0].price',filtered[0].price)
                       var val = ((bid_bp1_price * filtered[0].lot * filtered[0].qty) - (filtered[0].price * filtered[0].lot * filtered[0].qty)).toFixed(2)


                    //    console.log('val',val)


                       if(val > 0){
                           $('.showdatapl'+filtered[0].token).html(val + " ↑ ");
                           $('.showdatapl'+filtered[0].token).css({"color":"green"});


                            }else if(val < 0){
                          $('.showdatapl'+filtered[0].token).html(val + " ↓ ");
                          $('.showdatapl'+filtered[0].token).css({"color":"red"});


                            }else if(val == 0){
                           $('.showdatapl'+filtered[0].token).html(val + " ");
                           $('.showdatapl'+filtered[0].token).css({"color":"black"});
                            }

                  
                        var ReturnPcval = (((parseFloat((bid_bp1_price * filtered[0].lot * filtered[0].qty)) - parseFloat((filtered[0].price * filtered[0].lot * filtered[0].qty))) / parseFloat((bid_bp1_price * filtered[0].lot * filtered[0].qty))) * 100).toFixed(2)
                      
                        if(ReturnPcval > 0){
                           $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↑ ');
                           $('.showdataReturnPc'+filtered[0].token).css({"color":"green"});
                           
                           

                       }else if(ReturnPcval < 0){
                           $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↓ ');
                           $('.showdataReturnPc'+filtered[0].token).css({"color":"red"});
                           
                           
                       }else if(ReturnPcval == 0){
                           $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% )  ');
                           $('.showdataReturnPc'+filtered[0].token).css({"color":"black"});
                       }
                       
                       
                       var single_trdae_price_show = (bid_bp1_price * filtered[0].lot * filtered[0].qty).toFixed(2);
                       
                       var single_trdae_price = ((bid_bp1_price * filtered[0].lot * filtered[0].qty) -(filtered[0].price * filtered[0].lot * filtered[0].qty)).toFixed(2);
                       
                       if(single_trdae_price>0){
                           
                           $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↑ ");
                           $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"green"});
                       }
                       else if(single_trdae_price < 0){
                           
                           $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↓ ");
                           $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"red"});
                           
                       }
                       else if(single_trdae_price == 0){
                           
                           $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ");
                           $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"black"});
                           
                       }
                       
                       
                       
                       
                       
                       
                       
                   }else if(filtered[0].type == "sell"){
                       var val = ((filtered[0].price * filtered[0].lot * filtered[0].qty) - (ask_sp1_price * filtered[0].lot * filtered[0].qty)).toFixed(2);
                       
                       
                       
                       if(val > 0){
                           $('.showdatapl'+filtered[0].token).html(val + " ↑ ");
                           $('.showdatapl'+filtered[0].token).css({"color":"green"});
                           
                           
                       }else if(val < 0){
                           $('.showdatapl'+filtered[0].token).html(val + " ↓ ");
                           $('.showdatapl'+filtered[0].token).css({"color":"red"});
                           
                           
                       }else if(val == 0){
                           $('.showdatapl'+filtered[0].token).html(val + " ");
                           $('.showdatapl'+filtered[0].token).css({"color":"black"});
                       }
                       
                       
                       
                       var ReturnPcval =  (((parseFloat((filtered[0].price * filtered[0].lot * filtered[0].qty))-parseFloat((ask_sp1_price * filtered[0].lot * filtered[0].qty))) / parseFloat((ask_sp1_price * filtered[0].lot * filtered[0].qty))) * 100).toFixed(2);
                       
                       if(ReturnPcval > 0 && ReturnPcval !="Infinity"){
                           $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↑ ');
                           $('.showdataReturnPc'+filtered[0].token).css({"color":"green"});
                           
                           
                       }else if(ReturnPcval < 0 && ReturnPcval !="Infinity"){
                           $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% ) ↓ ');
                           $('.showdataReturnPc'+filtered[0].token).css({"color":"red"});
                           
                           
                       }else if(ReturnPcval == 0 && ReturnPcval !="Infinity"){
                           $('.showdataReturnPc'+filtered[0].token).html('( ' + ReturnPcval + '% )  ');
                           $('.showdataReturnPc'+filtered[0].token).css({"color":"black"});
                       }
                       
                       
                       
                       var single_trdae_price_show = (ask_sp1_price * filtered[0].lot * filtered[0].qty).toFixed(2);
                       
                       var single_trdae_price = ((filtered[0].price * filtered[0].lot * filtered[0].qty)-(ask_sp1_price * filtered[0].lot * filtered[0].qty)).toFixed(2);
                       
                       if(single_trdae_price>0){
                           
                           $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↑ ");
                           $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"green"});
                       }
                       else if(single_trdae_price < 0){
                           
                           $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ↓ ");
                           $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"red"});
                           
                       }
                       else if(single_trdae_price == 0){
                           
                           $('.showdataSingleTradePrice'+filtered[0].token).html(single_trdae_price_show + " ");
                           $('.showdataSingleTradePrice'+filtered[0].token).css({"color":"black"});

                      }    


                   }



                  // var totalreturn_live_totalparseFloat = (totalreturn_live_skt)+parseFloat(totalreturn_exit)
                   
           var totalreturn_live_totalparseFloat= pls(holdingloop,"response")

                   
           $('.showdataTotalReturnHolding').html((totalreturn_live_totalparseFloat).toFixed(2));
          // console.log("okkkk total return",totalreturn_live_totalparseFloat);
           
           if(parseFloat(totalreturn_live_totalparseFloat)>0){
               
               
               $('.showdataTotalReturnHolding').css({"color":"green"});
           }
           else if(parseFloat(totalreturn_live_totalparseFloat) < 0){

               $('.showdataTotalReturnHolding').css({"color":"red"});

           }
           else if(parseFloat(totalreturn_live_totalparseFloat) == 0){
                
           $('.showdataTotalReturnHolding').css({"color":"black"});

           } 



            
           }

         }



        }

        
        

      });
  
      socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket');
      });
  
      // Clean up WebSocket connection when component unmounts
      return () => {
        socket.disconnect();
      };
    
  
  }
  //// Forex Crypto Socket ENd

function pls(getHoldingFund,response){

 var currentAmountValue = 0;
    var totalreturn_exit = 0;
    var totalreturn_live = 0;
    var totalreturn = 0;
   

    getHoldingFund && getHoldingFund.map((item) => {
        const { invest_amount, qty, symbol, token, lot, price,type,expiry,exch_seg } = item

        //var live_price = $('.showdatalp'+token).html(); 
            var live_price;
            if(type=="buy"){
            live_price = $('.showdatabp1'+token).html();          
            }else{
            live_price = $('.showdatasp1'+token).html();
            }

        // type=="buy"? totalreturn_live +=  parseFloat(((live_price * lot * qty) - (price * lot * qty))): totalreturn_live += parseFloat(((price * lot * qty) - (live_price * lot * qty)))

        if(live_price == undefined || live_price == ""){
            totalreturn_live += 0;
           }else{
         //   alert("oo")
            type=="buy"? totalreturn_live +=  parseFloat(((live_price * lot * qty) - (price * lot * qty))): totalreturn_live += parseFloat(((price * lot * qty) - (live_price * lot * qty)))
           }
 

    })


    // console.log("totalreturn_live holding",totalreturn_live)
    return totalreturn_live;

}
    







    function UpDownPrice(token) {

        var rr;
        allResponseSocket && allResponseSocket.forEach(element => {
            if (element.tk == token) {
                rr = element.c
            }
        });
        return rr
    }


    // function colorprice(currentAmount, investAmount) {
    //     // var res;
    //     // allResponseSocket && allResponseSocket.forEach(element => {
    //     //   if (element.tk == token) {
    //     //     res = val - element.c;
    //     //   }
    //     // });

    //     // if (res > 0) {
    //     //   return "green"
    //     // } else if (res < 0) {
    //     //   return "red"
    //     // } else {
    //     //   return "black"
    //     // }

    //     if (currentAmount >= investAmount) {
    //         return "green"
    //     } else if (currentAmount < investAmount) {
    //         return "red"
    //     } else {
    //         return "black"
    //     }

    // }

    // function colortotalreturn(val){
    
    //     if (val > 0) {
    //         return "green"
    //     } else if (val < 0) {
    //         return "red"
    //     } else if(val == 0){
    //         return "black"
    //     }  

    // }


    // function UpDownAero(currentAmount, investAmount) {
    //     var total = parseFloat(currentAmount) - parseFloat(investAmount);

    //    // console.log("total ", total)

    //     if (total > 0) {
    //         return " ↑ "
    //     } else if (total < 0) {
    //         return " ↓ "
    //     } else if (total == 0) {
    //         return ""
    //     }

    // }

    // function totalReturnPercentage(currentAmount, investAmount) {

    //     if (currentAmount && currentAmount) {
    //         var val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
    //         return '(' + val + '% )';
    //     }
    //     else {
    //         return ""
    //     }
    // }

    function colorprice(currentAmount, investAmount,type="") {
        // var res;
        // allResponseSocket && allResponseSocket.forEach(element => {
        //   if (element.tk == token) {
        //     res = val - element.c;
        //   }
        // });

        // if (res > 0) {
        //   return "green"
        // } else if (res < 0) {
        //   return "red"
        // } else {
        //   return "black"
        // }

        if(type == "buy"){
        
            if (currentAmount > investAmount) {
                return "green"
            } else if (currentAmount < investAmount) {
                return "red"
            } else {
                return "black"
            }  
       
        }else if(type == "sell"){

            if (currentAmount < investAmount) {
                return "green"
            } else if (currentAmount > investAmount) {
                return "red"
            } else {
                return "black"
            }

        }else{

            if (currentAmount > investAmount) {
                return "green"
            } else if (currentAmount < investAmount) {
                return "red"
            } else {
                return "black"
            }  


        }

      

    }

    function colortotalreturn(val){
    
        if (val > 0) {
            return "green"
        } else if (val < 0) {
            return "red"
        } else if(val == 0){
            return "black"
        }  

    }


    function UpDownAero(currentAmount, investAmount,type="") {
        
        var total = 0
        if(type == "buy"){
            total=    parseFloat(currentAmount) - parseFloat(investAmount);
        }else if(type == "sell"){
            total=   parseFloat(investAmount)-parseFloat(currentAmount);
        }else{
            total =  parseFloat(currentAmount) - parseFloat(investAmount);
        }
      
    //  var total = parseFloat(currentAmount) - parseFloat(investAmount);

    //    console.log("total ",total)
    
       

        if (total > 0) {
            return " ↑ "
        } else if (total < 0) {
            return " ↓ "
        } else if(total == 0){
            return ""
        }



    }

    function totalReturnPercentage(currentAmount, investAmount,type="") {
       if(currentAmount && currentAmount){
     
        var val = 0

        if(type == "buy"){
            val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
        }else if(type == "sell"){
            val = (((parseFloat(investAmount)-parseFloat(currentAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
        }else{
            val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
        }
       // var val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
        return '(' + val + '% )';
       }
       else{
        return ""
       }
    }



   

   

    function singleProfitLossTrade(token,type,lot,qty,price){
      
       //var live_price = $('.showdatalp'+token).html();
          var live_price;
            if(type=="buy"){
            live_price = $('.showdatabp1'+token).html();          
            }else{
            live_price = $('.showdatasp1'+token).html();
            }

       if(live_price && live_price){

       if(type=="buy"){

       var val = ((live_price * lot * qty) - (price * lot * qty)).toFixed(2)

       if(val>0){
        return val + " ↑ "
        }
        else if(val < 0){
        return val + " ↓ "
       }
       else if(val == 0){
        return val + " "
       }
    
       }else{

        var val =  ((price * lot * qty) - (live_price * lot * qty)).toFixed(2)

        if(val>0){
            return val + " ↑ "
        }else if(val < 0){
            return val + " ↓ "
        }
        else if(val == 0){
            return val + ""
        }

       }

    }else{
        return 0
    }
    
    }


    function colorpriceNew(token, price , lot , qty,type){
       // var live_price = $('.showdatalp'+token).html();  
           var live_price;
            if(type=="buy"){
            live_price = $('.showdatabp1'+token).html();          
            }else{
            live_price = $('.showdatasp1'+token).html();
            }
         
        var currentAmount = live_price*lot*qty
        var investAmount = price*lot*qty

        if(live_price && live_price){

        if(type == "buy"){
        
            if (currentAmount > investAmount) {
                return "green"
            } else if (currentAmount < investAmount) {
                return "red"
            } else {
                return "black"
            }  
       
        }else if(type == "sell"){

            if (currentAmount < investAmount) {
                return "green"
            } else if (currentAmount > investAmount) {
                return "red"
            } else {
                return "black"
            }

        }else{

            if (currentAmount > investAmount) {
                return "green"
            } else if (currentAmount < investAmount) {
                return "red"
            } else {
                return "black"
            }  


        }

    }else{

        return "black"
    }

    }


    function totalReturnPercentageNew(token,type,lot,qty,price){
        //var live_price = $('.showdatalp'+token).html();

        var live_price;
        if(type=="buy"){
        live_price = $('.showdatabp1'+token).html();          
        }else{
        live_price = $('.showdatasp1'+token).html();
        }


        var currentAmount = live_price*lot*qty;
        var investAmount = price*lot*qty

        if(currentAmount && currentAmount){
      
     if(type == "buy"){
      var val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)

        if(val>0){
        return '( ' + val + '% ) ↑ ';
        }else if(val < 0){
        return '( ' + val + '% ) ↓ ';
        }else if(val == 0){
         return '( ' + val + '% )  ';
        }

       }else if(type == "sell"){
      var val = (((parseFloat(investAmount)-parseFloat(currentAmount)) / parseFloat(currentAmount)) * 100).toFixed(2);
      if(val>0){
        return '( ' + val + '% ) ↑ ';
        }else if(val < 0){
        return '( ' + val + '% ) ↓ ';
        }else if(val == 0){
         return '( ' + val + '% )  ';
        }
      }else{
      var val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
      if(val>0){
        return '( ' + val + '% ) ↑ ';
        }else if(val < 0){
        return '( ' + val + '% ) ↓ ';
        }else if(val == 0){
         return '( ' + val + '% )  ';
        }
        }
           
          
           }
           else{
            return ""
           }   

    }



    function singleTradeLivePrice(token,type,lot,qty,price){
       // var live_p = $('.showdatalp'+token).html();
           var live_p;
            if(type=="buy"){
            live_p = $('.showdatabp1'+token).html();          
            }else{
            live_p = $('.showdatasp1'+token).html();
            }

        var live_price = (live_p * lot * qty).toFixed(2);
        // console.log("live_price typeof",typeof live_price)
        // console.log("live_price", live_price)
      

        if(live_price != "0.00"){

        if(type=="buy"){
 
        var val = ((live_p * lot * qty) - (price * lot * qty)).toFixed(2)
 
        if(val>0){
         return live_price + " ↑ "
         }
         else if(val < 0){
         return live_price + " ↓ "
        }
        else if(val == 0){
         return live_price + " "
        }
     
        }else{
 
         var val =  ((price * lot * qty) - (live_p * lot * qty)).toFixed(2)
 
         if(val>0){
             return live_price + " ↑ "
         }else if(val < 0){
             return live_price + " ↓ "
         }
         else if(val == 0){
             return live_price + ""
         }
 
        }

    }else if(live_price == NaN){
        
        return 0.00 
    }
    else if(live_price == "0.00"){
        
        return 0.00 
    }


    }

    return (
        // <div>  <div className="tab-pane fade" id="videos" role="tabpanel">
        <div className="section full ">
            <div className="holding-heading d-flex">
                {/* <div className="section-title"> */}
                <h3 className='m-0 flex-grow-1 '>
                    Holdings({holdingCount && holdingCount})
                </h3>
                {/* <button className='flex-grow-1'>
                        Verify Holdings
                    </button> */}
            </div>

            <div className=" section inset text-center  mt-2">
                <div className="wide-block pt-2 pb-2">
                    {/* <div className="content-header mb-05 pb-2">Market Today</div> */}
                    <div className='row '  >
                        <div className='col-6'>
                            <div className='d-flex justify-content-center align-items-center'>
                           
                            <span className='invested portfolio-icon'><i className="fa-light fa-chart-line-up"></i></span> 
                            <div className='pl-2'>
                            <h4 className='mb-0 text-left'>Invested</h4>
                          <p className='text-success mb-0' >{getInvestedAmmount && getInvestedAmmount ? <span className='text-black-50 text-bold'>₹</span> : ""}{(getInvestedAmmount && getInvestedAmmount ? parseFloat(getInvestedAmmount).toFixed(2) : " - ")}</p>
                        </div>
                        </div>
                      </div>
                        <div className='col-6'>
                            <div className='d-flex justify-content-center align-items-center'>
                            <span className='return portfolio-icon'><i className="fa-solid fa-money-bill-trend-up"></i></span>    
                            <div className='pl-2'>                        
                             <h4 className='mb-0 text-left'>  Total Returns</h4>
                          
                            {/*<p className='mb-0' style={{ color: colortotalreturn(totalreturn_live) }}>
                                   {totalreturn_live.toFixed(2)}</p>*/}
                                    <span className={`showdataTotalReturnHolding`}>-</span>
                            {/* <p className='mb-0' style={{ color: colorprice(currentAmountValue, getInvestedAmmount && getInvestedAmmount) }}>
                                {currentAmountValue && currentAmountValue ? <span className='text-black-50'>₹</span> : " - "}


                                {currentAmountValue && currentAmountValue ? (parseFloat(currentAmountValue) - parseFloat(getInvestedAmmount && getInvestedAmmount)).toFixed(2) : ""}</p>

                            <span>{<div style={{ color: colorprice(currentAmountValue, getInvestedAmmount && getInvestedAmmount) }} >{totalReturnPercentage(currentAmountValue, getInvestedAmmount && getInvestedAmmount)}</div>}</span> */}
                        </div>
                        </div>
                        </div>
                    </div>
                    {/* <hr/> */}

                </div>
            </div>

            <div className="wide-block pt-2 pb-2">
                <ul className="listview image-listview" >
                    {
                        getHoldingFund && getHoldingFund.length == 0 ?
                        <div className="no-data">
                        <p className='text-center'>No Data Found!</p>
                          <img src="/images/nodata.webp" className=""/>
                          </div>
                        :




                            getHoldingFund && getHoldingFund.map((item) => {
                                const { invest_amount, qty, symbol, token, lot, price,type,expiry,exch_seg } = item


                               

                         
                                // var currentprice = rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                //     k == token ? v : ""
                                // )) : ""

                                
                                // var percentage = rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                    //     k == token ? totalReturnPercentage((v * lot * qty).toFixed(2), price * lot * qty)  : ""
                                    // )) : ""
                                    
                                    
                               var currentprice = $('.showdatalp'+token).html();
                               var percentage = totalReturnPercentageNew(token,type,lot,qty,price)
                                
                                // console.log("currentprice",currentprice)
                                // console.log("percentage",percentage)
                           
                                return <>
                                    <li onClick={()=> handleShow(symbol,token,price,currentprice,percentage,qty,type,expiry,lot,exch_seg) }>
                                        <a  className="item">

                                            <div className="in align-items-start">
                                                <div className='grid-div-position'>
                                                    <header>
                                                    <span className="chip chip-outline chip-primary  px-2 py-1 text-left mr-1 mb-05">
                                                            {type}
                                                        </span>
                                                        {/* <div className="chip chip-outline chip-primary ml-05 mb-05"> */}
                                                        <span className="symbol-title" style={{ fontSize: '18px' }}>{symbol}</span>
                                                        {/* </div> */}
                                                        {/* <span className='ml-1'>0/qty</span> */}
                                                    </header>
                                                    <div className='grid-div'>
                                                    <span className=" ms-5 mb-1" style={{ fontSize: '14px' }}>LOT - {qty}
                                                    </span>
                                                    <span className=" ms-5 mb-1" style={{ fontSize: '14px' }}>QTY - {qty*lot}
                                                    </span>
                                                    {/* <span className="mb-1 ms-5 " style={{ fontSize: '14px' }}>LOT SIZE - {lot}</span> */}
                                                </div>
</div>



                                                <div className='d-flex flex-column text-right' style={{ position: 'relative', }}>

                                                    <span className="chip-label text-success d-flex justify-content-end mb-1" style={{ fontSize: '18px' }}><span className='text-black-50 ' style={{ paddingRight: '2px' }}>₹</span> 
                                                   



                                                     <div className={`showdatapl${token}`}></div>
                                                    
                                                     {/* {rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                                        <div key={k} style={{ color: colorprice(k == token ? (v * lot * qty).toFixed(2) : "", price * lot * qty,type) }}>{k == token ?type=="buy"?((v * lot * qty) - price * lot * qty).toFixed(2):(price * lot * qty - (v * lot * qty)).toFixed(2) : ""} </div>
                                                    )) : ""} */}


                                                        <span className="chip-label text-success d-flex" style={{ fontSize: '14px' }}>

                                                          
                                                            
                                                             {/* {rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                                            <div key={k} style={{ color: colorprice(k == token ? (v * lot * qty) : "", price * lot * qty,type) }}>{k == token ? type=="buy" ? (v * lot * qty) > price * lot * qty ? "↑" : (v * lot * qty) == price * lot * qty ? " ": "↓" :(v * lot * qty) < price * lot * qty ? "↑" : (v * lot * qty) == price * lot * qty ? " ": "↓" : ""}  </div>
                                                        )) : ""} */}
                                                        
                                                        
                                                        </span>
                                                    </span>




                                                    <div className="d-flex">
                                                        <span className="d-flex" style={{ fontSize: '14px' }}>
                                                            
                                                            {/* {rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                                            <div key={k} style={{ color: colorprice(k == token ? (v * lot * qty).toFixed(2) : "", price * lot * qty,type) }}>{k == token ? totalReturnPercentage((v * lot * qty).toFixed(2), price * lot * qty,type) : ""}</div>
                                                        )) : ""} */}

                                                            
                                                           {/* <div style={{ color: colorpriceNew(token, price , lot , qty,type) }}>{totalReturnPercentageNew(token,type,lot,qty,price)}</div> */}
                                                           {/* <div className={`showdataReturnPc${token}`}></div> */}
                                                            <span className="chip-label text-success d-flex" style={{ fontSize: '14px' }}> 
                                                            
                                                         
                                                            {/* {rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                                                <div key={k} style={{ color: colorprice(k == token ? (v * lot * qty).toFixed(2) : "", price * lot * qty,type) }}>{k == token ?type=="buy" ? (v * lot * qty) > price * lot * qty ? "↑" : (v * lot * qty) == price * lot * qty ? " ": "↓" :(v * lot * qty) < price * lot * qty ? "↑" : (v * lot * qty) == price * lot * qty ? " ": "↓" : ""} </div>
                                                            )) : ""} */}
                                                            
                                                            </span>
                                                        </span>
                                                        </div>
                                                        <span className=" mb-1" style={{ fontSize: '14px' }}>  {price} Avg.</span>
                                                    <span 
                                                     className='mb-1'
                                                    style={{ fontSize: '14px' }}> 
                                                     {type=="buy"? <><b className={`showdatabp1${token}`}></b></>:<><b className={`showdatasp1${token}`}></b>Crt.</>}
                                                    </span>
                                                    {/* <div className='d-flex'>

                                                    <span className='text-black-50 ' style={{ paddingRight: '2px' }}>₹</span> 
                                                     <div className={`showdataSingleTradePrice${token}`}></div>
                                                     <div className={`showdataReturnPc${token}`}></div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </a>


                                        <div className='d-flex justify-content-between' style={{ width: "100%" }}>
                                           
                                            {/* <span className='text-muted ' style={{ fontSize: '12px' }}>&#8377; {(price * lot * qty).toFixed(2)}</span> */}
                                           

                                            <span className="chip-label text-success d-flex " style={{ fontSize: '12px' }}>
                                                {/* <span className='text-black-50 ' style={{ paddingRight: '2px' }}>₹</span>  */}
                                            
                                             {/* {rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                                <div key={k} style={{ color: colorprice(k == token ? (v * lot * qty).toFixed(2) : "", price * lot * qty,type) }}>{k == token ? (v * lot * qty).toFixed(2) : ""}</div>
                                            )) : ""} */}

                                          
                                                
                                               {/* <div style={{ color: colorpriceNew(token, price , lot , qty,type) }}>{singleTradeLivePrice(token,type,lot,qty,price)}</div>*/}
                                             


                                                <span className="chip-label text-success d-flex" style={{ fontSize: '12px' }}>
                                                    
                                                     {/* {rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
                                                    <div key={k} style={{ color: colorprice(k == token ? (v * lot * qty).toFixed(2) : "", price * lot * qty,type) }}>{k == token ?type=="buy" ? (v * lot * qty) > price * lot * qty ? "↑" : (v * lot * qty) == price * lot * qty ? " ": "↓" :(v * lot * qty) < price * lot * qty ? "↑" : (v * lot * qty) == price * lot * qty ? " ": "↓" : ""}  </div>
                                                )) : ""} */}

                                              
                                                
                                                </span>
                                            </span>
                                        </div>
                                    </li>





                                </>
                            })

                    }


                </ul>
            </div>
            {/* </div> */}


            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
            >

<div className="d-flex justify-content-end align-items-center">
          <MdOutlineCancel
            onClick={handleClose}
            className="icon-large"
            style={{ fontSize: "30px",marginRight:"10px",marginTop:"5px"}}
          />
        </div>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className='p-4'>
                    <div>
                    <table className='table'>
                    <tr>
                    <td className='border-0'>  <h4 className='flex-grow-1'>{showModalsymbol} </h4></td>
                    <td className='border-0'><span >{showModalbuysell=="buy"? "Buy" : "Sell"} : </span>  
                    <span className='ml-1 border border-primary px-3 py-1 rounded mb-0 font-weight-bold'>{showModalqty}</span></td>
                    </tr>
                    <tr>
                    <td className='border-0'> <h4 className='flex-grow-1'>MKT &#8377; {showModalcurrentprice}{showModalpercentupdown}</h4></td>
                    <td className='border-0'> <span >AVG &#8377; {showModalaverageprice}</span></td>
                    </tr>
                    </table>
                    <hr/>
                     {/*  <div className='d-flex'>
                            <h4 className='flex-grow-1'>{showModalsymbol} </h4>
                            <span >{showModalbuysell=="buy"? "Buy" : "Sell"}</span> 
                            <span >{showModalqty}</span>
                        </div>

                        <div className='d-flex'>
                            <h4 className='flex-grow-1'>MKT &#8377; {showModalcurrentprice}{showModalpercentupdown}</h4>
                            <span >AVG &#8377; {showModalaverageprice}</span>
                        </div> */} 
                    </div>
                    <div className='d-flex'>

                        <div className="form-group boxed ">


                            <Link
                                className='btn btn-danger mr-1 mb-1  d-flex w-100'
                            // onClick={addClientbtn}
                            to={'/buysell'}
                            state={{ userid:user_id,currentprice: showModalcurrentprice,percentupdown:showModalpercentupdown, symbol : showModalsymbol,type:showModalbuysell,expiry: showModalexpiry ,lot:showModallot,token:showModaltoken,segment:showModalsegment ,qty:showModalqty, userId: socketuserid, userSession: socketuserSession ,type_submit : "sell"}} 
                            
                            >SELL<i className="fa-solid fa-hand-pointer ml-1"></i></Link>
                        </div>
                        <div className="form-group boxed ">
                            <Link
                                className='btn btn-success mr-1 mb-1  d-flex w-100'
                            // onClick={addClientbtn}
                            to={'/buysell'}
                               state={{userid:user_id ,currentprice: showModalcurrentprice,percentupdown:showModalpercentupdown, symbol : showModalsymbol , type:showModalbuysell,expiry: showModalexpiry , lot:showModallot,token:showModaltoken,segment:showModalsegment, qty:showModalqty, userId: socketuserid , userSession: socketuserSession , type_submit : "buy"}}
                            >BUY<i className="fa-solid fa-hand-pointer ml-1"></i></Link>
                        </div>

                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
            </Modal>
        </div>
        // </div>
    )
}

export default Holdings