import React from 'react'
import { Link } from 'react-router-dom'

const Profile = () => {
  const name= localStorage.getItem("UserName")
  return (
<div id="appCapsule">
  <div className="section mt-2">
    <div className="profile-head">
   
    <img src='assets/img/user.png' style={{height:'30px',width:'30px',marginRight:'5px'}}/>
    
      <div className="in">
        <h3 className="name username">{name}</h3>
        {/* <h5 className="subtext">Designer</h5> */}
      </div>
    </div>
  </div>

  <div className="" >
        <ul className="listview image-listview text flush transparent pt-1">
      <li>
          <Link to='/clientprofile' className="item">
              <div className="in">
                <div className="">Profile</div>
              </div>
            </Link>
          </li>

          <li>
          <Link to='/statement' className="item">
              <div className="in">
                <div className="">Statement</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/privacy_policy' className="item">
              <div className="in">
                <div>Privacy Policy</div>
              </div>
              </Link>
          </li>
          <li>
          <Link to='/terms_condition' className="item">
              <div className="in">
                <div>Terms And Condition</div>
              </div>
            </Link>
          </li>
          <li>
          <Link to='/fund_history' className="item">
              <div className="in">
                <div>Fund History</div>
              </div>
            </Link>
          </li>
          <li>
          <Link to='/changepassword' className="item">
              <div className="in">
                <div>Change Password</div>
              </div>
            </Link>
          </li>
          <li>
          <Link to='/payment' className="item">
              <div className="in">
                <div>Payment Scanner</div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
</div>

  )
}

export default Profile