import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, NavLink, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../component/admin_dashboard/Dashboard";
import Header from '../component/admin_dashboard/Header';
import Sidebar from '../component/admin_dashboard/Sidebar';
import Login from '../component/frontend/Login';
import Reports from '../component/admin_dashboard/reports/Reports';
import Withdraw from '../component/admin_dashboard/withdraw/Withdraw';
import DepositeHistory from '../component/admin_dashboard/DepositeHistory/DepositeHistory';
import Services from '../component/admin_dashboard/services/Services';
import Client from '../component/admin_dashboard/client/Client';
import Add_client from '../component/admin_dashboard/client/Add_client';
import Edit_client from '../component/admin_dashboard/client/Edit_client';
import Message_broadcast from '../component/admin_dashboard/message_broadcast/Message_broadcast';
import Help_center from '../component/admin_dashboard/help_center/Help_center';
import Trading_status from '../component/admin_dashboard/trading_status/Trading_status';
import Transaction_license from '../component/admin_dashboard/transaction_license/Transaction_license';
import Trade_history from '../component/admin_dashboard/trade_history/Trade_history';
import Stratergy from '../component/admin_dashboard/stratergy/Stratergy';
import Add_strategy_client from '../component/admin_dashboard/stratergy/Add_strategy_client';
import Add_strategy from '../component/admin_dashboard/stratergy/Add_strategy';
import Errorpage from '../component/frontend/Errorpage';
import Changepassword from '../component/admin_dashboard/changePassword/ChangePassword';
import AuthorityMain from '../component/admin_dashboard/AdminAuthority/AuthorityMain';
import AvailablePositions from '../component/admin_dashboard/AvailablePositions/AvailablePositions';
import EditSignals from '../component/admin_dashboard/EditSignals/EditSignals';
import Profile from '../component/admin_dashboard/profile/Profile';
import System from '../component/admin_dashboard/System/System';  
import SignIn from "../component/admin_dashboard/client/SignIn"

const Admin = () => {

  const location = useLocation();
  const navigate = useNavigate()
  const role_id = localStorage.getItem("Role")
  const user_token = localStorage.getItem("user_token")


  return (
    <div>

      {location.pathname !== "/admin" && location.pathname !== "/admin/" ? <Header /> : null}
      {location.pathname !== "/admin" && location.pathname !== "/admin/" ? <Sidebar /> : null}

      <Routes>
        <>
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/admin" element={<Login />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route exact  path="/authority" element={<AuthorityMain />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/depositehistory" element={<DepositeHistory />} />
          <Route path="/services" element={<Services />} />
          <Route path="/client" element={<Client />} />
          <Route path="/add_client" element={<Add_client />} />
          <Route path="/edit_client/:id" element={<Edit_client />} />
          <Route path="/message_broadcast" element={<Message_broadcast />} />
          <Route path="/help_center" element={<Help_center />} />
          <Route path="/trading_status" element={<Trading_status />} />
          <Route path="/transaction_license" element={<Transaction_license />} />
          <Route path="/trade_history" element={<Trade_history />} />
          <Route path="/stratergy" element={<Stratergy />} />
          <Route path="/add_strategy_client" element={<Add_strategy_client />} />
          <Route path="/add_strategy" element={<Add_strategy />} />
          <Route path="/adchangepassword" element={<Changepassword />} />
          <Route path="/availableposition" element={<AvailablePositions />} />
          <Route path="/editsingls" element={<EditSignals />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/system" element={<System/>} />
          <Route path="/signin" element={<SignIn/>} />
          <Route path="*" element={<Errorpage />} />
          
         
        </>
      </Routes>

    </div>
  )
}

export default Admin