import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import * as Config from "../../frontend/common/Config";
import socketIOClient from "socket.io-client";
import { GetAllClientNotifiaction } from "../../../services";
import { fDateTime } from "../../../component/frontend/common/formatTime";
import AlertToast from "../../../utils/AlertToast";
import { Link, useNavigate } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";

const Fund_history = () => {
  const accesstoken = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");
  const [allnotification, setAllnotification] = useState([]);

  const [refresh, setRefresh] = useState(false);


  // Alert Toast-----------------------------------------
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");

  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  const GetClientNotification = async () => {
    const req = {
      userid: UserId,
    };

    const response = await GetAllClientNotifiaction(req, accesstoken);
    if (response.data.status) {
      console.log("response.data",response.data.data)
      setAllnotification(response.data.data);
      return;
    }
  };

  

  useEffect(() => {

    GetClientNotification();
    const socket = socketIOClient(`${Config.base_url}`);
    socket.on("res_pendingwithdraw_accept_message", (data) => {
      if (data.userid == UserId) {
        
        GetClientNotification(data.userid);
        // setRefresh(!refresh);
        setShowAlert(true);
        setTextAlert(data.data);
        setAlertColor("success");
      }
    });

    socket.on("res_pendingwithdraw_reject_message", (data) => {
      if (data.userid == UserId) {
        GetClientNotification(data.userid);
        //   setRefresh(!refresh);
        setShowAlert(true);
        setTextAlert(data.data);
        setAlertColor("error");
      }
    });

    socket.on("res_pendingdeposite_accept_message", (data) => {
      if (data.userid == UserId) {
        GetClientNotification(data.userid);
        //   setRefresh(!refresh);
        setShowAlert(true);
        setTextAlert(data.data);
        setAlertColor("success");
      }
    });

    socket.on("res_pendingdeposite_reject_message", (data) => {
      if (data.userid == UserId) {
        GetClientNotification(data.userid);
        //   setRefresh(!refresh);
        setShowAlert(true);
        setTextAlert(data.data);
        setAlertColor("error");
      }


      
    });

    return () => {
      socket.disconnect();
    };
  }, [refresh]);





  

  const handleNotificationClick = async (userid) => {
    await GetClientNotification(userid, accesstoken);
    setAllnotification(
      allnotification.filter((notification) => notification.userid !== userid)
            
    );
    
  };

  
  return (


    <div className="section full">
      <Dropdown>
        <Dropdown.Toggle className="button-light">
          <i
            class="fa-sharp fa-solid fa-bell text-white"
            id="dropdown-basic"
          ></i>

          <span className="notification_Count">
            {allnotification.length < 10 ? allnotification.length : "9+"}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{ height: "400px", width: "400px", overflowY: "scroll" }}
        >
          <h3 className="text-center   border-bottom">Notification</h3>

          {allnotification.length == 0 ? (
            <Dropdown.Item className="d-flex flex-column float-left text-left">
              <ListGroup>
                {" "}
                <li
                  className="d-flex flex-column border-0 p-0"
                  style={{ fontSize: "14px" }}
                >
                  <div className="no-data">
                    <p className="text-center">No Data Found!</p>
                    <img src="/images/nodata.webp" className="" />
                  </div>
                </li>{" "}
              </ListGroup>
            </Dropdown.Item>
          ) : (
            <>
            
              {allnotification.map((item, index) => {
              
                return (
                  <>
                    <Dropdown.Item
                      className="d-flex flex-column float-left text-left"
                      key={index}
                      onClick={() => handleNotificationClick(item.userid)}
                    >
                      <ListGroup>
                        <li
                          className="d-flex flex-column border-0 p-0"
                          style={{ fontSize: "14px" }}
                        >
                          <Link
                            to={"/fund_history"}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            {item.notification_title}
                          </Link>

                          <span
                            className="text-secondary"
                            style={{ fontSize: "12px" }}
                          >
                            <Link
                              to={"/fund_history"}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {fDateTime(item.updatedAt)}
                            </Link>
                          </span>
                        </li>
                      </ListGroup>
                    </Dropdown.Item>
                  </>
                );
              })}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default Fund_history;
