import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GetTradingStatusOff } from "../../services/login.service";
import jwtDecode from 'jwt-decode'
import ClientNotification from "./ClientNotification/ClientNotiication"
import { getNiftyBankNiftyToken } from "../../services";


import { getQuote } from 'stock-nse-india';
import * as Config from './common/Config';

import $ from "jquery";
import CryptoJS from "crypto-js";


const Header = () => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sockets, setSockets] = useState(null);

  const [socketuserid, setSocketuserid] = useState("");
  const [socketuserSession, setSocketuserSession] = useState("");


  const [bankniftyToken, setBankniftyToken] = useState("");
  const [niftyToken, setNiftyToken] = useState("");

  const [tokenPC, setTokenPC] = useState("");
  const [tokenPCAero, setTokenPCAero] = useState("");

  const [refreshscreen, setRefreshscreen] = useState(true);

  const [show1, setShow1] = useState();
  const name = localStorage.getItem("UserName");
  const user_token = localStorage.getItem("user_token");

  const logout = async () => {
    const userId = localStorage.getItem("UserId");
    const Token = localStorage.getItem("user_token");
    const data = {
      userid: userId,
    };
    const res = await GetTradingStatusOff(data, Token);
    if (res.data.status === true) {
      localStorage.removeItem("user_token");
      localStorage.removeItem("Role");
      localStorage.removeItem("UserId");
      localStorage.removeItem("UserName");
      navigate("/");
    }
  }

  const { exp } = jwtDecode(user_token)
  const ExpirationTime = (exp * 1000) - 60000
  // console.log("exp", exp);
  if (Date.now() >= ExpirationTime) {
    localStorage.removeItem("user_token");
    localStorage.removeItem("Role");
    localStorage.removeItem("UserId");
    localStorage.removeItem("UserName");
    navigate('/');
  }



  /// Socket 
  var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
  let AuthorizationToken;
  let type = "API";

  function TokenAndClientCode(returntokenstring) {
    // alert(token);

    var config = {
      method: "get",
      url: `${Config.base_url}api/alicebluetoken`,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        
        if (response.data.status == "true") {
          //  console.log('Token Available', response.data.data[0].client_code + '  ' + response.data.data[0].access_token);
          setSocketuserid(response.data.data[0].client_code);
          setSocketuserSession(response.data.data[0].access_token);

          createSession(
            response.data.data[0].client_code,
            response.data.data[0].access_token,
            returntokenstring


          );
        } else {
          //  console.log('Token Not Available');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function invalidateSession(userId, userSession, returntokenstring) {
    //alert('0');

    AuthorizationToken = "Bearer " + userId + " " + userSession;
    //console.log('AuthorizationToken',AuthorizationToken);
    let jsonObj = {
      loginType: type,
    };
    $.ajax({
      url: BASEURL + "api/ws/invalidateSocketSess",
      headers: {
        Authorization: AuthorizationToken,
      },
      type: "post",
      data: JSON.stringify(jsonObj),
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (msg) {
        var data = JSON.stringify(msg);
        if (msg.stat == "Ok") {
          createSession(userId, userSession, returntokenstring);
        }
      },
    });
  }

  function createSession(userId, userSession, returntokenstring) {
    //alert('create session')

    AuthorizationToken = "Bearer " + userId + " " + userSession;

    //console.log('AuthorizationToken cratesession',AuthorizationToken);

    let jsonObj = {
      loginType: type,
    };
    $.ajax({
      url: BASEURL + "api/ws/createSocketSess",
      headers: {
        Authorization: AuthorizationToken,
      },
      type: "post",
      data: JSON.stringify(jsonObj),
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (msg) {
        var data = JSON.stringify(msg);
        if (msg.stat == "Ok") {
          connect(userId, userSession, returntokenstring);
        } else {
          alert(msg);
        }
      },
    });
  }

  const url = "wss://ws1.aliceblueonline.com/NorenWS/";
  let socket;
  function connect(userId, userSession, returntokenstring) {
    // abcc(token)

    socket = new WebSocket(url);

    // else{
    //   console.log('elseeee')
    // }

    socket.onopen = function () {
      connectionRequest(userId, userSession);
      //alert('okk socket open')
      //  console.log('token ffffffff',token)

      setSockets(socket)

      //  console.log('xyzzzzz',xyz(socket,token))
    };
    socket.onmessage = function (msg) {
      var response = JSON.parse(msg.data);

      if (response.s == "OK") {

        var channel = returntokenstring;
        //console.log("channel", channel);
        let json = {
          k: channel,
          t: "t",
        };

        socket.send(JSON.stringify(json));

        socket.onmessage = function (msg) {
          var response = JSON.parse(msg.data);
          // console.log('response socket header', response)

          // if (response.tk) {
          //     if (response.lp !== undefined) {
          //         $('.showdatalp'+response.tk).html(response.lp); 
          //     }
          // }

          if (response.tk) {
            if (response.lp != undefined) {
              $('.showdatalp' + response.tk).html(response.lp);
            }

            if (response.pc != undefined) {

              $('.showdatapcval' + response.tk).html(response.pc);

              if (response.pc > 0) {
                $('.showdatapc' + response.tk).html(" ( " + response.pc + " % ) ↑ ");

              } else if (response.pc < 0) {
                $('.showdatapc' + response.tk).html(" ( " + response.pc + " % ) ↓ ");

              } else if (response.pc == 0) {
                $('.showdatapc' + response.tk).html(" ( " + response.pc + " % )  ");

              }

            }
          }
        };


        socket.onclose = function (event) {
          if (event.wasClean) {
            // alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            TokenAndClientCode(returntokenstring)
          } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            //  alert('[close] Connection died');
            TokenAndClientCode(returntokenstring)
          }
        };


        socket.onerror = function (error) {
          TokenAndClientCode(returntokenstring)
          //alert(`[error]`);
        };



      }
    };
  }


  function connectionRequest(userId, userSession) {
    var encrcptToken = CryptoJS.SHA256(
      CryptoJS.SHA256(userSession).toString()
    ).toString();
    // alert(encrcptToken);
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: userId + "_" + type,
      uid: userId + "_" + type,
      source: type,
    };
    // console.log('initCon', initCon);
    socket.send(JSON.stringify(initCon));
  }

  // Socket End

  const Overview = async () => {
    //alert("okkk");
    const response = await getNiftyBankNiftyToken();
    if (response.data.status) {
      //   console.log("hello world" , response.data.banknifty);
      setBankniftyToken(response.data.banknifty)
      setNiftyToken(response.data.nifty)
      TokenAndClientCode(response.data.data)
    }
    setShow(true);
  }


  function colorpriceNew(token) {

    var pcValue = $('.showdatapcval' + token).html();
    // console.log("pcValue",pcValue);

    if (pcValue > 0) {
      return "green";
    } else if (pcValue < 0) {
      return "red";
    }
    else if (pcValue == 0) {
      return "black";
    }

  }



  return (
    <div>
      {/* App Header */}
      <div className="appHeader bg-primary scrolled is-active">
        <div className="right">

          <button className="toggle bg-transparent border-0 headerButton goBack" onClick={() => Overview()}>
            <p className='mb-0 pr-1'>Indices</p>
            <ion-icon name="chevron-down-outline" />
          </button>

          <ClientNotification />

          <div className="dropdown">
            <button className="btn btn-primary dropdown-toggle px-0" type="button" data-toggle="dropdown" aria-expanded="false">
              <div className="d-flex align-items-center">
                <div className="image-wrapper d-flex">
                  {/* <ion-icon name="person-circle-outline" style={{marginRight:'0px'}}></ion-icon>*/}
                  <img src='assets/img/user.png' style={{ height: '30px', width: '30px', marginRight: '5px' }} />
                </div>
                <div className="">
                  <strong className='username'>{name}</strong>
                </div>
              </div>
            </button>
            <div className="dropdown-menu" style={{}}>
              <button className='btn btn-light' onClick={logout}>Logout</button>
            </div>
          </div>
        </div>

        <div className="pageTitle"></div>
        <div className="left">

        </div>
      </div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header  className='border-0 pb-0'>
          
          <Modal.Title>Indices</Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}><i class="fa-solid fa-x"></i></button>
        </Modal.Header>
        <Modal.Body className='py-0'>
          <div className="form-group ">
            <div className='container mt-2'>
              <div className='row'>
                <div className='col-6'>

                  <span style={{ display: "none" }} className={"showdatapcval" + niftyToken}></span>
                  <span style={{ display: "none" }} className={"showdatapcval" + bankniftyToken}></span>
                  <div className='dashboard-boxes text-center border  rounded'>
                  <h4>NIFTY 50</h4>
                  {/* <p className='text-success'>18725.25</p> */}

                  <div style={{ fontSize: '15px', color: colorpriceNew(niftyToken) }}  ><span className={"showdatalp" + niftyToken}></span><span className={"showdatapc" + niftyToken}></span></div>
                  </div>
                </div>
                <div className='col-6'>
                <div className='dashboard-boxes text-center border rounded'>
                  <h4 > BANK NIFTY </h4>
                  {/* <p className='text-danger'>18725.25</p> */}
                  <div style={{ fontSize: '15px', color: colorpriceNew(bankniftyToken) }}  >
                    <span className={"showdatalp" + bankniftyToken}></span>
                    <span className={"showdatapc" + bankniftyToken}></span>
                    </div>
                </div>
                </div>
              </div>
              {/* <div className='row'>
                <div className='col-12'>
                  <h3>Funds: ₹0.00</h3>
                </div>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* * App Header */}
      {/* Search Component */}
      {/* * Search Component */}
      {/* Search Component */}
      {/* * Search Component */}
      <div style={{ height: '120px', background: '#2f55a3', borderRadius: '12px', position: 'fixed', width: '100%' }}></div>
    </div>

  )
}

export default Header