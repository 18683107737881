import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import Complete from './Complete';
import Pending from './Pending';
import Rejects from './Rejects';

const Reports = () => {
  const [pendingKey, setPendingKey] = useState(0);
  const [completeKey, setCompleteKey] = useState(0);
  const [rejectKey, setRejectKey] = useState(0);

  const refreshPendingTab = () => {
    setPendingKey((prevKey) => prevKey + 1);
  };

  const refreshCompleteTab = () => {
    setCompleteKey((prevKey) => prevKey + 1);
  };

  const refreshRejectTab = () => {
    setRejectKey((prevKey) => prevKey + 1);
  };


  
  useEffect(() => {
    const table = document.querySelector('.rdt_Table');

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener('dragstart', preventDrag);

      return () => {
        table.removeEventListener('dragstart', preventDrag);
      };
    }
  }, []);

  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <strong className="conteudo-title">Deposit History</strong>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner">
                    <div className="section full mt-1">
                      <div className="wide-block pt-2 pb-2">
                        <ul className="nav nav-tabs style1" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#pending"
                              role="tab"
                              aria-selected="true "
                              onClick={refreshPendingTab}
                            >
                              Pending
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#complete"
                              role="tab"
                              aria-selected="false"
                              onClick={refreshCompleteTab}
                            >
                              Complete
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#reject"
                              role="tab"
                              aria-selected="false"
                              onClick={refreshRejectTab}
                            >
                              Reject
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content mt-2">
                          <div className="tab-pane fade active show" id="pending" role="tabpanel">
                            <Pending key={pendingKey} />
                          </div>
                          <div className="tab-pane fade" id="complete" role="tabpanel">
                            <Complete key={completeKey} />
                          </div>
                          <div className="tab-pane fade" id="reject" role="tabpanel">
                            <Rejects key={rejectKey} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
