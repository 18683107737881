import axios from "axios";
import * as Config from "../../frontend/common/Config";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { fDate } from "../../../component/frontend/common/formatTime";

import Button from "react-bootstrap/Button";
import DataTableExtensions from "react-data-table-component-extensions";
import {
  GetTradeHistoryClients,
  GetTradeHistoryClientsTrad,
} from "../../../services";

const Trade_history = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [client, setClient] = useState([]);
  const [TradeHistorydata, setTradeHistorydata] = useState([]);
  const [tradeHistorydataPrevious, setTradeHistorydataPrevious] = useState([]);
  const [tradeHistorydataall, setTradeHistorydataall] = useState([]);
  const [holdingId, setHoldingId] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fromdata, setFromDate] = useState("");
  const [abc, setAbc] = useState();
  const [todate, setToDate] = useState("");
  const [stateTCPL, setStateTCPL] = useState("");

  const AuthLogin = localStorage.getItem("user_token");
  const AuthRole = localStorage.getItem("Role");
  const UserId = localStorage.getItem("UserId");

  const Clientdata = async () => {
    var data1 = {
      admin_id: UserId,
    };

    const response1 = await GetTradeHistoryClientsTrad(data1, AuthLogin);

    if (response1.status === 200) {

      setClient(response1.data.data);

      response1.data.data.filter((x) => {});
    } else {
      console.log("error", response1.data.data);
    }

    const filterInput = document.querySelector(".filter-text");

    if (filterInput) {

      filterInput.placeholder = "Search Here...";
    }

    // const data = {
    //   "userid": 3,
    //   "fromdate": "2022-12-01",
    //   "todate": "",

    //   // "fromdate": fromdata,
    //   // "todate": todate,
    // }

    // const response = await GetTradeHistoryClients(data, AuthLogin);

    // var buy = []
    // var sell = []
    // var notbuysell = []

    // response.data.data.filter((item) => {

    //   if (item.type === 'buy') {
    //     return buy.push(item)
    //   } else if (item.type === 'sell') {
    //     return sell.push(item)
    //   }
    //   else {
    //     return notbuysell(item)
    //   }

    // })

  };

  const colums = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
    },
    {
      name: "User Name",
      selector: (row) => row.username,
    },
    {
      name: "Full Name",
      selector: (row) => row.fullname,
    },

    {
      name: "Start Date",
      selector: (row) => fDate(row.start_date),
    },
    {
      name: "End Date",
      selector: (row) => fDate(row.end_date),
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="action-btn">
          {/* <Tooltip title="Edit" arrow><button className='btn btn-success  mr-2 rounded-circle'><i className="fa-solid fa-pen-to-square "></i></button></Tooltip>
   <Tooltip title="Delete" arrow><button className='btn btn-danger  mr-2 rounded-circle'><i className="fa-solid fa-trash "></i></button></Tooltip> */}
          <Tooltip title="View Detail" arrow>
            <button
              className="btn btn-warning  rounded-circle"
              onClick={(e) => {
                GetOrderList(row.id, e);
                setAbc(row.id);
              }}
            >
              <i className="fa-solid fa-eye "></i>
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];



  // console.log("fromdata", fromdata);

  const GetOrderList = async (id, e) => {
    e.preventDefault();
    setShow(true);

    const data = {
      userid: id,
      // "fromdate": "2022-11-21",
      // "todate": "",
      fromdate: fromdata === "" ? "" : fromdata,
      todate: todate === "" ? "" : todate,
    };

    const response = await GetTradeHistoryClients(data, AuthLogin);
    if (response.data.status) {
      setTradeHistorydata(response.data.data);
      var ptid = [];
      response.data.data.forEach((ele) => {
        if (ele.previous_trade_id != null) {
          ptid.push(ele.previous_trade_id);
        }
      });

      var pid = [];
      response.data.data.forEach((ele) => {
        if (ele.previous_trade_id != null) {
          if (ptid.includes(ele.previous_trade_id.toString()) == true) {
            pid.push(ele);
          }
        }
      });

      var pidd = [];
      pid.forEach((ele) => {
        pidd.push(ele.id);
      });
      //console.log("pid", pid);
      // console.log("pidd", pidd);
      setTradeHistorydataPrevious(ptid);
      setTradeHistorydataall(pidd);
      setHoldingId(response.data.holdings_id);
    } else {
      setTradeHistorydata([response.data.data]);
    }
  };

  // const SearchDateFilter = async (e) => {
  //   e.preventDefault();

  //   const data = {
  //     "userid": abc,
  //     // "fromdate": "2022-11-21",
  //     // "todate": "",
  //     "fromdate": startDate,
  //     "todate": endDate
  //   }
  //   const response = await GetTradeHistoryClients(data, AuthLogin);
  //   // console.log("response.data.data", response.data.data);
  //   if (response.data.status) {
  //     setTradeHistorydata(response.data.data);
  //   } else {
  //     setTradeHistorydata([response.data.data]);

  //   }

  // }

  useEffect(() => {
    Clientdata();
  }, []);

  useEffect(() => {}, [startDate, endDate]);

  var CPL = 0;
  var TCPL = 0;
  var trade_symboll = [];
  var cal = [];
  var sumVal = 0;
  var temp;

  var holdprofitlost;

  useEffect(() => {
    const table = document.querySelector(".rdt_Table");

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener("dragstart", preventDrag);
      table.addEventListener("dragover", preventDrag);
      table.addEventListener("drop", preventDrag);

      return () => {
        table.removeEventListener("dragstart", preventDrag);
        table.removeEventListener("dragover", preventDrag);
        table.removeEventListener("drop", preventDrag);
      };
    }
  }, []);

  const calculation = (aa) => {
    var element = 0;
    for (let index = 0; index < cal.length; index++) {
      element += cal[index];

      // console.log("cal[index]" ,element +cal[index])
    }

    return (TCPL = element.toFixed(2));
  };

  const squareOff = (trade, holdingId, squareoff_qty) => {
    var userid = trade.userid;

    var squareoff_type = "";

    if (trade.type == "buy") {
      squareoff_type = "sell";
    } else if (trade.type == "sell") {
      squareoff_type = "buy";
    }

    alert(userid);

    var data = JSON.stringify({
      userid: userid,
    });

    var config = {
      method: "post",
      url: `${Config.base_url}api/useravailabefunds`,
      headers: {
        // 'x-access-token': accesstoken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        // console.log("dd", typeof response.data.availablefund);
        if (response.data.status == true) {
          var avalableFund = response.data.availablefund;
          // console.log("avalableFund", avalableFund)

          // var data = {
          //   userid: userid,
          //   symbol: trade.symbol,
          //   qty: squareoff_qty,
          //   type: squareoff_type,
          //   price: locationDatanew.price,
          //   expiry: trade.expiry,
          //   totalamount: locationDatanew.totalamount,
          //   lot: trade.lot,
          //   avalableFund:avalableFund,
          //   token:.trade.token,
          //   segment:locationDatanew.segment
          // };
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  let cplarr = [];
  var cplarr1 = [];
  const calCPL = (type, s) => {
    cplarr.push(parseInt(s));
    if (type != "ENTRY") {
      let sum = cplarr.reduce((s, f) => {
        return s + f;
      }, 0);
      cplarr1.push(sum);
      return sum;
    } else {
      return "-";
    }
  };



  let cplarr3 = [];
  var cplarr4 = [];

  const CPLColor = (type, s) => {
    cplarr3.push(parseInt(s));
    if (type != "ENTRY") {
      let sum = cplarr3.reduce((s, f) => {
        return s + f;
      }, 0);
      cplarr4.push(sum);

      return sum > 0;
    } else {
      return "-";
    }
  };

  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <div className="row">
                <div className="col-md-8">
                  <strong className="conteudo-title">Trade History</strong>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">
                    <DataTableExtensions
                      columns={colums}
                      data={client}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        paginationRowsPerPageOptions={[10, 50, 100]}
                        paginationComponentOptions={{
                          selectAllRowsItem: true,
                          selectAllRowsItemText: "All",
                        }}
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="trade-history-modal-table"
      >
        <Modal.Header>
          <Modal.Title>Trade History</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            className="table-responsive trade-history-table-modal"
            style={{ height: "400px" }}
          >
            <table className="table trade-table ">
              <thead>
                <tr>
                  <th>S .No</th>
                  <th>Symbol</th>
                  <th>Lot</th>
                  <th>Lot Size</th>
                  <th>Type</th>
                  <th>Entry Price</th>
                  <th>Exit Price</th>

                  <th>Profit/Loss</th>
                  <th>Cumulative P&L</th>
                  <th>CreatedAt</th>
                  {/* <th>Action</th> */}

                </tr>
              </thead>

              <tbody>
                {TradeHistorydata[0] == "Data Not Found" ? (
                  <th colspan="8" className="text-center">
                    {TradeHistorydata}
                  </th>
                ) : (
                  <>
                    {TradeHistorydata &&
                      TradeHistorydata.map((trade, i) => {
                        var squareoff_qty = 1;

                        var all_holdings_id = [];
                        holdingId.forEach((ele) => {
                          all_holdings_id.push(ele.id);

                          if (ele.id == trade.id) {
                            squareoff_qty = ele.qty;
                          }
                        });

                        trade_symboll[i] = trade.symbol;

                        var prev_price = 0;
                        if (trade.type_entry_exit === "EXIT") {
                          if (TradeHistorydata[i - 1]) {
                            prev_price = TradeHistorydata[i - 1].price;
                          } else {
                            prev_price = 0;
                          }
                        }

                        return (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{trade.symbol}</td>
                              <td>{trade.qty}</td>
                              <td>{trade.lot}</td>
                              <td>{trade.type}</td>
                              
                              

                              {/* <td>
                                {trade.type === "buy"
                                  ? "Sell"
                                  : trade.type === "sell"
                                  ? "Buy"
                                  : trade.type}
                              </td> */}

                              <td>
                                {trade.type_entry_exit === "ENTRY"
                                  ? trade.price
                                  : "-"}
                              </td>
                              <td>
                                {trade.type_entry_exit === "EXIT"
                                  ? trade.price
                                  : "-"}
                              </td>

                              <td
                                style={{
                                  color:
                                    trade.type_entry_exit == "ENTRY"
                                      ? "black"
                                      : trade.type == "sell"
                                      ? parseFloat(
                                          (trade.price - prev_price) *
                                            trade.qty *
                                            trade.lot
                                        ).toFixed(2) > 0
                                        ? "#4BB543"
                                        : "red"
                                      : parseFloat(
                                          (prev_price - trade.price) *
                                            trade.qty *
                                            trade.lot
                                        ).toFixed(2) > 0
                                      ? "#4BB543"
                                      : "red",
                                }}
                              >
                                {
                                  (temp =
                                    trade.type_entry_exit === "EXIT"
                                      ? TradeHistorydata[i - 1]
                                          .type_entry_exit === "ENTRY"
                                        ? trade.type == "sell"
                                          ? (holdprofitlost = parseFloat(
                                              (
                                                (trade.price - prev_price) *
                                                trade.qty *
                                                trade.lot
                                              ).toFixed(2)
                                            ))
                                          : (
                                              (prev_price - trade.price) *
                                              trade.qty *
                                              trade.lot
                                            ).toFixed(2)
                                        : "_"
                                      : "_")
                                }
                              </td>
                              {/* <td style={{ fontSize: '15px', fontWeight: '700', color: parseFloat((trade.price - prev_price) * trade.qty * trade.lot).toFixed(2) > 0 ? "#4BB543" : 'red' }}>{
                                trade.type_entry_exit === 'EXIT' ?
                                  TradeHistorydata[i - 1].type_entry_exit === 'ENTRY' ?
                                    trade.type == "sell" ? holdprofitlost = parseFloat(((trade.price - prev_price) * trade.qty * trade.lot).toFixed(2)) : (((prev_price - trade.price) * trade.qty * trade.lot).toFixed(2))
                                    : "_"
                                  : "_"
                              }
                              </td> */}
                              <td
                                style={{
                                  color:
                                    trade.type_entry_exit === "ENTRY"
                                      ? "black"
                                      : CPLColor(
                                          trade.type_entry_exit,
                                          temp.toString().includes("_")
                                            ? "0"
                                            : temp.toString()
                                        )
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {calCPL(
                                  trade.type_entry_exit,
                                  temp.toString().includes("_")
                                    ? "0"
                                    : temp.toString()
                                )}
                              </td>
                              <td>{new Date(trade.createdAt).toLocaleString()}</td>
                              {/* <td>{
                       
                             all_holdings_id.includes(trade.id) == true ?
                            <><button className=' btn btn-success' onClick={() => squareOff(trade,holdingId,squareoff_qty)}>Square Off</button></>
                            : ""

                        }
                        </td> */}

                              <td>
                                {
                                  //        tradeHistorydataPrevious.includes(trade.id.toString()) == false ? tradeHistorydataall.includes(trade.id) == false ?
                                  //        <><button className='btn btn-warning'>Square Off</button></> : "":""
                                  //     //   tradeHistorydataPrevious.includes(trade.id.toString()) == false ? <><button className='btn btn-warning'>Square Off</button></> : remainqty = "no" ?
                                  //     //  <> <button className='btn btn-warning'>Square Off</button> </> : ""
                                }
                              </td>
                              {/* <td><button className='btn btn-warning'>Square Off</button></td> */}
                            </tr>
                          </>
                        );
                      })}
                  </>
                )}
                <td colspan="8">
                  <h3 style={{ fontWeight: 900 }}>Total cumulative P&L</h3>
                </td>

                <td>
                  <h3
                    style={{
                      fontWeight: 900,
                      color:
                        parseInt(cplarr1[cplarr1.length - 1]) > 0
                          ? "#4BB543"
                          : "red",
                    }}
                  >
                    {cplarr1[cplarr1.length - 1]}
                  </h3>
                </td>

                <td> </td>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
        Save Changes
      </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default Trade_history;
