import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";

import { UserHoldingsFund, GetPosition } from "../../../services";
import CryptoJS from "crypto-js";
import $ from "jquery";
import "../dashboard.css";
import * as Config from "../common/Config";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdOutlineCancel } from "react-icons/md";

import socketIOClient from 'socket.io-client';

const Positons = (props) => {
 
  const [getHoldingFund, setGetHoldingFund] = useState([]);
  // console.log("jj -",getHoldingFund.length);
  const [getInvestedAmmount, setGetInvestedAmmount] = useState("");

  const [GetPostionfund, setGetPostionfund] = useState("");
  const [holdingCount, setHoldingCount] = useState(0);
  const [socketUserId, setSocketUserId] = useState("");
  const [socketUserToken, setSocketUserToken] = useState("");
  // console.log("socketUserId" ,socketUserId);
  // console.log("socketUserToken" ,socketUserToken);

  //console.log("holdingCount" ,holdingCount);

  const [showAlert, setShowAlert] = useState(false);
  // const [textAlert, setTextAlert] = useState("");
  // const [alertColor, setAlertColor] = useState("");
  const [tokenMatch, setTokenMatch] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [tokenMatchArray, setTokenMatchArray] = useState([]);
  const [arrayobject, setArrayobject] = useState([]);
  const [sockets, setSockets] = useState(null);
  const [allResponseSocket, setAllResponseSocket] = useState([]);

  // const [show, setShow] = useState(false);

  const [showModaltoken, setShowModaltoken] = useState("");
  const [showModalsymbol, setShowModalsymbol] = useState("");
  const [showModalaverageprice, setShowModalaverageprice] = useState("");
  const [showModalcurrentprice, setShowModalcurrentprice] = useState("");
  const [showModalpercentupdown, setShowModalpercentupdown] = useState("");
  const [showModalqty, setShowModalqty] = useState("");
  const [showModalbuysell, setShowModalbuysell] = useState("");
  const [showModalexpiry, setShowModalexpiry] = useState("");
  const [showModallot, setShowModallot] = useState("");
  const [showModalsegment, setShowModalsegment] = useState("");

  const handleClose = () => {
    setShow(false);
    setShowModaltoken("");
    setShowModalsymbol("");
    setShowModalaverageprice("");
    setShowModalcurrentprice("");
    setShowModalpercentupdown("");
    setShowModalqty("");
    setShowModalbuysell("");
    setShowModalexpiry("");
    setShowModallot("");
    setShowModalsegment("");
  };
  const handleShow = (
    symbol,
    token,
    price,
    currentprice,
    percentage,
    qty,
    type,
    expiry,
    lot,
    segment
  ) => {
    setShow(true);
    setShowModalsymbol(symbol);
    setShowModaltoken(token);
    setShowModalaverageprice(price);
    setShowModalcurrentprice(currentprice);
    setShowModalpercentupdown(percentage);
    setShowModalqty(qty);
    setShowModalbuysell(type);
    setShowModalexpiry(expiry);
    setShowModallot(lot);
    setShowModalsegment(segment);
  };

  const UserToken = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const [auth, setAuth] = useState("");
  const [adddata, setAdddata] = useState([]);
  const [posts, setPosts] = useState([]);
  const [uid, setUid] = useState("");
  const [searcdata, setSearcdata] = useState();
  // console.log("searcdata", searcdata);
  const [watchdata, setWatchdata] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [show, setShow] = useState(false);
  const [listData, setListData] = useState([]);
  const [listDataExcSeg, setListDataExcSeg] = useState([]);
  // console.log("listData", listData);
  const [tokenSymbol, setTokenSymbol] = useState([]);
  const [socketuserid, setSocketuserid] = useState("");
  const [socketuserSession, setSocketuserSession] = useState("");
  const [socketStatus, setSocketStatus] = useState(false);



  const GetOrderList = async () => {
    // alert("possition")

    const data = {
      userid: user_id,
    };
    const response = await GetPosition(data, UserToken);
    setGetHoldingFund(response.data.data);
    setHoldingCount(response.data.data.length);



    if (response.data.status) {
      let initialValue = 0;
      var tokenArr = [];
      var tokenArrExcSeg = [];

      var tokenstring = "";

      forexCrypto(response.data.data);

      response.data.data.map((total) => {
        // initialValue +=  parseFloat(total.price);
        initialValue +=
          parseInt(total.qty) * parseFloat(total.price) * total.lot;
        //   tokenArr.push(total.token);
        //  console.log("total ",total.exch_seg)

        tokenstring += total.exch_seg + "|" + total.token + "#";

        // tokenArrExcSeg.push({ token: total.token, exch_seg: total.exch_seg });
        // setListData(tokenArr);
        // setListDataExcSeg({ tokenArrExcSeg });
      });

      var returntokenstring = tokenstring.substring(0, tokenstring.length - 1);
      //  console.log(" sd  returntokenstring  returntokenstring", returntokenstring);

      //   setListDataChannel(returntokenstring);
      if (sockets == null) {
        TokenAndClientCode(returntokenstring, response.data.data);
       
      } else {
        var totalreturn_live_totalparseFloat = pls(response.data.data, "");

        //console.log(" totalreturn_live_totalparseFloat g", totalreturn_live_totalparseFloat);


        $(".showdataTotalReturn").html(
          totalreturn_live_totalparseFloat.toFixed(2)
        );
        if (totalreturn_live_totalparseFloat > 0) {
          $(".showdataTotalReturn").css({ color: "green" });
        } else if (totalreturn_live_totalparseFloat < 0) {
          $(".showdataTotalReturn").css({ color: "red" });
        } else if (totalreturn_live_totalparseFloat == 0) {
          $(".showdataTotalReturn").css({ color: "black" });
        }
      }
     
      return setGetInvestedAmmount(initialValue);
      
    } else {
      //setGetHoldingFund([response.data.data])
    }

  };

  useEffect(() => {
    GetOrderList();
  }, [props.key]);

  useEffect(() => {}, [sockets]);

  /// Socket
  var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
  let AuthorizationToken;
  let type = "API";

  function TokenAndClientCode(returntokenstring, holdingloop) {
    // alert(token);

    var config = {
      method: "get",
      url: `${Config.base_url}api/alicebluetoken`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        //  console.log(" response ", response.data.status);
        if (response.data.status == "true") {
          setSocketuserid(response.data.data[0].client_code);
          setSocketuserSession(response.data.data[0].access_token);
          createSession(
            response.data.data[0].client_code,
            response.data.data[0].access_token,
            returntokenstring,
            holdingloop
          );
        } else {
          //  console.log('Token Not Available');
          var totalreturn_live_totalparseFloat = pls(holdingloop, "");
          $(".showdataTotalReturn").html(
            totalreturn_live_totalparseFloat.toFixed(2)
          );
          if (totalreturn_live_totalparseFloat > 0) {
            $(".showdataTotalReturn").css({ color: "green" });
          } else if (totalreturn_live_totalparseFloat < 0) {
            $(".showdataTotalReturn").css({ color: "red" });
          } else if (totalreturn_live_totalparseFloat == 0) {
            $(".showdataTotalReturn").css({ color: "black" });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function invalidateSession(userId, userSession, returntokenstring) {
    //alert('0');

    AuthorizationToken = "Bearer " + userId + " " + userSession;
    //console.log('AuthorizationToken',AuthorizationToken);
    let jsonObj = {
      loginType: type,
    };
    $.ajax({
      url: BASEURL + "api/ws/invalidateSocketSess",
      headers: {
        Authorization: AuthorizationToken,
      },
      type: "post",
      data: JSON.stringify(jsonObj),
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (msg) {
        var data = JSON.stringify(msg);
        if (msg.stat == "Ok") {
          createSession(userId, userSession, returntokenstring);
        }
      },
    });
  }

  async function createSession(
    userId,
    userSession,
    returntokenstring,
    holdingloop
  ) {
    //alert('create session')
    var type = { loginType: "API" };
    // console.log("createSession")
    await axios
      .post(`${BASEURL}api/ws/createSocketSess`, type, {
        headers: {
          Authorization: `Bearer ${userId} ${userSession}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log("res",res)
        if (res.data.stat == "Ok") {
          connect(userId, userSession, returntokenstring, holdingloop);
        }
      })
      .catch((error) => {
        // console.log("error",error)
        var totalreturn_live_totalparseFloat = pls(holdingloop, "");
        
        $(".showdataTotalReturn").html(
          totalreturn_live_totalparseFloat.toFixed(2)
        );
        if (totalreturn_live_totalparseFloat > 0) {
          $(".showdataTotalReturn").css({ color: "green" });
        } else if (totalreturn_live_totalparseFloat < 0) {
          $(".showdataTotalReturn").css({ color: "red" });
        } else if (totalreturn_live_totalparseFloat == 0) {
          $(".showdataTotalReturn").css({ color: "black" });
        }
        return "error";
      });

    return;
    AuthorizationToken = "Bearer " + userId + " " + userSession;
    //console.log('AuthorizationToken cratesession',AuthorizationToken);
    let jsonObj = {
      loginType: type,
    };
    $.ajax({
      url: BASEURL + "api/ws/createSocketSess",
      headers: {
        Authorization: AuthorizationToken,
      },
      type: "post",
      data: JSON.stringify(jsonObj),
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (msg) {
        var data = JSON.stringify(msg);
        if (msg.stat == "Ok") {
          connect(userId, userSession, returntokenstring, holdingloop);
        } else {
          alert(msg);
        }
      },
    });
  }

  var totalreturn_exit_skt = 0;
  var totalreturn_live_skt = 0;

  const url = "wss://ws1.aliceblueonline.com/NorenWS/";
  let socket;
  function connect(userId, userSession, returntokenstring, holdingloop) {
    // console.log("holdingloop  ",holdingloop)

    const entryAvailable = holdingloop.filter((item) => item.qty > 0);
    //console.log("entryAvailable  ",entryAvailable)

    if (entryAvailable.length > 0) {
      socket = new WebSocket(url);
      socket.onopen = function () {
        connectionRequest(userId, userSession);
        setSockets(socket);
        setSocketStatus(true);
        //  console.log('xyzzzzz',xyz(socket,token))
      };
      socket.onmessage = function (msg) {
        var response = JSON.parse(msg.data);

        //  console.log("response - ", response);

        if (response.s == "OK") {
          var channel = returntokenstring;
          // console.log("channel", channel);
          let json = {
            k: channel,
            t: "t",
          };

          socket.send(JSON.stringify(json));

          socket.onmessage = function (msg) {
            var response = JSON.parse(msg.data);
            //   console.log("response socket ggg", response);
         
            //console.log('getHoldingFund.length',holdingloop.length)

            //  if(holdingloop.length > 0){
            //     holdingloop.forEach(element => {
            //      console.log("okkk",element.token);
            //      if(element.token == response.tk){
            //      $('.showdatapl'+response.tk).html(response.lp*2);
            //      }
            //  });

            // }

            if (response.tk) {
              var filtered = holdingloop.filter(
                (item) => item.token == response.tk && item.qty != 0
              );
              //  console.log('filtered',filtered[0])
              //  console.log('response socket ggg', response)

              if (response.lp !== undefined) {
                $(".showdatalp" + response.tk).html(response.lp);
              }
              if (response.bp1 !== undefined) {
                $(".showdatabp1" + response.tk).html(response.bp1);
                // console.log('response socket response.bp1', response.bp1)
              }
              if (response.sp1 !== undefined) {
                $(".showdatasp1" + response.tk).html(response.sp1);
                //   console.log('response socket spi', response.sp1)
              }

              if (response.bp1 !== undefined && response.sp1 !== undefined) {
                if (filtered.length > 0) {
                  if (filtered[0].type == "buy") {
                    var val = (
                      response.bp1 * filtered[0].lot * filtered[0].qty -
                      filtered[0].price * filtered[0].lot * filtered[0].qty
                    ).toFixed(2);

                    totalreturn_live_skt += val;

                    if (val > 0) {
                      $(".showdatapl" + filtered[0].token).html(val + " ↑ ");
                      $(".showdatapl" + filtered[0].token).css({
                        color: "green",
                      });
                    } else if (val < 0) {
                      $(".showdatapl" + filtered[0].token).html(val + " ↓ ");
                      $(".showdatapl" + filtered[0].token).css({
                        color: "red",
                      });
                    } else if (val == 0) {
                      $(".showdatapl" + filtered[0].token).html(val + " ");
                      $(".showdatapl" + filtered[0].token).css({
                        color: "black",
                      });
                    }

                    var ReturnPcval = (
                      ((parseFloat(
                        response.bp1 * filtered[0].lot * filtered[0].qty
                      ) -
                        parseFloat(
                          filtered[0].price * filtered[0].lot * filtered[0].qty
                        )) /
                        parseFloat(
                          response.bp1 * filtered[0].lot * filtered[0].qty
                        )) *
                      100
                    ).toFixed(2);

                    if (ReturnPcval > 0) {
                      $(".showdataReturnPc" + filtered[0].token).html(
                        "( " + ReturnPcval + "% ) ↑ "
                      );
                      $(".showdataReturnPc" + filtered[0].token).css({
                        color: "green",
                      });
                    } else if (ReturnPcval < 0) {
                      $(".showdataReturnPc" + filtered[0].token).html(
                        "( " + ReturnPcval + "% ) ↓ "
                      );
                      $(".showdataReturnPc" + filtered[0].token).css({
                        color: "red",
                      });
                    } else if (ReturnPcval == 0) {
                      $(".showdataReturnPc" + filtered[0].token).html(
                        "( " + ReturnPcval + "% )  "
                      );
                      $(".showdataReturnPc" + filtered[0].token).css({
                        color: "black",
                      });
                    }

                    var single_trdae_price_show = (
                      response.bp1 *
                      filtered[0].lot *
                      filtered[0].qty
                    ).toFixed(2);

                    var single_trdae_price = (
                      response.bp1 * filtered[0].lot * filtered[0].qty -
                      filtered[0].price * filtered[0].lot * filtered[0].qty
                    ).toFixed(2);

                    if (single_trdae_price > 0) {
                      $(".showdataSingleTradePrice" + filtered[0].token).html(
                        single_trdae_price_show + " ↑ "
                      );
                      $(".showdataSingleTradePrice" + filtered[0].token).css({
                        color: "green",
                      });
                    } else if (single_trdae_price < 0) {
                      $(".showdataSingleTradePrice" + filtered[0].token).html(
                        single_trdae_price_show + " ↓ "
                      );
                      $(".showdataSingleTradePrice" + filtered[0].token).css({
                        color: "red",
                      });
                    } else if (single_trdae_price == 0) {
                      $(".showdataSingleTradePrice" + filtered[0].token).html(
                        single_trdae_price_show + " "
                      );
                      $(".showdataSingleTradePrice" + filtered[0].token).css({
                        color: "black",
                      });
                    }
                  } else if (filtered[0].type == "sell") {
                    var val = (
                      filtered[0].price * filtered[0].lot * filtered[0].qty -
                      response.sp1 * filtered[0].lot * filtered[0].qty
                    ).toFixed(2);

                    totalreturn_live_skt += val;

                    if (val > 0) {
                      $(".showdatapl" + filtered[0].token).html(val + " ↑ ");
                      $(".showdatapl" + filtered[0].token).css({
                        color: "green",
                      });
                    } else if (val < 0) {
                      $(".showdatapl" + filtered[0].token).html(val + " ↓ ");
                      $(".showdatapl" + filtered[0].token).css({
                        color: "red",
                      });
                    } else if (val == 0) {
                      $(".showdatapl" + filtered[0].token).html(val + " ");
                      $(".showdatapl" + filtered[0].token).css({
                        color: "black",
                      });
                    }

                    var ReturnPcval = (
                      ((parseFloat(
                        filtered[0].price * filtered[0].lot * filtered[0].qty
                      ) -
                        parseFloat(
                          response.sp1 * filtered[0].lot * filtered[0].qty
                        )) /
                        parseFloat(
                          response.sp1 * filtered[0].lot * filtered[0].qty
                        )) *
                      100
                    ).toFixed(2);

                    if (ReturnPcval > 0 && ReturnPcval != "Infinity") {
                      $(".showdataReturnPc" + filtered[0].token).html(
                        "( " + ReturnPcval + "% ) ↑ "
                      );
                      $(".showdataReturnPc" + filtered[0].token).css({
                        color: "green",
                      });
                    } else if (ReturnPcval < 0 && ReturnPcval != "Infinity") {
                      $(".showdataReturnPc" + filtered[0].token).html(
                        "( " + ReturnPcval + "% ) ↓ "
                      );
                      $(".showdataReturnPc" + filtered[0].token).css({
                        color: "red",
                      });
                    } else if (ReturnPcval == 0 && ReturnPcval != "Infinity") {
                      $(".showdataReturnPc" + filtered[0].token).html(
                        "( " + ReturnPcval + "% )  "
                      );
                      $(".showdataReturnPc" + filtered[0].token).css({
                        color: "black",
                      });
                    }

                    var single_trdae_price_show = (
                      response.sp1 *
                      filtered[0].lot *
                      filtered[0].qty
                    ).toFixed(2);

                    var single_trdae_price = (
                      filtered[0].price * filtered[0].lot * filtered[0].qty -
                      response.sp1 * filtered[0].lot * filtered[0].qty
                    ).toFixed(2);

                    if (single_trdae_price > 0) {
                      $(".showdataSingleTradePrice" + filtered[0].token).html(
                        single_trdae_price_show + " ↑ "
                      );
                      $(".showdataSingleTradePrice" + filtered[0].token).css({
                        color: "green",
                      });
                    } else if (single_trdae_price < 0) {
                      $(".showdataSingleTradePrice" + filtered[0].token).html(
                        single_trdae_price_show + " ↓ "
                      );
                      $(".showdataSingleTradePrice" + filtered[0].token).css({
                        color: "red",
                      });
                    } else if (single_trdae_price == 0) {
                      $(".showdataSingleTradePrice" + filtered[0].token).html(
                        single_trdae_price_show + " "
                      );
                      $(".showdataSingleTradePrice" + filtered[0].token).css({
                        color: "black",
                      });
                    }
                  }

                  // var totalreturn_live_totalparseFloat = (totalreturn_live_skt)+parseFloat(totalreturn_exit)

                  var totalreturn_live_totalparseFloat = pls(
                    holdingloop,
                    response
                  );

                  $(".showdataTotalReturn").html(
                    totalreturn_live_totalparseFloat.toFixed(2)
                  );

                  if (totalreturn_live_totalparseFloat > 0) {
                    $(".showdataTotalReturn").css({ color: "green" });
                  } else if (totalreturn_live_totalparseFloat < 0) {
                    $(".showdataTotalReturn").css({ color: "red" });
                  } else if (totalreturn_live_totalparseFloat == 0) {
                    $(".showdataTotalReturn").css({ color: "black" });
                  }
                }
              }
            }
          };

          socket.onclose = function (event) {
            if (event.wasClean) {
              // alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
              connect(userId, userSession, returntokenstring, holdingloop);
              setSocketStatus(false);
            } else {
              // e.g. server process killed or network down
              // event.code is usually 1006 in this case
              //  alert('[close] Connection died');
              connect(userId, userSession, returntokenstring, holdingloop);
              setSocketStatus(false);
            }
          };

          socket.onerror = function (error) {
            connect(userId, userSession, returntokenstring, holdingloop);
            setSocketStatus(false);
            //alert(`[error]`);
          };
        }
      };
    } else {
      var totalreturn_live_totalparseFloat = pls(holdingloop, "");

      $(".showdataTotalReturn").html(
        totalreturn_live_totalparseFloat.toFixed(2)
      );
      if (totalreturn_live_totalparseFloat > 0) {
        $(".showdataTotalReturn").css({ color: "green" });
      } else if (totalreturn_live_totalparseFloat < 0) {
        $(".showdataTotalReturn").css({ color: "red" });
      } else if (totalreturn_live_totalparseFloat == 0) {
        $(".showdataTotalReturn").css({ color: "black" });
      }
    }
  }


  function connectionRequest(userId, userSession) {
    var encrcptToken = CryptoJS.SHA256(
      CryptoJS.SHA256(userSession).toString()
    ).toString();
    // alert(encrcptToken);
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: userId + "_" + type,
      uid: userId + "_" + type,
      source: type,
    };
    // console.log('initCon', initCon);
    socket.send(JSON.stringify(initCon));
  }

  // Socket End


  /// Forex Crypto Socket
  const endpoint = Config.base_url;


  const forexCrypto = (holdingloop) => {

  
      // Establish WebSocket connection when component mounts
      const socket = socketIOClient(endpoint);
  
      // Define event handlers
      socket.on('connect', () => {
        console.log('Connected to WebSocket');
      });
  
      socket.on('receive_data_forex', (message) => {
        // console.log('Received message:', message);

        if(message.type != undefined){
        let live_price = message.type == 'crypto' ? message.data[6].toFixed(3) : message.data[5].toFixed(6);
        let bid_bp1_price = message.type == 'crypto' ? message.data[5].toFixed(3) : message.data[4].toFixed(6);
        let ask_sp1_price = message.type == 'crypto' ? message.data[8].toFixed(3) : message.data[7].toFixed(6);
        
        // console.log('token:', message.data[1]);
        // console.log('live_price:', live_price);
        // console.log('bid_bp1_price:', bid_bp1_price);
        // console.log('ask_sp1_price:', ask_sp1_price);


      
          var filtered = holdingloop.filter(
            (item) => item.token == message.data[1] && item.qty != 0
          );
          //  console.log('filtered',filtered[0])
           
          //  console.log('response socket ggg', response)
            
          if (filtered[0] !== undefined) {
         
            $(".showdatalp" + message.data[1]).html(live_price);
          
      
            $(".showdatabp1" + message.data[1]).html(bid_bp1_price);
         
         
            $(".showdatasp1" + message.data[1]).html(ask_sp1_price);
           

       
            if (filtered.length > 0) {
              if (filtered[0].type == "buy") {
                var val = (
                  bid_bp1_price * filtered[0].lot * filtered[0].qty -
                  filtered[0].price * filtered[0].lot * filtered[0].qty
                ).toFixed(2);

                totalreturn_live_skt += val;

                if (val > 0) {
                  $(".showdatapl" + filtered[0].token).html(val + " ↑ ");
                  $(".showdatapl" + filtered[0].token).css({
                    color: "green",
                  });
                } else if (val < 0) {
                  $(".showdatapl" + filtered[0].token).html(val + " ↓ ");
                  $(".showdatapl" + filtered[0].token).css({
                    color: "red",
                  });
                } else if (val == 0) {
                  $(".showdatapl" + filtered[0].token).html(val + " ");
                  $(".showdatapl" + filtered[0].token).css({
                    color: "black",
                  });
                }

                var ReturnPcval = (
                  ((parseFloat(
                    bid_bp1_price * filtered[0].lot * filtered[0].qty
                  ) -
                    parseFloat(
                      filtered[0].price * filtered[0].lot * filtered[0].qty
                    )) /
                    parseFloat(
                      bid_bp1_price * filtered[0].lot * filtered[0].qty
                    )) *
                  100
                ).toFixed(2);

                if (ReturnPcval > 0) {
                  $(".showdataReturnPc" + filtered[0].token).html(
                    "( " + ReturnPcval + "% ) ↑ "
                  );
                  $(".showdataReturnPc" + filtered[0].token).css({
                    color: "green",
                  });
                } else if (ReturnPcval < 0) {
                  $(".showdataReturnPc" + filtered[0].token).html(
                    "( " + ReturnPcval + "% ) ↓ "
                  );
                  $(".showdataReturnPc" + filtered[0].token).css({
                    color: "red",
                  });
                } else if (ReturnPcval == 0) {
                  $(".showdataReturnPc" + filtered[0].token).html(
                    "( " + ReturnPcval + "% )  "
                  );
                  $(".showdataReturnPc" + filtered[0].token).css({
                    color: "black",
                  });
                }

                var single_trdae_price_show = (
                  bid_bp1_price *
                  filtered[0].lot *
                  filtered[0].qty
                ).toFixed(2);

                var single_trdae_price = (
                  bid_bp1_price * filtered[0].lot * filtered[0].qty -
                  filtered[0].price * filtered[0].lot * filtered[0].qty
                ).toFixed(2);

                if (single_trdae_price > 0) {
                  $(".showdataSingleTradePrice" + filtered[0].token).html(
                    single_trdae_price_show + " ↑ "
                  );
                  $(".showdataSingleTradePrice" + filtered[0].token).css({
                    color: "green",
                  });
                } else if (single_trdae_price < 0) {
                  $(".showdataSingleTradePrice" + filtered[0].token).html(
                    single_trdae_price_show + " ↓ "
                  );
                  $(".showdataSingleTradePrice" + filtered[0].token).css({
                    color: "red",
                  });
                } else if (single_trdae_price == 0) {
                  $(".showdataSingleTradePrice" + filtered[0].token).html(
                    single_trdae_price_show + " "
                  );
                  $(".showdataSingleTradePrice" + filtered[0].token).css({
                    color: "black",
                  });
                }
              } else if (filtered[0].type == "sell") {
                var val = (
                  filtered[0].price * filtered[0].lot * filtered[0].qty -
                  ask_sp1_price * filtered[0].lot * filtered[0].qty
                ).toFixed(2);

                totalreturn_live_skt += val;

                if (val > 0) {
                  $(".showdatapl" + filtered[0].token).html(val + " ↑ ");
                  $(".showdatapl" + filtered[0].token).css({
                    color: "green",
                  });
                } else if (val < 0) {
                  $(".showdatapl" + filtered[0].token).html(val + " ↓ ");
                  $(".showdatapl" + filtered[0].token).css({
                    color: "red",
                  });
                } else if (val == 0) {
                  $(".showdatapl" + filtered[0].token).html(val + " ");
                  $(".showdatapl" + filtered[0].token).css({
                    color: "black",
                  });
                }

                var ReturnPcval = (
                  ((parseFloat(
                    filtered[0].price * filtered[0].lot * filtered[0].qty
                  ) -
                    parseFloat(
                      ask_sp1_price * filtered[0].lot * filtered[0].qty
                    )) /
                    parseFloat(
                      ask_sp1_price * filtered[0].lot * filtered[0].qty
                    )) *
                  100
                ).toFixed(2);

                if (ReturnPcval > 0 && ReturnPcval != "Infinity") {
                  $(".showdataReturnPc" + filtered[0].token).html(
                    "( " + ReturnPcval + "% ) ↑ "
                  );
                  $(".showdataReturnPc" + filtered[0].token).css({
                    color: "green",
                  });
                } else if (ReturnPcval < 0 && ReturnPcval != "Infinity") {
                  $(".showdataReturnPc" + filtered[0].token).html(
                    "( " + ReturnPcval + "% ) ↓ "
                  );
                  $(".showdataReturnPc" + filtered[0].token).css({
                    color: "red",
                  });
                } else if (ReturnPcval == 0 && ReturnPcval != "Infinity") {
                  $(".showdataReturnPc" + filtered[0].token).html(
                    "( " + ReturnPcval + "% )  "
                  );
                  $(".showdataReturnPc" + filtered[0].token).css({
                    color: "black",
                  });
                }

                var single_trdae_price_show = (
                  ask_sp1_price *
                  filtered[0].lot *
                  filtered[0].qty
                ).toFixed(2);

                var single_trdae_price = (
                  filtered[0].price * filtered[0].lot * filtered[0].qty -
                  ask_sp1_price * filtered[0].lot * filtered[0].qty
                ).toFixed(2);

                if (single_trdae_price > 0) {
                  $(".showdataSingleTradePrice" + filtered[0].token).html(
                    single_trdae_price_show + " ↑ "
                  );
                  $(".showdataSingleTradePrice" + filtered[0].token).css({
                    color: "green",
                  });
                } else if (single_trdae_price < 0) {
                  $(".showdataSingleTradePrice" + filtered[0].token).html(
                    single_trdae_price_show + " ↓ "
                  );
                  $(".showdataSingleTradePrice" + filtered[0].token).css({
                    color: "red",
                  });
                } else if (single_trdae_price == 0) {
                  $(".showdataSingleTradePrice" + filtered[0].token).html(
                    single_trdae_price_show + " "
                  );
                  $(".showdataSingleTradePrice" + filtered[0].token).css({
                    color: "black",
                  });
                }
              }

              // var totalreturn_live_totalparseFloat = (totalreturn_live_skt)+parseFloat(totalreturn_exit)

              var totalreturn_live_totalparseFloat = pls(
                holdingloop,
                "response"
              );

              $(".showdataTotalReturn").html(
                totalreturn_live_totalparseFloat.toFixed(2)
              );

              if (totalreturn_live_totalparseFloat > 0) {
                $(".showdataTotalReturn").css({ color: "green" });
              } else if (totalreturn_live_totalparseFloat < 0) {
                $(".showdataTotalReturn").css({ color: "red" });
              } else if (totalreturn_live_totalparseFloat == 0) {
                $(".showdataTotalReturn").css({ color: "black" });
              }
            }
        
       

       
          }
        }

        
        

      });
  
      socket.on('disconnect', () => {
        console.log('Disconnected from WebSocket');
      });
  
      // Clean up WebSocket connection when component unmounts
      return () => {
        socket.disconnect();
      };
    
  
  }
  //// Forex Crypto Socket ENd
  function pls(getHoldingFund, response) {
    //return loop.length

    var currentAmountValue = 0;
    var totalreturn_exit = 0;
    var totalreturn_live = 0;
    var totalreturn = 0;

    getHoldingFund &&
      getHoldingFund.map((item) => {

    //  console.log("ITEM -- ",item)



        const {
          invest_amount,
          qty,
          symbol,
          token,
          lot,
          price,
          type,
          expiry,
          exch_seg,
          exit_price,
          entry_exit_qty,
        } = item;

        totalreturn_exit +=
          qty == 0
            ? type == "buy"
              ? parseFloat(exit_price * lot * entry_exit_qty - price * lot * entry_exit_qty)
              : parseFloat(price * lot * entry_exit_qty - exit_price * lot * entry_exit_qty)
            : 0 ;

          // console.log("totalreturn_exit ",totalreturn_exit , 'token - ',token)

        // rr = 1 ? Object.entries(arrayobject).map(([k, v]) => (
        // k == token ?type=="buy"? totalreturn_live +=  parseFloat((v * lot * qty - price * lot * qty)): totalreturn_live += parseFloat((price * lot * qty - v * lot * qty)): ""
        // )) : "";

        //  var live_price = $('.showdatalp'+token).html();

        var live_price;
        if (type == "buy") {
          live_price = $(".showdatabp1" + token).html();
        } else {
          live_price = $(".showdatasp1" + token).html();
        }

      //  console.log("live_price one to one",live_price , 'token - ',token)

        // alert(live_price)

        if (live_price == undefined || live_price == "") {
          totalreturn_live += 0;
        } else {
          //   alert("oo")
          type == "buy"
            ? (totalreturn_live += parseFloat(
                live_price * lot * qty - price * lot * qty
              ))
            : (totalreturn_live += parseFloat(
                price * lot * qty - live_price * lot * qty
              ));
        }

       // console.log("totalreturn_live ",totalreturn_live , 'token - ',token)


      });
   
      // console.log("totalreturn_live outeside", totalreturn_live)
      // console.log("totalreturn_exit outeside",totalreturn_exit)
    var totalreturn_live_totalparseFloat =
      totalreturn_live + parseFloat(totalreturn_exit);


    return totalreturn_live_totalparseFloat;
  }

  function colorprice(currentAmount, investAmount, type = "") {
    if (type == "buy") {
      if (currentAmount > investAmount) {
        return "green";
      } else if (currentAmount < investAmount) {
        return "red";
      } else {
        return "black";
      }
    } else if (type == "sell") {
      if (currentAmount < investAmount) {
        return "green";
      } else if (currentAmount > investAmount) {
        return "red";
      } else {
        return "black";
      }
    } else {
      if (currentAmount > investAmount) {
        return "green";
      } else if (currentAmount < investAmount) {
        return "red";
      } else {
        return "black";
      }
    }
  }

  function colortotalreturn(val) {
    if (val > 0) {
      return "green";
    } else if (val < 0) {
      return "red";
    } else if (val == 0) {
      return "black";
    }
  }

  function UpDownAero(currentAmount, investAmount, type = "") {
    var total = 0;
    if (type == "buy") {
      total = parseFloat(currentAmount) - parseFloat(investAmount);
    } else if (type == "sell") {
      total = parseFloat(investAmount) - parseFloat(currentAmount);
    } else {
      total = parseFloat(currentAmount) - parseFloat(investAmount);
    }

    if (total > 0) {
      return " ↑ ";
    } else if (total < 0) {
      return " ↓ ";
    } else if (total == 0) {
      return "";
    }
  }

  function totalReturnPercentage(currentAmount, investAmount, type = "") {
    if (currentAmount && currentAmount) {
      var val = 0;

      if (type == "buy") {
        val = (
          ((parseFloat(currentAmount) - parseFloat(investAmount)) /
            parseFloat(currentAmount)) *
          100
        ).toFixed(2);
      } else if (type == "sell") {
        val = (
          ((parseFloat(investAmount) - parseFloat(currentAmount)) /
            parseFloat(currentAmount)) *
          100
        ).toFixed(2);
      } else {
        val = (
          ((parseFloat(currentAmount) - parseFloat(investAmount)) /
            parseFloat(currentAmount)) *
          100
        ).toFixed(2);
      }
      // var val = (((parseFloat(currentAmount) - parseFloat(investAmount)) / parseFloat(currentAmount)) * 100).toFixed(2)
      return "(" + val + "% )";
    } else {
      return "";
    }
  }

  function singleProfitLossTradeExit(token, type, lot, qty, price, exit_price) {
    if (type == "buy") {
      var val = (exit_price * lot * qty - price * lot * qty).toFixed(2);

      if (val > 0) {
        return val + " ↑ ";
      } else if (val < 0) {
        return val + " ↓ ";
      } else if (val == 0) {
        return val + " ";
      }
    } else {
      var val = (price * lot * qty - exit_price * lot * qty).toFixed(2);

      if (val > 0) {
        return val + " ↑ ";
      } else if (val < 0) {
        return val + " ↓ ";
      } else if (val == 0) {
        return val + "";
      }
    }
  }

  function singleProfitLossTrade(token, type, lot, qty, price) {
    // var live_price = $('.showdatalp'+token).html();

    var live_price;
    if (type == "buy") {
      live_price = $(".showdatabp1" + token).html();
    } else {
      live_price = $(".showdatasp1" + token).html();
    }

    if (live_price && live_price) {
      if (type == "buy") {
        var val = (live_price * lot * qty - price * lot * qty).toFixed(2);

        if (val > 0) {
          return val + " ↑ ";
        } else if (val < 0) {
          return val + " ↓ ";
        } else if (val == 0) {
          return val + " ";
        }
      } else {
        var val = (price * lot * qty - live_price * lot * qty).toFixed(2);

        if (val > 0) {
          return val + " ↑ ";
        } else if (val < 0) {
          return val + " ↓ ";
        } else if (val == 0) {
          return val + "";
        }
      }
    } else {
      return 0;
    }
  }

  function colorpriceNew(token, price, lot, qty, type) {
    // var live_price = $('.showdatalp'+token).html();

    var live_price;
    if (type == "buy") {
      live_price = $(".showdatabp1" + token).html();
    } else {
      live_price = $(".showdatasp1" + token).html();
    }

    var currentAmount = live_price * lot * qty;
    var investAmount = price * lot * qty;

    if (live_price && live_price) {
      if (type == "buy") {
        if (currentAmount > investAmount) {
          return "green";
        } else if (currentAmount < investAmount) {
          return "red";
        } else {
          return "black";
        }
      } else if (type == "sell") {
        if (currentAmount < investAmount) {
          return "green";
        } else if (currentAmount > investAmount) {
          return "red";
        } else {
          return "black";
        }
      } else {
        if (currentAmount > investAmount) {
          return "green";
        } else if (currentAmount < investAmount) {
          return "red";
        } else {
          return "black";
        }
      }
    } else {
      return "black";
    }
  }

  function totalReturnPercentageNew(token, type, lot, qty, price) {
    //var live_price = $('.showdatalp'+token).html();

    var live_price;
    if (type == "buy") {
      live_price = $(".showdatabp1" + token).html();
    } else {
      live_price = $(".showdatasp1" + token).html();
    }

    var currentAmount = live_price * lot * qty;
    var investAmount = price * lot * qty;

    if (currentAmount && currentAmount) {
      if (type == "buy") {
        var val = (
          ((parseFloat(currentAmount) - parseFloat(investAmount)) /
            parseFloat(currentAmount)) *
          100
        ).toFixed(2);

        if (val > 0) {
          return "( " + val + "% ) ↑ ";
        } else if (val < 0) {
          return "( " + val + "% ) ↓ ";
        } else if (val == 0) {
          return "( " + val + "% )  ";
        }
      } else if (type == "sell") {
        var val = (
          ((parseFloat(investAmount) - parseFloat(currentAmount)) /
            parseFloat(currentAmount)) *
          100
        ).toFixed(2);
        if (val > 0) {
          return "( " + val + "% ) ↑ ";
        } else if (val < 0) {
          return "( " + val + "% ) ↓ ";
        } else if (val == 0) {
          return "( " + val + "% )  ";
        }
      } else {
        var val = (
          ((parseFloat(currentAmount) - parseFloat(investAmount)) /
            parseFloat(currentAmount)) *
          100
        ).toFixed(2);
        if (val > 0) {
          return "( " + val + "% ) ↑ ";
        } else if (val < 0) {
          return "( " + val + "% ) ↓ ";
        } else if (val == 0) {
          return "( " + val + "% )  ";
        }
      }
    } else {
      return "";
    }
  }

  function singleTradeLivePrice(token, type, lot, qty, price) {
    var live_p;
    // console.log("okkk", token);
    // var live_p = $('.showdatalp'+token).html();
    if (type == "buy") {
      live_p = $(".showdatabp1" + token).html();
    } else {
      live_p = $(".showdatasp1" + token).html();
    }

    var live_price = (live_p * lot * qty).toFixed(2);

    if (live_price != "0.00") {
      if (type == "buy") {
        var val = (live_p * lot * qty - price * lot * qty).toFixed(2);

        if (val > 0) {
          return live_price + " ↑ ";
        } else if (val < 0) {
          return live_price + " ↓ ";
        } else if (val == 0) {
          return live_price + " ";
        }
      } else {
        var val = (price * lot * qty - live_p * lot * qty).toFixed(2);

        if (val > 0) {
          return live_price + " ↑ ";
        } else if (val < 0) {
          return live_price + " ↓ ";
        } else if (val == 0) {
          return live_price + "";
        }
      }
    } else if (live_price == NaN) {
      return 0.0;
    } else if (live_price == "0.00") {
      return 0.0;
    }
  }

  return (
    // <div>  <div className="tab-pane fade" id="videos" role="tabpanel">
    <div className="section full ">
      <div className="holding-heading d-flex">
        {/* <div className="section-title"> */}
        <h3 className="m-0 flex-grow-1 ">
          Positions({holdingCount && holdingCount})
        </h3>
        {/* <button className='flex-grow-1'>
                        Verify Holdings
                    </button> */}
      </div>

      <div className=" section inset text-center  mt-2">
        <div className="wide-block pt-2 pb-2">
          {/* <div className="content-header mb-05 pb-2">Market Today</div> */}
          <div className="row ">
            <div className="col-6">
              <div className="d-flex justify-content-center align-items-center">
                <span className="invested portfolio-icon">
                  <i className="fa-light fa-chart-line-up"></i>
                </span>
                <div className="pl-1 pl-md-2">
                  <h4 className="mb-0 text-left">Invested</h4>
                  <p className="text-success mb-0 total-value">
                    {getInvestedAmmount && getInvestedAmmount ? (
                      <span className="text-black-50 text-bold">₹</span>
                    ) : (
                      ""
                    )}
                    {getInvestedAmmount && getInvestedAmmount
                      ? parseFloat(getInvestedAmmount).toFixed(2)
                      : " - "}
                  </p>{" "}
                </div>
              </div>
            </div>
            {/* <div className='col-4'>
                                <h4 className='mb-0'>  Current</h4>

                                <p className='mb-0 d-flex justify-content-center' style={{ color: colorprice(currentAmountValue, getInvestedAmmount && getInvestedAmmount) }} > {getInvestedAmmount && getInvestedAmmount ? <span className='text-black-50'>₹</span> :""} {currentAmountValue && currentAmountValue ? parseFloat(currentAmountValue).toFixed(2) : " - "}
                                    <span>{<div style={{ color: colorprice(currentAmountValue, getInvestedAmmount && getInvestedAmmount) }} >{UpDownAero(currentAmountValue, getInvestedAmmount && getInvestedAmmount)}</div>}</span>
                                </p>
                            </div> */}
            <div className="col-6">
              <div className="d-flex justify-content-center align-items-center">
                <span className="return portfolio-icon">
                  <i className="fa-solid fa-money-bill-trend-up"></i>
                </span>
                <div className="pl-1 pl-md-2">
                  <h4 className="mb-0 text-left">Total Returns</h4>
                  <p className={`showdataTotalReturn mb-0 total-value`}>-</p>
                  
                </div>
              </div>
            </div>
          </div>
          {/* <hr/> */}
        </div>
      </div>

      <div className="wide-block pt-2 pb-2">
        <ul className="listview image-listview">
          {getHoldingFund && getHoldingFund.length === 0 ? (
            <div className="no-data">
              <p className="text-center">No Data Found!</p>
              <img src="/images/nodata.webp" className="" />
            </div>
          ) : (
            
            getHoldingFund &&
            getHoldingFund.map((item) => {
              const {
                invest_amount,
                qty,
                symbol,
                token,
                lot,
                price,
                type,
                expiry,
                exch_seg,
                exit_price,
                entry_exit_qty,
              } = item;



              var currentprice = $(".showdatalp" + token).html();
              var percentage = totalReturnPercentageNew(
                token,
                type,
                lot,
                qty,
                price
              );


              
              return (
                <li
                  key={token} // Ensure each item has a unique key
                  onClick={() =>
                    handleShow(
                      symbol,
                      token,
                      price,
                      currentprice,
                      percentage,
                      qty,
                      type,
                      expiry,
                      lot,
                      exch_seg
                    )
                  }
                >
                  <a className="item">
                    <div className="in align-items-start">
                      <div className="w-100">
                        <header className="d-flex justify-content-between align-items-end align-items-sm-start">
                          <div className="w-50">
                            <span className="chip chip-outline chip-primary  px-2 py-1 text-left mr-2  mb-05">
                              {type}
                            </span>
                            <span
                              className="symbol-title"
                              style={{ fontSize: "18px" }}
                            >
                              {symbol}
                            </span>
                          </div>
                          <div>
                            <span
                              className="chip-label text-success d-flex"
                              style={{ fontSize: "18px" }}
                            >
                              <span
                                className="text-black-50 "
                                style={{ paddingRight: "2px" }}
                              >
                                ₹
                              </span>{" "}
                              {qty === 0 ? (
                                <>
                                  <div
                                    style={{
                                      color: colorprice(
                                        exit_price * lot * entry_exit_qty,
                                        price * lot * entry_exit_qty,
                                        type
                                      ),
                                    }}
                                  >
                                    {type === "buy"
                                      ? (
                                          exit_price * lot * entry_exit_qty -
                                          price * lot * entry_exit_qty
                                        ).toFixed(2)
                                      : (
                                          price * lot * entry_exit_qty -
                                          exit_price * lot * entry_exit_qty
                                        ).toFixed(2)}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={`showdatapl${token}`}></div>
                                </>
                              )}
                              <span
                                className="chip-label text-success d-flex"
                                style={{ fontSize: "14px" }}
                              >
                                {" "}
                                {qty === 0 ? (
                                  <>
                                    <div
                                      style={{
                                        color: colorprice(
                                          exit_price * lot * entry_exit_qty,
                                          price * lot * entry_exit_qty,
                                          type
                                        ),
                                      }}
                                    >
                                      {exit_price * lot * entry_exit_qty ===
                                      price * lot * entry_exit_qty
                                        ? ""
                                        : type === "buy"
                                        ? exit_price * lot * entry_exit_qty >
                                          price * lot * entry_exit_qty
                                          ? " ↑ "
                                          : " ↓ "
                                        : exit_price * lot * entry_exit_qty <
                                          price * lot * entry_exit_qty
                                        ? " ↑ "
                                        : " ↓ "}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            </span>
                          </div>
                        </header>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="grid-div-position">
                            <span
                              className="mb-1 ms-5"
                              style={{ fontSize: "14px" }}
                            >
                              Lot - {qty}
                            </span>
                            <span
                              className="mb-1 ms-5"
                              style={{ fontSize: "14px" }}
                            >
                              Qty - {qty * lot}
                            </span>
                            {/* <span
                        className=" mb-1"
                        style={{ fontSize: "14px" }}
                      >
                        Lot Size- {lot}  
                      </span> */}
                          </div>

                          <div
                            className="d-flex flex-column position-price text-right"
                            style={{ position: "relative" }}
                          >
                            <span
                              className=" mb-1"
                              style={{ fontSize: "13px" }}
                            >
                              {price} Avg.
                            </span>
                            {qty === 0 ? (
                              <>
                                <span
                                  className="mb-1 "
                                  style={{ fontSize: "13px" }}
                                >
                                  {exit_price} Exit
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className=" mb-1"
                                  style={{ fontSize: "13px" }}
                                >
                                  {" "}
                                  Crt.
                                  {type === "buy" ? (
                                    <>
                                      <b className={`showdatabp1${token}`}></b>
                                    </>
                                  ) : (
                                    <>
                                      <b className={`showdatasp1${token}`}></b>
                                    </>
                                  )}{" "}
                                </span>
                              </>
                            )}
                            {/* <div className="d-flex align-items-center">
                        <span
                          className="chip-label text-success d-flex align-items-center "
                          style={{ fontSize: "12px" }}
                        >
                          <span
                            className="text-black-50 "
                            style={{ paddingRight: "2px" }}
                          >
                            ₹
                          </span>
                          {qty === 0 ? (
                            <>
                              <div
                                style={{
                                  color: colorprice(
                                    exit_price * lot * entry_exit_qty,
                                    price * lot * entry_exit_qty,
                                    type
                                  ),
                                }}
                              >
                                {(exit_price * lot * entry_exit_qty).toFixed(2)}
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div
                                className={`showdataSingleTradePrice${token}`}
                              ></div>
                            </>
                          )}
                          <span
                            className="chip-label text-success d-flex"
                            style={{ fontSize: "12px" }}
                          >
                            {" "}
                            {qty === 0 ? (
                              <>
                                <div
                                  style={{
                                    color: colorprice(
                                      exit_price * lot * entry_exit_qty,
                                      price * lot * entry_exit_qty,
                                      type
                                    ),
                                  }}
                                >
                                  {exit_price * lot * entry_exit_qty ===
                                  price * lot * entry_exit_qty
                                    ? ""
                                    : type === "buy"
                                    ? exit_price * lot * entry_exit_qty >
                                      price * lot * entry_exit_qty
                                      ? " ↑ "
                                      : " ↓ "
                                    : exit_price * lot * entry_exit_qty <
                                      price * lot * entry_exit_qty
                                    ? " ↑ "
                                    : " ↓ "}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </span>
                        <span
                          className="d-flex align-items-center"
                          style={{ fontSize: "14px" }}
                        >
                          {qty === 0 ? (
                            <>
                              <div
                                style={{
                                  color: colorprice(
                                    exit_price * lot * entry_exit_qty,
                                    price * lot * entry_exit_qty,
                                    type
                                  ),
                                }}
                              >
                                {totalReturnPercentage(
                                  (
                                    exit_price *
                                    lot *
                                    entry_exit_qty
                                  ).toFixed(2),
                                  (
                                    price *
                                    lot *
                                    entry_exit_qty
                                  ).toFixed(2),
                                  type
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className={`showdataReturnPc${token}`}></div>
                            </>
                          )}
                          <span
                            className="chip-label text-success d-flex"
                            style={{ fontSize: "14px" }}
                          >
                            {" "}
                            {qty === 0 ? (
                              <>
                                <div
                                  style={{
                                    color: colorprice(
                                      exit_price * lot * entry_exit_qty,
                                      price * lot * entry_exit_qty,
                                      type
                                    ),
                                  }}
                                >
                                  {exit_price * lot * entry_exit_qty ===
                                  price * lot * entry_exit_qty
                                    ? ""
                                    : type === "buy"
                                    ? exit_price * lot * entry_exit_qty >
                                      price * lot * entry_exit_qty
                                      ? " ↑ "
                                      : " ↓ "
                                    : exit_price * lot * entry_exit_qty <
                                      price * lot * entry_exit_qty
                                    ? " ↑ "
                                    : " ↓ "}
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </span>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              );
            })
          )}
        </ul>
      </div>

      {/* </div> */}

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
      >
        <div className="d-flex justify-content-end align-items-center">
          <MdOutlineCancel
            onClick={handleClose}
            className="icon-large"
            style={{ fontSize: "30px", marginRight: "10px", marginTop: "5px" }}
          />
        </div>

        {/* <Modal.Header closeButton>
                    <Modal.Title className="mx-auto">Buy/Sell Stocks</Modal.Title>
                </Modal.Header> */}
        <Modal.Body className="py-2 px-2">
          <div>
            <table className="table">
              <tr>
                <td className="border-0 px-1">
                  {" "}
                  <h4 className="flex-grow-1 pb-0 mb-0">{showModalsymbol} </h4>
                </td>
                <td className="border-0">
                  <span>{showModalbuysell == "buy" ? "Buy" : "Sell"} :</span>
                  <span className="ml-1 border border-primary px-3 py-1 rounded mb-0 font-weight-bold">
                    {showModalqty}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="border-0 px-1">
                  <h4 className="flex-grow-1">
                    MKT &#8377; {showModalcurrentprice}
                    {showModalpercentupdown}
                  </h4>
                </td>
                <td className="border-0">
                  <span>AVG &#8377; {showModalaverageprice}</span>
                </td>
              </tr>
            </table>
            <hr />
            {/* <div className='d-flex'>
                            <h4 className='flex-grow-1'>{showModalsymbol} </h4>
                            <span >{showModalbuysell=="buy"? "Buy" : "Sell"} :</span> 
                            <span className='ml-1 border px-3'>{showModalqty}</span>
                        </div>

                        <div className='d-flex'>
                            <h4 className='flex-grow-1'>MKT &#8377; {showModalcurrentprice}{showModalpercentupdown}</h4>
                            <span >AVG &#8377; {showModalaverageprice}</span>
                        </div> */}
          </div>
          <div className="d-flex ">
            <div className="form-group boxed text-center">
              <Link
                className="btn btn-danger mr-1 mb-1  d-flex w-100"
                // onClick={addClientbtn}
                to={"/buysell"}
                state={{
                  userid: user_id,
                  currentprice: showModalcurrentprice,
                  percentupdown: showModalpercentupdown,
                  symbol: showModalsymbol,
                  type: showModalbuysell,
                  expiry: showModalexpiry,
                  lot: showModallot,
                  token: showModaltoken,
                  segment: showModalsegment,
                  qty: showModalqty,
                  userId: socketuserid,
                  userSession: socketuserSession,
                  type_submit: "sell",
                }}
              >
                SELL<i className="fa-solid fa-hand-pointer ml-1"></i>
              </Link>
            </div>
            <div className="form-group boxed text-center">
              <Link
                className="btn btn-success mr-1 mb-1  d-flex w-100"
                // onClick={addClientbtn}
                to={"/buysell"}
                state={{
                  userid: user_id,
                  currentprice: showModalcurrentprice,
                  percentupdown: showModalpercentupdown,
                  symbol: showModalsymbol,
                  type: showModalbuysell,
                  expiry: showModalexpiry,
                  lot: showModallot,
                  token: showModaltoken,
                  segment: showModalsegment,
                  qty: showModalqty,
                  userId: socketuserid,
                  userSession: socketuserSession,
                  type_submit: "buy",
                }}
              >
                BUY<i className="fa-solid fa-hand-pointer ml-1"></i>
              </Link>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </div>
    // </div>
  );
};

export default Positons;
