import React, { useState, useEffect } from 'react'
import { useNavigate, Link, useParams, useLocation, NavLink } from 'react-router-dom'
import axios from 'axios'
import $ from "jquery";
import CryptoJS from "crypto-js";
import * as Config from '../common/Config';
import { UserAvailabeFunds, getmargiRreqPerUser } from "../../../services";
import { fDate } from '../../../component/frontend/common/formatTime'

import socketIOClient from 'socket.io-client';



const Stock_detail = () => {
  
 


  const naviagte = useNavigate();
  const { id } = useParams();
  const [fund, setfund] = useState('');
  const [sockets, setSockets] = useState(null);
  const [tokenMatch, setTokenMatch] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [stockLot, setStockLot] = useState("");
  const [stockBuyPrice, setStockBuyPrice] = useState("");
  const [stockSellPrice, setStockSellPrice] = useState("");
  const [requiredFund, setRequiredFund] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityeer, setQuantityerr] = useState("");
  const [nfomargin, setNfomargin] = useState("");
  const [mcxmargin, setMcxmargin] = useState("");
  const [cdsmargin, setCdsmargin] = useState("");



  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");


  const location = useLocation();
  const locationData = location.state;

  // console.log("locationData", locationData.stock.exch_seg);

  const endpoint = Config.base_url;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Establish WebSocket connection when component mounts
    const socket = socketIOClient(endpoint);

    // Define event handlers
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('receive_data_forex', (message) => {
    //   console.log('Received message:', message);
      // console.log('Received message:', message[1]);
      // console.log('Received message:', message[5]);
      // console.log('Received message:', message[2]);
      // setMessages((prevMessages) => [...prevMessages, message]);

      if (message.type != undefined && message.type == 'crypto') {
        if (locationData.stock.token == message.data[1]) {
            setTokenMatch(message.data[1]);
            setTokenPrice(message.data[6].toFixed(3));
            setStockBuyPrice(message.data[5].toFixed(3));
            setStockSellPrice(message.data[8].toFixed(3));
            setStockLot(100000);
        }
      }
      else if (message.type != undefined && message.type == 'forex') {
        if (locationData.stock.token == message.data[1]) {
            setTokenMatch(message.data[1]);
            setTokenPrice(message.data[5].toFixed(6));
            setStockBuyPrice(message.data[4].toFixed(6));
            setStockSellPrice(message.data[7].toFixed(6));
            setStockLot(100000);
        }
      }

    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    // Clean up WebSocket connection when component unmounts
    return () => {
      socket.disconnect();
    };
  }, [endpoint]); // Only re-run effect if endpoint changes





  // console.log("locationData sssssss -",locationData)
  // console.log("locationData", locationData.stock);
  // console.log("locationData", locationData.stock.token);
  // console.log("locationData", locationData);
  // console.log("locationData exg segment", locationData.stock.exch_seg);
  // console.log("symbol", locationData.stock.symbol);
  // console.log("userId", locationData.userId);
  // console.log("userSession", locationData.userSession);

  // console.log(" locationData.stock.expiry", locationData.stock.expiry);
  
  const url = "wss://ws1.aliceblueonline.com/NorenWS/";
  let socket;

  // locationData.stock.expiry


  var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
  let AuthorizationToken;
  let type = "API";





  //  ge tUser Avilable Fund


  const getUserAvilableFund = async () => {

    const data = {
      "userid": userid
    }
    const response = await UserAvailabeFunds(data, accesstoken);
      
    if (response) {
      setfund(response.data.availablefund.toFixed(2))
    }

  };



  const getMarginReqPer = async () => {

    const data = {
      "userid": userid
    }
    const response = await getmargiRreqPerUser(data, accesstoken);

    if (response.data.status) {
       
      //  console.log("NFO", response.data);
      // console.log("MCX", response.data.data[0].MCX);
      // console.log("CDS", response.data.data[0].CDS);
      // console.log("NFO", response.data.data[0].NFO);
      setNfomargin(response.data.data[0].NFO);
      setMcxmargin(response.data.data[0].MCX);
      setCdsmargin(response.data.data[0].CDS);
    }

  }


  useEffect(() => {
   
    if (locationData.userId != "") {
      connect(locationData.userId, locationData.userSession, locationData.stock.token, locationData.stock.exch_seg)
    }

    getUserAvilableFund()
    getMarginReqPer();

  }, []);


  useEffect(() => {

  }, [sockets]);








  // function AvailableUserFunds() {
  //   var data = {
  //     userid: userid,
  //   };

  //   var config = {
  //     method: "post",
  //     url: `${Config.base_url}api/useravailabefunds`,
  //     headers: {
  //       "x-access-token": accesstoken,
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //      
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });

  // }





  function connect(userId, userSession, token = "", exch_seg = "") {


    socket = new WebSocket(url);


    socket.onopen = function () {
      connectionRequest(userId, userSession);

      setSockets(socket)


    };
    socket.onmessage = function (msg) {

      var response = JSON.parse(msg.data);


      if (response.s == "OK") {
        //alert(response.bp1)

        // console.log("response socket next url sss", response);


        // var tokensss = 53402
        var channel = exch_seg + "|" + token;
        //console.log("channel", channel);
        let json = {
          k: channel,
          t: "t",
        };

        socket.send(JSON.stringify(json));

        socket.onmessage = function (msg) {
          var response = JSON.parse(msg.data);
          // console.log('response socket ggg sssss', response)



          if (response.tk) {

            setTokenMatch(response.tk);

            if (response.lp) {
              setTokenPrice(response.lp);
            }

            if (response.ls) {
              setStockLot(response.ls);
            }

            if (response.bp1) {
              // console.log('response bp1 price ', response.bp1)
              setStockBuyPrice(response.bp1);
            }

            if (response.sp1) {
              // console.log('response sp1 price ', response.sp1)

              setStockSellPrice(response.sp1);
            }

          }
        };

      }
    };
  }




  function connectionRequest(userId, userSession) {
    var encrcptToken = CryptoJS.SHA256(
      CryptoJS.SHA256(userSession).toString()
    ).toString();
    // alert(encrcptToken);
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: userId + "_" + type,
      uid: userId + "_" + type,
      source: type,
    };
    // console.log('initCon', initCon);
    socket.send(JSON.stringify(initCon));
  }


  const enterLot = (e) => {

    if (e.target.value) {
      setQuantity(e.target.value);
      //alert(e.target.value*stockLot*stockBuyPrice)
      var totalrequiredfundwithoutmargin = e.target.value * stockLot * tokenPrice;

      var marginvalue = "";

      // console.log("marginvalue",locationData.stock.exch_seg)
      // console.log("nfomargin",nfomargin)

      // if(locationData.stock.exch_seg == "NFO"){
      //   marginvalue = nfomargin; 
      // }else if(locationData.stock.exch_seg == "MCX"){
      //   marginvalue = mcxmargin;
      // }else if(locationData.stock.exch_seg == "CDS"){
      //   marginvalue = cdsmargin;
      // }else{
      //   marginvalue = 13;
      // }

      marginvalue = 13;

      // console.log("totalrequiredfundwithoutmargin",totalrequiredfundwithoutmargin)
      // console.log("marginvalue",marginvalue)

      var margintotalrequiredfund = (totalrequiredfundwithoutmargin / 100) * parseInt(marginvalue);
      setRequiredFund(margintotalrequiredfund.toFixed(2));
    } else {
      setRequiredFund("");
    }
  }
  // console.log("Marketlot Value", quantity);


  const backbtn = () => {

    naviagte('/watchlist');
  }

  const Buysell = (type) => {
       
  
    if (quantity == "" || (quantity === "0" || quantity === "00" || quantity === "000" || quantity === "0000" || quantity === "00000" || quantity === "000000" || quantity === "0000000" || quantity === "00000000" || quantity === "000000000" || quantity === "0000000000" || quantity === "00000000000")) {
      setQuantityerr("Please enter Quantity")
      return
    }



    var marginvalue;
    // if(locationData.stock.exch_seg == "NFO"){
    //   marginvalue = nfomargin; 
    // }else if(locationData.stock.exch_seg == "MCX"){
    //   marginvalue = mcxmargin;
    // }else if(locationData.stock.exch_seg == "CDS"){
    //   marginvalue = cdsmargin;
    // }else{
    //   marginvalue = 13;
    // }

    marginvalue = 13;


    if (type == "buy") {
      var data = {
        locationData: locationData,
        stockLot: stockLot,
        Quantity: quantity,
        price: stockSellPrice,
        totalamount: ((((stockSellPrice * stockLot * quantity) * parseInt(marginvalue)) / 100)).toFixed(2),
        marginvalue: marginvalue,
        type: type,
        segment: locationData.stock.exch_seg
      }
    } else if (type == "sell") {
      var data = {
        locationData: locationData,
        stockLot: stockLot,
        Quantity: quantity,
        price: stockBuyPrice,
        totalamount: ((((stockBuyPrice * stockLot * quantity) * parseInt(marginvalue)) / 100)).toFixed(2),
        marginvalue: marginvalue,
        type: type,
        segment: locationData.stock.exch_seg
      }

    }
    //state={{ stock: items, socket: socket, userId: socketuserid, userSession: socketuserSession }}
    // naviagte('/confirm_trade',  {state:{ data : data ,stock: locationData.stock, socket: locationData.socket, userId: locationData.userId, userSession: locationData.userSession}} )
    naviagte('/confirm_trade', { state: data })

  }

  const handleKeyPress = (event) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleKeyPressForexCrypto = (event) => {
    if (!/[\d.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div id="appCapsule">

      <div className="section full mb-2">

        <div className="wide-block p-0 border-0">
          <div className="section-title d-block text-left px-0"><span><button onClick={backbtn}><i class="fa-regular fa-arrow-left"></i></button></span>Buy/Sell Stocks</div>
          <div className="table-responsive">
            <table className="table table-borderless">

              <tbody>
                <tr>

                  <td>Stock Name</td>
                  <td>{locationData.stock.symbol_name}</td>
                </tr>

                <tr>

                  <td>Price</td>
                  <td>{tokenPrice && tokenPrice}</td>
                </tr>
                <tr>

                  <td>Expiry</td>
                  <td>
                    <div>
                      {fDate(locationData.stock.expiry)}
                      {/* <div className="custom-control custom-radio mb-1">
                        <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                        <label className="custom-control-label" htmlFor="customRadio1">Option 1</label>
                      </div> */}


                    </div>


                  </td>
                </tr>
                <tr>

                  <td>Market lot</td>
                  <td>{stockLot && stockLot}</td>
                </tr>
                <tr>

                  <td>Quantity</td>
                  <td>
                     {
                      locationData.stock.exch_seg == "FOREX" ||locationData.stock.exch_seg == "CRYPTO" ?
                      
                        <input type="number" min="0.01" onKeyPress={handleKeyPressForexCrypto} onChange={(e) => { enterLot(e); setQuantityerr('') }} className="form-control" id="name5" placeholder="Enter Lot" />

                        :
                         <input type="number" min="1" onKeyPress={handleKeyPress} onChange={(e) => { enterLot(e); setQuantityerr('') }} className="form-control" id="name5" placeholder="Enter Lot" />
                     }


                    {/* <input type="number" min="0.01" onKeyPress={handleKeyPress} onChange={(e) => { enterLot(e); setQuantityerr('') }} className="form-control" id="name5" placeholder="Enter Lot" /> */}

                    <p className='text-danger text-left mx-0 mt-1'>{quantityeer}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="wide-block pt-2 pb-1 d-flex justify-content-center">
          {/* do not forget to delete mr-1 and mb-1 when copy / paste codes */}



          <button type="button" className="btn btn-danger mr-1 mb-1 col-md-3 col-sm-6  d-block" onClick={() => Buysell("sell")}><p className='mb-0'>SELL</p>
            <p>{stockBuyPrice && stockBuyPrice}</p>
          </button>
          <button type="button" className="btn btn-success mr-1 mb-1 w-100 col-md-3 d-block" onClick={() => Buysell("buy")}><p className='mb-0'>BUY</p>
            <p>{stockSellPrice && stockSellPrice}</p></button>

        </div>
        <div className="wide-block pt-2 pb-1 d-flex justify-content-between ">
          {/* do not forget to delete mr-1 and mb-1 when copy / paste codes */}



          <p className='fs-6 funds-price '>Required Funds: <span><b>₹</b>{requiredFund && requiredFund}</span></p>
          <p className='fs-6 funds-price'>Available Funds:<span> <b>₹</b>{fund}</span></p>
        </div>
      </div>
    </div>
  )
}

export default Stock_detail