import React, { useEffect, useState } from 'react'
import { GetProfileDetails } from "../../../services";


const Profile = () => {

  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const [ProfileData, setProfileData] = useState('');
  // console.log("ProfileData", ProfileData);
  const GetProfileInfo = async () => {
    const data = {
      "userid": userid
    }
    const response = await GetProfileDetails(data, accesstoken)
    // console.log("response", response.data);
    if (response.data.status) {
      setProfileData(response.data.data)
    }
  }

  useEffect(() => {
    GetProfileInfo()
  }, [])



  const { username, mobile, email, fullname, company_name } = ProfileData
  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <strong className="conteudo-title">Admin Profile</strong>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">
                    <div className="student-profile py-4">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="card shadow-sm">
                              <div className="card-header bg-transparent text-center">
                                <img className="profile_img" src="https://cdn-icons-png.flaticon.com/512/1177/1177568.png" alt="" />
                                <h3>{username}</h3>
                              </div>

                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="card shadow-sm">
                              <div className="card-header bg-transparent border-0">
                                <h3 className="mb-0"><i className="far fa-clone pr-1" />General Information</h3>
                              </div>
                              <div className="card-body pt-0">
                                <table className="table table-bordered">
                                  <tbody><tr>
                                    <th width="30%">Name</th>
                                    <td width="2%">:</td>
                                    <td>{fullname}</td>
                                  </tr>
                                    <tr>
                                      <th width="30%">Company Name</th>
                                      <td width="2%">:</td>
                                      <td>{company_name}</td>
                                    </tr>
                                    <tr>
                                      <th width="30%">Email	</th>
                                      <td width="2%">:</td>
                                      <td>{email}</td>
                                    </tr>
                                    <tr>
                                      <th width="30%">Mobile</th>
                                      <td width="2%">:</td>
                                      <td>{mobile}</td>
                                    </tr>

                                  </tbody></table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile