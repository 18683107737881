import axios from "axios";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { GetReports } from "../../../services";
import { fDateTime } from '../../../component/frontend/common/formatTime'
import { getUserRejectAmmount } from "../../../services";



const Rejects = () => {
  const [alRejectAmmount, setAllRejectAmmount] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const GetTradeReports = async () => {

    // console.log("AuthLogin" ,AuthLogin);
    const req = {
      "adminid": UserId,
      'type': 'deposite'
    };

    const response = await getUserRejectAmmount(req, AuthLogin);
    if (response.data.status) {
      setAllRejectAmmount(response.data.data)
      // setRefresh(!refresh)
    }
  };

  // }

  //   var data = JSON.stringify({
  //     "parentid": UserId,
  //   });

  //   var config = {
  //     method: 'get',
  //     url: 'http://180.149.241.128:5008/api/admin/reports', data ,
  //     headers: {
  //       'x-access-token':  AuthLogin,
  //       'Content-Type': 'application/json'
  //     },
  //     // data : data
  //   };

  //   axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //   });

  const colums = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name:' Date And Time',
      selector: row => fDateTime(row.updatedAt),
    },
    {
      name: "User Name",
      cell: row => row.username.username
    },
    {
      name: "Payment",
      selector: (row) => row.deposite_payment,
    },
    {
      name: "Message",
      selector: (row) => row.note == '' ? "no message" : row.note,
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.status == 0) {
          return 'Pending'
        }
        else if (row.status == 1) {
          return 'Completed'
        }
        else if (row.status == 2) {
          return 'Reject'
        }
      },
    },

  ];
  useEffect(() => {
    GetTradeReports();
  }, [refresh]);
  useEffect(() => {
    const table = document.querySelector('.rdt_Table');
  
    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener('dragstart', preventDrag);
      table.addEventListener('dragover', preventDrag);
      table.addEventListener('drop', preventDrag);
  
      return () => {
        table.removeEventListener('dragstart', preventDrag);
        table.removeEventListener('dragover', preventDrag);
        table.removeEventListener('drop', preventDrag);
      };
    }
  }, []);
  
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="conteudo-box">
            <div className="conteudo-boxInner p-0">
              <DataTable
                columns={colums}
                data={alRejectAmmount}
                fixedHeaderScrollHeight="400px"
                fixedHeader
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
    </div>
  );
};

export default Rejects;

