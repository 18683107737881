
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'



const Transaction_license = () => {
  const [student, setStudent] = useState([]);
  const getstudent = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v2/all")
      setStudent(response.data);
    }
    catch (error) { console.log(error) }
  }
  const colums = [
    {
      name: 'S No.',
      selector: (row, index) => index + 1,
    },
    {
      name: 'Name',
      selector: row => row.name,
    },
    {
      name: 'Capital',
      selector: row => row.capital,
    },
    {
      name: 'Region',
      selector: row => row.region,
    },
    {
      name: "Action",
      cell: row => (<div className='action-btn'>

        <Tooltip title="Edit" arrow><button className='btn btn-success  mr-2 rounded-circle'><i className="fa-solid fa-pen-to-square "></i></button></Tooltip>
        <Tooltip title="Delete" arrow><button className='btn btn-danger  rounded-circle'><i className="fa-solid fa-trash "></i></button></Tooltip>
      </div>),
    },
  ]
  useEffect(
    () => {
      getstudent();
    }, []
  )
  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <div className='row'>
                <div className='col-md-8'>
                  <strong className="conteudo-title">Transaction License</strong></div>
                <div className='col-md-4 text-right'>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner p-0">
                    <DataTableExtensions
                      columns={colums}
                      data={student}
                      export={false}
                      print={false}
                    >
                      <DataTable
                        fixedHeader
                        fixedHeaderScrollHeight="400px"
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination


                        highlightOnHover
                        paginationRowsPerPageOptions={[10, 50, 100]}
                        paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }} />
                    </DataTableExtensions>


                  </div>
                </div>
              </div>

            </div>

            {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Transaction_license