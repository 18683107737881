import axios from 'axios';
import React, { useState, useEffect } from 'react'
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component'
import Tooltip from '@mui/material/Tooltip';
import { Link, useNavigate } from 'react-router-dom'
import { GetAllServices } from "../../../services";
import { fDate } from '../../frontend/common/formatTime';


const Services = () => {
  const navigate = useNavigate();
  const [servies, setServices] = useState([]);
  const AuthLogin = localStorage.getItem("user_token");
  const AuthRole = localStorage.getItem("Role")


  const servicedata = async() => {
    // var config = {
    //   method: 'get',
    //   url: 'http://180.149.241.128:5008/api/admin/allservices',
    //   headers: {
    //     'x-access-token': AuthLogin,
    //   }
    // };

    // axios(config)
    //   .then(function (response) {
    //     //console.log(JSON.stringify(response.data));
    //     //console.log("services",response.data);
    //     setServices(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    const response = await GetAllServices( AuthLogin);

    if (response.status === 200) {
      setServices(response.data);
    }
    else {
      console.log("error", response.data);
    }
  }
  const colums = [
    
    {
      name: 'S. No.',
         selector: (row, index) => index + 1,
        //  w
    },
   
    {
      name: 'Symbol',
      selector: row => row.symbol,
    },
    

    
    {
      name: 'Expiry',
      selector: row =>fDate(row.expiry),

    },
    {
      name: 'Segment',
      selector: row => row.exch_seg,

    },
    {
      name: 'Trading Symbol',
      selector: row => row.trading_symbol,
      // width:'210px'
    },


  ]
  useEffect(() => {
    const table = document.querySelector('.rdt_Table');

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener('dragstart', preventDrag);
      table.addEventListener('dragover', preventDrag);
      table.addEventListener('drop', preventDrag);

      return () => {
        table.removeEventListener('dragstart', preventDrag);
        table.removeEventListener('dragover', preventDrag);
        table.removeEventListener('drop', preventDrag);
      };
    }
  }, []);


  useEffect(
    () => {
      servicedata();
    }, []
  )

  const filterInput = document.querySelector('.filter-text');


  
// Check if the input element exists
if (filterInput) {
  // Set the new placeholder value
  filterInput.placeholder = "Search Here...";
}
  if (AuthRole == "ROLE_ADMIN") {
    return (
      <div>
        <div id="conteudo">
          <div className="conteudo-inner">
            <div className="conteudo-wrapper">
              <div className="conteudo-titleSection">
                <strong className="conteudo-title">Services</strong>

              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="conteudo-box">
                    <div className="conteudo-boxInner p-0">
                      <DataTableExtensions
                        columns={colums}
                        data={servies}
                        export={false}
                        print={false}
                      >
                        <DataTable
                          fixedHeader
                          fixedHeaderScrollHeight="400px"
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination


                          highlightOnHover
                          paginationRowsPerPageOptions={[10, 50, 100]}
                          paginationComponentOptions={{ selectAllRowsItem: true, selectAllRowsItemText: 'All' }} />
                      </DataTableExtensions>


                    </div>
                  </div>
                </div>

              </div>

              {/*   <div className="conteudo-box mt-4">
            <div className="conteudo-boxInner">
              
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {

    navigate('/')
  }
}

export default Services