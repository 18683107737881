import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Client from "./routing/Client";
import Admin from "./routing/Admin";
import Login from "./component/frontend/Login";
import SignIn from "./component/frontend/SignIn";
import ErrorPage from "./component/frontend/Errorpage";
import Routings from "./routing/Client";

function App() {
  const location = useLocation();
 
  const navigate = useNavigate();
  const role_id = localStorage.getItem("Role");
  const user_token = localStorage.getItem("user_token");


  useEffect(() => {
    if (location.pathname == "signin") {
      navigate("/signin");
    }

    if (
      user_token != "" &&
      role_id == "ROLE_ADMIN" &&
      location.pathname == "/"
    ) {
      navigate("/admin/dashboard");
    } else if (
      user_token != "" &&
      role_id == "ROLE_USER" &&
      location.pathname == "/"
    ) {
      navigate("/watchlist");
    }
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route
          path="/*"
          element={
            user_token != "" && role_id == "ROLE_USER" ? <Client /> : <Login />
          }
        />

        <Route
          path="/admin/*"
          element={
            user_token != "" && role_id == "ROLE_ADMIN" ? <Admin /> : <Login />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
