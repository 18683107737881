import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Config from "../../../utils/Config";
import AlertToast from "../../../utils/AlertToast";
import $ from "jquery";

import { GetEditData } from "../../../services";
import { getpassword } from "../../../services/login.service";


const Edit_client = () => {
  const navigate = useNavigate();


  const [limit, setLimit] = useState("0");
  const [licence, setLicence] = useState("0");
  const [recharge, setRecharge] = useState("0");
  const [userdata, setUserdata] = useState("");
  const [availableamount, setAvailableamount] = useState("");
  const [totallicence, setTotallicence] = useState("");
  const [Currentlimit, setCurrentlimit] = useState("");

  // input Buttons
  const [tradeInputvalue, settradeInputvalue] = useState("");
  const [LotInputvalue, setLotInputvalue] = useState("");

  const [persentageInputvalue, setPersentageInputvalue] = useState("");
  // const [MinBrokrageInputvalue, setMinBrokrageInputvalue] = useState("")

  // Radio Buttons
  const [tradeRadio, settradeRadio] = useState(false);
  const [LotRadio, setLotRadio] = useState(false);
  const [persentageRadio, setPersentageRadio] = useState(false);
  const [MinBrokrageRadio, setMinBrokrageRadio] = useState(false);
  const [ButtonDisabled, setButtonDisabled] = useState(false);

  // // Radio Buttons Values
  // const [MinBrokrageRadio1, setMinBrokrageRadio1] = useState('')
  // const [MinBrokrageRadio2, setMinBrokrageRadio2] = useState('')
  // const [MinBrokrageRadio3, setMinBrokrageRadio3] = useState('')

  // // Radio Buttons Erros

  // const [usenameErr, setUsenameErr] = useState("")
  // const [fullnameErr, setFullnameErr] = useState("")
  // const [passwordErr, setPasswordErr] = useState("")
  // const [confirmPasswordErr, setConfirmPasswordErr] = useState("")
  // const [licenceToMonthErr, setLicenceToMonthErr] = useState("")
  // const [clientCreatedByErr, setClientCreatedByErr] = useState("")
  // const [rechargeErr, setRechargeErr] = useState("")
  // const [limitErr, setLimitErr] = useState("")

  // Radio Buttons Errors
  const [tradeRadioErr, settradeRadioErr] = useState();
  const [LotRadioErr, setLotRadioErr] = useState("");
  const [persentageRadioErr, setPersentageRadioErr] = useState("");
  const [MinBrokrageRadioErr, setMinBrokrageRadioErr] = useState("");
  const [rechargeErr, setRechargeErr] = useState("");
  const [abc, setabc] = useState("");
  const [abcd, setabcd] = useState("");
  // const [abcde, setabcde] = useState("")
  // const [abcdef, setabcdef] = useState("")

  // console.log("abc" ,abc);


  const [setid, Setid] = useState("")
  const [password, setPassword] = useState("")

  const AuthLogin = localStorage.getItem("user_token");





  const [alertMsg, setAlertMsg] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [textAlert, setTextAlert] = useState("");

  const hideClose = () => {
    setAlertMsg(false);
  };

  const userId = useParams();
  var user_token = localStorage.getItem("user_token");
  var UserId = localStorage.getItem("UserId");






  //getting user password

  const showpassword = async () => {
    const data = {
      id: setid
    };
    try {
      const response = await getpassword(data, AuthLogin);
      if (response.status) {
        setPassword(response.data.show_password)

      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };




  const getUserData = () => {
    var config = {
      method: "get",
      url: `${Config.baseUrl}api/admin/getRow/${userId.id}`,
      headers: {
        "x-access-token": user_token,
      },
    };
    axios(config)
      .then(function (response) {
        setUserdata(response.data.data);
        Setid(response.data.data.id)
        setAvailableamount(response.data.availableAmount);
        setTotallicence(response.data.totallicence);
        setCurrentlimit(response.data.currentlimit);
        setabc(response.data.data.brokerage);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // console.log("recharge", recharge);

  const editclient = async (e) => {
    e.preventDefault();

    // Check and set default values if necessary
    if (limit === "null") {
      setLimit("0");
      return;
    }
    if (recharge === "null") {
      setRecharge("0");
      return;
    }

    // Validate recharge amount
    if (!recharge || !/^\d+$/.test(recharge)) {
      setRechargeErr("Enter a valid Recharge Amount");
      return;
    }

    if (licence === "null") {
      setLicence("0");
      return;
    }

    // Check trading-related inputs
    if (tradeRadio && !tradeInputvalue) {
      settradeRadioErr("Please enter Per Trade Amount");
      return;
    }
    if (LotRadio && !LotInputvalue) {
      setLotRadioErr("Please enter Per Lot Amount");
      return;
    }
    if (persentageRadio && !persentageInputvalue) {
      setPersentageRadioErr("Please enter Percentage value");
      return;
    }

    if (MinBrokrageRadio) {
      if (!tradeInputvalue) {
        settradeRadioErr("Please enter Per Trade Amount");
        return;
      }
      if (!LotInputvalue) {
        setLotRadioErr("Please enter Per Lot Amount");
        return;
      }
      if (!persentageInputvalue) {
        setPersentageRadioErr("Please enter Percentage value");
        return;
      }
    }

    // Disable the submit button
    setButtonDisabled(true);

    // Construct data object
    const data = {
      parent_id: UserId,
      userid: userId.id,
      username: userdata.username,
      fullname: userdata.fullname,
      licence: licence,
      recharge: recharge,
      multiplelimit: limit,
      button_value: abc,
      per_trade: tradeInputvalue,
      per_lot: LotInputvalue,
      turn_over_percentage: persentageInputvalue,
    };

    // Send request
    try {
      const response = await GetEditData(data, user_token);
      if (response.data.status) {
        // Enable the submit button
        setButtonDisabled(false);
        // Show success alert
        setAlertMsg(true);
        setAlertColor("success");
        setTextAlert(response.data.message);
        // Navigate after delay
        setTimeout(() => navigate("/admin/client"), 1000);
      }
    } catch (error) {
      // Handle errors
      // console.error("Error editing client:", error);
      // Enable the submit button
      setButtonDisabled(false);
      // Show error alert
      setAlertMsg(true);
      setAlertColor("danger");
      setTextAlert("An error occurred while editing client.");
    }
  };

  useEffect(() => {
    getUserData();
    showpassword()
    // else  {
    //   setMinBrokrageRadio(true)
    // }
  }, [setid]);

  // console.log("HELLO " , abc);

  useEffect(() => {
    settradeInputvalue(userdata.pertrade);
    setLotInputvalue(userdata.perlot);
    setPersentageInputvalue(userdata.turn_over_percentage);

    if (abc == 1) {
      settradeRadio(true)
      // settradeRadioErr("")
      // settradeInputvalue('')
      setLotInputvalue("")
      setPersentageInputvalue("")
      // return
    }
    // else if (abc == 2) {
    //   //  settradeRadio(false)
    //   setLotRadio(true)
    //   settradeInputvalue('')
    //   // setLotInputvalue("")
    //   setPersentageInputvalue("")
    //   // return
    // } else if (abc == 3) {
    //   setPersentageRadio(true)
    //   settradeInputvalue('')
    //   setLotInputvalue("")
    //   // setPersentageInputvalue("")
    //   return
    // } else if (abc == 4) {
    //   setMinBrokrageRadio(true)
    //   settradeInputvalue('')
    //   setLotInputvalue("")
    //   setPersentageInputvalue("")
    //   return
    // }
  }, [abc]);

  // console.log("abc", abc);
  const changeValue = (e) => {
    // if (abc == 1) {
    //   settradeRadio(true)
    //   settradeInputvalue(userdata.pertrade)
    //   setLotInputvalue("")
    //   setPersentageInputvalue("")
    //   // return
    // }
    // else if (abc == 2) {
    //   //  settradeRadio(false)
    //   setLotRadio(true)
    //   settradeInputvalue('')
    //   setLotInputvalue("")
    //   setPersentageInputvalue("")
    //   // return
    // } else if (abc == 3) {
    //   setPersentageRadio(true)
    //   return
    // }

    // if (parseInt(e.target.value) == 1) {
    //   if (!userdata.brokerage == 1) {
    //     settradeInputvalue('')
    //   }
    // }

    // else if (parseInt(e.target.value) == 2) {

    //   if (!userdata.brokerage == 2) {
    //     setLotInputvalue("")
    //   }
    // }
    // else if (parseInt(e.target.value) == 3) {

    //   if (!userdata.brokerage == 3) {
    //     setPersentageInputvalue("")
    //   }
    // }
    // else if (parseInt(e.target.value) == 4) {
    //   if (!userdata.brokerage == 4) {
    //     settradeInputvalue('')
    //     setLotInputvalue("")
    //     setPersentageInputvalue("")
    //   }

    // }

    setabc(parseInt(e.target.value));
  };

  return (
    <div id="conteudo">
      <div className="conteudo-inner">
        <div className="conteudo-wrapper">
          <div className="conteudo-titleSection">
            <div className="row">
              <div className="col-md-8">
                <strong className="conteudo-title">Edit Client</strong>
              </div>
              <div className="col-md-4 text-right">
                <Link
                  to="/admin/client"
                  className="px-3 py-2 rounded mb-0 btn-5"
                >
                  <i className="fa-solid fa-arrow-left mr-1"></i>Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="conteudo-box">
                <div className="conteudo-boxInner ">
                  <div className="section full mt-2 mb-2">
                    {/*<div className="section-title">Boxed</div>*/}
                    <div className="wide-block pb-1 pt-2 row">
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="name5">
                            UserName
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name5"
                            defaultValue={userdata.username}
                            readOnly
                            placeholder="UserName"
                          />
                          <i className="clear-input">
                            <ion-icon
                              name="close-circle"
                              role="img"
                              className="md hydrated"
                              aria-label="close circle"
                            />
                          </i>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="name5">
                            Full Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name5"
                            defaultValue={userdata.fullname}
                            readOnly
                            placeholder="Full Name"
                          />

                          <i className="clear-input">
                            <ion-icon
                              name="close-circle"
                              role="img"
                              className="md hydrated"
                              aria-label="close circle"
                            />
                          </i>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="email5">
                            Total License
                          </label>
                          <input
                            type="hidden"
                            defaultValue={totallicence.totallicence}
                          />
                          {/* <p></p> */}
                          <input
                            type=""
                            disabled
                            className="form-control"
                            defaultValue={totallicence.totallicence}
                          ></input>
                        </div>
                      </div>

                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="city5">
                            License to Month
                          </label>
                          <select
                            className="form-control custom-select"
                            id="city5 "
                            onChange={(e) => {
                              setLicence(e.target.value);
                            }}
                          >
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="email5">
                            Available Amount
                          </label>
                          <input type="hidden" defaultValue={availableamount} />
                          <input
                            type=""
                            disabled
                            className="form-control"
                            defaultValue={availableamount}
                          ></input>
                          {/* <p>{availableamount}</p> */}
                        </div>
                        {/* <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper mt-4">
                          <label className="label" htmlFor="email5">Current Limit </label>
                          <input type="hidden" defaultValue={Currentlimit} />
                          <p>{Currentlimit}</p>

                        </div> */}
                      </div>
                      {/* </div> */}
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="email5">
                            Recharge
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email5"
                            placeholder="Recharge"
                            defaultValue={0}
                            onChange={(e) => {
                              setRecharge(
                                e.target.value.replace(/[^0-9.]/g, ""),
                                setRechargeErr("")
                              );
                            }}
                            value={recharge}
                          />
                        </div>
                        {rechargeErr ? (
                          <p className="text-danger">{rechargeErr}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper ">
                          <label className="label" htmlFor="email5">
                            Current Limit{" "}
                          </label>
                          <input type="hidden" defaultValue={Currentlimit} />
                          {/* <p>{Currentlimit}</p> */}
                          <input
                            type=""
                            disabled
                            className="form-control"
                            defaultValue={Currentlimit}
                          ></input>
                        </div>
                      </div>
                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper">
                          <label className="label" htmlFor="city5">
                            Limit
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="city5"
                            onChange={(e) => {
                              setLimit(e.target.value);
                            }}
                            placeholder="Enter limit"
                          />
                        </div>
                      </div>

                      <div className="form-group boxed col-sm-6">
                        <div className="input-wrapper ">
                          <label className="label" htmlFor="email5">
                            User Password{" "}
                          </label>
                          <input type="hidden" defaultValue={password && password} />
                          {/* <p>{Currentlimit}</p> */}
                          <input
                            type=""
                            disabled
                            className="form-control"
                            defaultValue={password && password}
                          ></input>
                        </div>
                      </div>







                      {/* ---------------------------------------- */}
                      <div className="form-group boxed col-sm-12">
                        {/* -Radio Buttons */}
                        <label className="ms-4">Brokerage</label>


                        <div className="d-flex">
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="Pertrade"
                                value={1}
                                onChange={(e) => {
                                  changeValue(e);
                                  settradeRadio(e.target.checked);
                                  setMinBrokrageRadio(false);
                                  setLotRadio(false);
                                  setPersentageRadio(false);
                                }}
                                checked={abc == 1}
                                required
                              />
                              <label
                                className="form-check-label"
                                for="Pertrade"
                              >
                                Per trade
                              </label>
                            </div>
                          </div>
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="PerLot"
                                value={2}
                                onChange={(e) => {
                                  changeValue(e);
                                  setLotRadio(e.target.checked);
                                  settradeRadio(false);
                                  setPersentageRadio(false);
                                  setMinBrokrageRadio(false);
                                }}
                                checked={abc == 2}
                              />
                              <label className="form-check-label" for="PerLot">
                                Per Lot
                              </label>
                            </div>
                          </div>
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="TurnOverPersentage"
                                value={3}
                                onChange={(e) => {
                                  changeValue(e);
                                  setPersentageRadio(e.target.checked);
                                  settradeRadio(false);
                                  setMinBrokrageRadio(false);
                                  setLotRadio(false);
                                }}
                                checked={abc == 3}
                              />
                              <label
                                className="form-check-label"
                                for="TurnOverPersentage"
                              >
                                Turn Over Percentage
                              </label>
                            </div>
                          </div>
                          <div className="input-wrappe mb-2 col-3">
                            <div className="form-check mx-auto">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="MinimumBrokerage"
                                value={4}
                                onChange={(e) => {
                                  changeValue(e);
                                  setMinBrokrageRadio(e.target.checked);
                                  settradeRadio(false);
                                  setLotRadio(false);
                                  setPersentageRadio(false);
                                }}
                                checked={abc == 4}
                              />
                              <label
                                className="form-check-label"
                                for="MinimumBrokerage"
                              >
                                Minimum Brokerage
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* ---------------------------------------- */}

                        {/* for Per Trade */}
                        {tradeRadio || abc == 1 ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                className="form-control ssss"
                                placeholder=" Per Trade Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  settradeInputvalue(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  );
                                  settradeRadioErr("");
                                }}
                                // defaultValue={abc != 4 ? userdata.pertrade : ""}
                                value={tradeInputvalue}
                                required
                              />
                            </div>
                            {tradeRadioErr ? (
                              <p className="text-danger">{tradeRadioErr}</p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {/* for Per Lot */}
                        {LotRadio || abc == 2 ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control ssss"
                                placeholder=" Per Lot Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setLotInputvalue(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  );
                                  setLotRadioErr("");
                                }}
                                defaultValue={abc == 4 ? userdata.perlot : ""}
                                value={LotInputvalue}
                                required
                              />
                            </div>
                            {LotRadioErr ? (
                              <p className="text-danger">{LotRadioErr}</p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {/* for TurnOver */}
                        {persentageRadio || abc == 3 ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                %{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control ssss"
                                placeholder="Percentage"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setPersentageInputvalue(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  );
                                  setPersentageRadioErr("");
                                }}
                                // defaultValue={abc == 4 ? userdata.turn_over_percentage : ""}
                                value={persentageInputvalue}
                                required
                              />
                            </div>
                            {persentageRadioErr ? (
                              <p className="text-danger">
                                {persentageRadioErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {/* for Minimum Brokrage */}
                        {MinBrokrageRadio || abc == 4 ? (
                          <>
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control ssss"
                                placeholder="Per Trade Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  settradeInputvalue(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  );
                                  settradeRadioErr("");
                                }}
                                //defaultValue={abc == 4 ? userdata.pertrade : ""}
                                value={tradeInputvalue}
                                required
                              />
                            </div>
                            {tradeRadioErr ? (
                              <p className="text-danger">{tradeRadioErr}</p>
                            ) : (
                              ""
                            )}
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                &#8377;{" "}
                              </span>
                              <input
                                type="text"
                                class="form-control ssss"
                                placeholder="Per Lot Amount"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setLotInputvalue(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  );
                                  setLotRadioErr("");
                                }}
                                value={LotInputvalue}
                                //  defaultValue={abc == 4 ? userdata.perlot : ""}
                                required
                              />
                            </div>
                            {LotRadioErr ? (
                              <p className="text-danger">{LotRadioErr}</p>
                            ) : (
                              ""
                            )}
                            <div class="input-group  flex-nowrap  icon-group mb-2">
                              <span
                                class="input-group-text"
                                id="addon-wrapping"
                              >
                                %
                              </span>
                              <input
                                type="text"
                                class="form-control ssss"
                                placeholder=" Turnover"
                                aria-label="Username"
                                aria-describedby="addon-wrapping"
                                onChange={(e) => {
                                  setPersentageInputvalue(
                                    e.target.value.replace(/[^0-9.]/g, "")
                                  );
                                  setPersentageRadioErr("");
                                }}
                                //defaultValue={abc == 4 ? userdata.turn_over_percentage : ""}
                                value={persentageInputvalue}
                                required
                              />
                            </div>
                            {persentageRadioErr ? (
                              <p className="text-danger">
                                {persentageRadioErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* ---------------------------------------- */}

                      <div className="form-group boxed ">
                        <button
                          className="px-3  rounded mb-0 btn-5"
                          onClick={(e) => editclient(e)}
                        >
                          Update
                          <i
                            disabled={ButtonDisabled}
                            className="fa-solid fa-hand-pointer ml-1"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/*   <div className="conteudo-box mt-4">
          <div className="conteudo-boxInner">
            
          </div>
        </div> */}
          </div>
        </div>
        {alertMsg && (
          <AlertToast
            hideAlert={hideClose}
            showAlert={alertMsg}
            message={textAlert}
            alertColor={alertColor}
          />
        )}
      </div>
    </div>
  );
};

export default Edit_client;
