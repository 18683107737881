import React from 'react'

const Help_center = () => {
  return (
       <div id="conteudo" className='help-center'>
    <div className="conteudo-inner">
      <div className="conteudo-wrapper">
        <div className="conteudo-titleSection">
        <div className='row'>
        <div className='col-md-8'>
        <strong className="conteudo-title">Help Center</strong></div>

        </div>
         
        </div>
        <div className="row">
          <div className="col-md-12">

            
           <div className="section full mt-2 mb-2">
           {/*<div className="section-title">Boxed</div>*/}
             <div className="wide-block pb-1 pt-2 row px-2">
           
             <div className="section full mt-2 mb-2">
            
 
             <div className="accordion" id="accordionExample3">
 
                 <div className="item">
                     <div className="accordion-header">
                         <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#accordion001">
                             <ion-icon name="help-circle-outline" role="img" className="md hydrated" aria-label="help circle outline"></ion-icon>
                             About
                         </button>
                     </div>
                     <div id="accordion001" className="accordion-body collapse show" data-parent="#accordionExample3">
                         <div className="accordion-content">
                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend,
                             lacinia
                             ex quis, condimentum erat. Nullam a ipsum lorem.
                         </div>
                     </div>
                 </div>
 
                 <div className="item">
                     <div className="accordion-header">
                         <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#accordion002">
                             <ion-icon name="document-outline" role="img" className="md hydrated" aria-label="document outline"></ion-icon>
                             Detail
                         </button>
                     </div>
                     <div id="accordion002" className="accordion-body collapse" data-parent="#accordionExample3">
                         <div className="accordion-content">
                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend,
                             lacinia
                             ex quis, condimentum erat. Nullam a ipsum lorem.
                         </div>
                     </div>
                 </div>
 
                 <div className="item">
                     <div className="accordion-header">
                         <button className="btn" type="button" data-toggle="collapse" data-target="#accordion003">
                             <ion-icon name="chatbox-ellipses-outline" role="img" className="md hydrated" aria-label="chatbox ellipses outline"></ion-icon>
                             Comment
                         </button>
                     </div>
                     <div id="accordion003" className="accordion-body collapse" data-parent="#accordionExample3">
                         <div className="accordion-content">
                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend,
                             lacinia
                             ex quis, condimentum erat. Nullam a ipsum lorem.
                         </div>
                     </div>
                 </div>
 
             </div>
         </div>
             
              </div>
            </div>
          </div>

        </div>
   
     {/*   <div className="conteudo-box mt-4">
          <div className="conteudo-boxInner">
            
          </div>
        </div> */}
      </div>
    </div>
  </div>
  )
}

export default Help_center