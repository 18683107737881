import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ClientFundHistory } from "../../../services";
import { fDate, fDateTime } from "../common/formatTime"

const Fund_history = () => {

  const [odrdata, setOdrdata] = useState([]);

  const UserToken = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  // alert Toast
  // const [alertMsg, setAlertMsg] = useState(false)
  // const [alertColor, setAlertColor] = useState("")
  // const [textAlert, setTextAlert] = useState("")
  // const hideClose = () => {
  //   setAlertMsg(false)
  // }

  const naviagte = useNavigate();
  const backbtn = () => {
    naviagte('/profile');
  }


  const GetOrderList = async () => {
    const data = {
      "userid": user_id
    }
    const response = await ClientFundHistory(data, UserToken);
    // console.log("OrderList ", response.data.data);
    if (response.data.status) {
      setOdrdata(response.data.data)
    }
    // else{
    //         setOdrdata([response.data.data])
    // }
  };

  useEffect(() => {
    GetOrderList()
  }, [])


  return (
    <div id="appCapsule">

      <div className="section full">

      <div className="section-title d-block text-left p-0"><span><button onClick={backbtn} className="mx-0"><i class="fa-regular fa-arrow-left"></i></button></span>Fund History</div>
      <div className="wide-block pt-2 pb-2">
                     <div className='row'>
                      <div className='col-md-7 col-12 mx-auto' >
                      {/* <ul className="nav nav-tabs style1" role="tablist">
                          <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#deposit" role="tab" aria-selected="true">
                              <i className="fa-regular fa-money-bill-transfer pr-1"></i>
                              Deposit
                              </a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#withdrawl" role="tab" aria-selected="false">
                              <i className="far fa-hand-holding-usd pr-1"></i> Withdrawal
                              </a>
                          </li>
                         
                      </ul> */}
                      </div>
                     </div>
                    
                      <div className="tab-content mt-2">
                          {/* <div className="tab-pane fade active show" id="deposit" role="tabpanel">
                          <div className='table-responsive trade-history-table-modal' style={{ height: '500px' }}>
          <table className="table trade-table ">
            <thead>
              <tr>
                <th>S. No</th>
                <th>Date & Time</th>
                <th> Amount  </th>
                <th> Message  </th>
              
              </tr>
            </thead>
            <tbody>
              {odrdata?.map((item, index) => {
                return <>
                  <tr className='text-left'>
                    <td >{index + 1}</td>
                    <td>{fDateTime(item.createdAt)}</td>
                    <td>{item.cash_credit == null ? "-" : item.cash_credit}</td>
                    <td>{item.cash_debit == null ? "-" : item.cash_debit}</td>
                    
                  </tr>
                </>
              })}

            </tbody>
          </table>
        </div>
                          </div> */}
                          <div className="tab-pane fade active show" id="withdrawl" role="tabpanel">
                          <div className='table-responsive trade-history-table-modal' style={{ height: '500px' }}>
          <table className="table trade-table ">
            <thead>
              <tr>
                <th>S. No</th>
                <th>Date & Time</th>
                <th> Credit  </th>
                <th> Debit  </th>
                <th>Available Bal.</th>
              </tr>
            </thead>
            <tbody>
              {odrdata?.map((item, index) => {
                return <>
                  <tr className='text-left'>
                    <td >{index + 1}</td>
                    <td>{fDateTime(item.createdAt)}</td>
                    <td>{item.cash_credit == null ? "-" : item.cash_credit}</td>
                    <td>{item.cash_debit == null ? "-" : item.cash_debit}</td>
                    <td>{item.amount}</td>
                  </tr>
                </>
              })}

            </tbody>
          </table>
        </div>
                          </div>
                          <div className="tab-pane fade" id="reacharg" role="tabpanel">
                        
                          </div>
                      </div>

                  </div>
      
    



      </div>

      {/* {
        alertMsg && (
          <AlertMessages
            hideAlert={hideClose}
            showAlert={alertMsg}
            message={textAlert}
            alertColor={alertColor}
            />
        )
      } */}
    </div>
  )
}

export default Fund_history

