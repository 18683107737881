import axios from "axios";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { encode, decode } from "js-base64";
import socketIOClient from "socket.io-client";

import * as Config from "../../frontend/common/Config";

import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { GetReports } from "../../../services";
import { fDateTime } from "../../../component/frontend/common/formatTime";
import { getUserPendingAmmount, getUserUpdateAmmount } from "../../../services";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AlertToast from "../../../utils/AlertToast";

const Pending = () => {
  let location = useLocation();
  let navigate = useNavigate();
  if (location.search !== "") {
    var Reqquery = JSON.parse(decode(location.search.split("=")[1]));
  }
  // var Reqquery = JSON.parse(decode(location.search.split("=")[1]))

  const [alPrendingAmmount, setAllPendingAmmount] = useState([]);
  const [rowData, setRowData] = useState("");
  const [alUpdateAmmount, setUpdateAmmount] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [alUpdateMessage, setUpdateMessage] = useState("");
  const [alUpdateMessageError, setUpdateMessageeError] = useState("");
  const [Updatestatus, setUpdatestatus] = useState("");

  // Alert Toast-----------------------------------------
  const [showAlert, setShowAlert] = useState(false);
  const [UpdateNotificationtitle, setUpdateNotificationtitle] = useState("");
  // console.log('UpdateNotificationtitle deposite', UpdateNotificationtitle);

  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");

  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  // console.log("rowData", rowData);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data, event) => {
    if (event == "Complete") {
      setUpdatestatus(1);
      setUpdateNotificationtitle(
        `Request of Deposite Rs.${data.deposite_payment} has been Accepted by the admin`
      );
    }
    if (event == "Reject") {
      setUpdatestatus(2);
      setUpdateNotificationtitle(
        `Request of Deposite Rs.${data.deposite_payment} has been Rejected by the admin`
      );
    }
    if (
      event == "Complete" ||
      event == "Reject" ||
      (event == "" && !event == "Pending")
    ) {
      setShow(true);
      setRowData(data);
    }
  };
  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const GetTradeReports = async () => {
    const req = {
      adminid: UserId,
      type: "deposite",
    };

    const response = await getUserPendingAmmount(req, AuthLogin);
    if (response.data.status) {
      setAllPendingAmmount(response.data.data);

      let abc = response.data.data.filter((item) => {
        // console.log("item", item);
        if (Reqquery.key == "0") {
          return parseInt(item.id) == parseInt(Reqquery.id);
        } else if (Reqquery.key == "1") {
          return parseInt(item.id) == parseInt(Reqquery.id);
        } else {
          return [];
        }
      });
      // console.log("deposite abc", abc);

      if (abc.length == 0 || Reqquery == "") {
        setAllPendingAmmount(response.data.data);
      } else {
        setAllPendingAmmount(abc);
      }
    }
    // setRefresh(!refresh)
  };

  const UpdateFund = async (e) => {
    e.preventDefault();

    // if (alUpdateMessage === "") {
    //   setUpdateMessageeError("please Enter A Valid Message")
    //   return
    // }

    // console.log("Updatestatus", Updatestatus);
    if (Updatestatus == 1) {
      const socket = socketIOClient(Config.base_url);
      var data = {
        data: `Request of Deposite Rs.${rowData.deposite_payment} has been Accepted by the admin`,
        userid: rowData.userid,
      };

      socket.emit("req_pendingdeposite_accept_message", data);
      // setUpdateNotificationtitle(`Your Withdraw  Fund Request  Accept By Admin`)
    }
    if (Updatestatus == 2) {
      const socket = socketIOClient(Config.base_url);
      var data = {
        data: `Request of Deposite Rs.${rowData.deposite_payment} has been Reject by the admin`,
        userid: rowData.userid,
      };
      socket.emit("req_pendingdeposite_reject_message", data);

      // socket.emit("req_pendingdeposite_accept_message", "Your Deposite Fund Request  Accept By Admin");

      // setUpdateNotificationtitle(`Your Withdraw Fund Request Reject By Admin`)
    }

    const req = {
      id: rowData.id,
      adminid: rowData.adminid,
      userid: rowData.userid,
      status: Updatestatus,
      note: alUpdateMessage,
      payment: rowData.deposite_payment,
      type: "deposite",
      notification_title: UpdateNotificationtitle,
    };

    const response = await getUserUpdateAmmount(req, AuthLogin);
    // console.log("response.data.status", response.data.status);
    if (response.data.status) {
      setShow(false);
      setShowAlert(true);
      setTextAlert(response.data.message);
      setAlertColor("success");
      setRefresh(!refresh);
      setAllPendingAmmount("");
    }
    // console.log("req", req);
  };

  const colums = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: " Date And Time",
      selector: (row) => fDateTime(row.createdAt),
    },
    {
      name: "User Name",
      cell: (row) => row.username.username,
    },
    {
      name: "Payment",
      selector: (row) =>
        row.deposite_payment == "" ? "-" : row.deposite_payment,
    },
    {
      name: "Message",
      selector: (row) => (row.note == "" ? "No Message" : row.note),
    },
    {
      name: "Action",
      cell: (row) => (
        <select
          onChange={(e) => {
            handleShow(row, e.target.value);
          }}
          selected
        >
          <option value="Pending">Pending</option>
          <option value="Complete">Complete</option>
          <option value="Reject">Reject</option>
        </select>
      ),
    },
  ];

  useEffect(() => {
    GetTradeReports();
  }, [refresh]);

  useEffect(() => {
    // return () => {
    //   socket.disconnect();
    // };
  }, []);
  useEffect(() => {
    const table = document.querySelector(".rdt_Table");

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener("dragstart", preventDrag);
      table.addEventListener("dragover", preventDrag);
      table.addEventListener("drop", preventDrag);

      return () => {
        table.removeEventListener("dragstart", preventDrag);
        table.removeEventListener("dragover", preventDrag);
        table.removeEventListener("drop", preventDrag);
      };
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="conteudo-box">
            <div className="conteudo-boxInner p-0">
              <DataTable
                columns={colums}
                data={alPrendingAmmount}
                fixedHeaderScrollHeight="400px"
                fixedHeader
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Are You Want To Change Status</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <button
            className="btn btn-primary btn-block w-auto"
            type="submit"
            onClick={(e) => UpdateFund(e)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default Pending;
