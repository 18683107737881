import React, { useState, useEffect } from 'react'
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component'
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fDateTime } from '../../frontend/common/formatTime';


import { useNavigate } from 'react-router-dom'
import { UserStatmentList } from "../../../services";


const Statement = () => {
  const [client, setClient] = useState([]);
  const [ModelData, setModelData] = useState('');
  const [Color, setColor] = useState('#4BB543');

  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");
  
const [filter, setFilter] = useState("");


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setModelData(item)
    setShow(true)
   
  };

 
  //   // e.preventDefault();

  //   const data = {
  //     "user_id": UserId,
  //   }
  //   const response = await UserStatmentList(data, AuthLogin);
  //   var statemntData = []

  //   // console.log("response.data.data.map", response.data.data[0].amount);
  //   if (response.data.status) {
  //         const searchmatch = response.data.data && response.data.data.filter((item)=>{
  //             const Match = search == "" ||
  //             item.credit_debit.toLowerCase().includes(search.toLowerCase())

  //             return Match
  //         })
  //     const updatedArray = response.data.data.map(item => {
  //       // if (item.amount !== '0') {
  //         const match = response.data.profit_loss_array.find(obj => obj.id === item.id);
  //         if (match && item.amount) {
  //           return { ...item, order_profit_loss: match.profit_loss, remaining_entry_am: match.remaining_entry_amount };
  //         } else {
  //           return item;
  //         }
  //       // } else {
  //       //   return "null"
  //       // }
  //     });
  //     setClient(search ? searchmatch :updatedArray)
  //     // 

  //     // console.log("update arr", client);



  //     // var matchingData = response.data.data.forEach(item1 => {
  //     //   // console.log("item1 ", item1);

  //     //   const item2 = response.data.profit_loss_array.find(item2 => {
  //     //     if (item1 != undefined) {
  //     //       console.log("hello world", item2.id === item1.id);
  //     //     }
  //     //   })
  //     //   if (item2) {
  //     //     item1.order_profit_loss = item2.profit_loss;
  //     //   } else {
  //     //     return item1
  //     //   }
  //     // });
  //     // console.log("filter ", matchingData);




  //     // var price
  //     // const matchingData = response.data.data.map((obj1) => {   

  //     //   response.data.profit_loss_array.map(obj2 => {
  //     //     if (obj1.id == obj2.id) {

  //     //       price = obj2.profit_loss
  //     //       return
  //     //     }
  //     //     else {
  //     //       price = obj1.order_profit_loss
  //     //       return
  //     //     }
  //     //   });

  //     //   console.log("price-", price);
  //     // });



const handleFilterChange = (e) => {
    setFilter(e.target.value);
};





const GetStatmentList = async () => {
  const data = {
      "user_id": UserId,
  };

  const response = await UserStatmentList(data, AuthLogin);
  if (response.data.status) {
       
    // console.log("aaa",response.data)

      const searchmatch = response.data.data && response.data.data.filter((item) => {
          const matchFilter = filter === "" || item.credit_debit.toLowerCase() === filter.toLowerCase();
          return matchFilter;
      });

      const updatedArray = searchmatch.map(item => {
          const match = response.data.profit_loss_array.find(obj => obj.id === item.id);
          if (match && item.amount) {
              return {
                  ...item,
                  order_profit_loss: match.profit_loss,
                  remaining_entry_am: match.remaining_entry_amount
              };
          } else {
              return item;
          }
      });

      setClient(updatedArray);
  } else {
      setClient([]);
  }
};


  useEffect(() => {
    GetStatmentList();
  }, [filter])



  const naviagte = useNavigate();
  const backbtn = () => {
    naviagte('/profile');
  }


  const showFund = (credit_debit, cash_credit, cash_debit, order_entry_fund_used, order_profit_loss, remaining_entry_am) => {
    if (credit_debit == "Credit") {
      if (cash_credit != null) {
        // setColor(cash_credit)
        return `+${cash_credit}`
      }
      else if (cash_credit == null && order_profit_loss != null)
        // setColor(cash_credit)
        return `${order_profit_loss > 0 ? "+"+order_profit_loss :order_profit_loss}`
    }
    else {
      if (cash_debit != null && order_profit_loss == null && order_entry_fund_used == null) {
        // setColor(cash_debit)
        return `-${cash_debit}`
      }
      else if (cash_debit == null && order_profit_loss != null && order_entry_fund_used == null) {
        // setColor(cash_debit)
        return `${order_profit_loss}`
      }
      else if (cash_debit == null && order_profit_loss == null && order_entry_fund_used !== null) {
        // setColor(order_entry_fund_used)
        return  `-${order_entry_fund_used}` 
      }
    }

    // else if (credit_debit == "Debit") {
    //   return `-${cash_debit}` || `-${order_entry_fund_used}` || `-${order_profit_loss}`
    // }
    // else if (cash_credit == null && cash_debit == null && order_entry_fund_used == null) {
    //   return `-${order_profit_loss}`
    // }


  }



   // date format 

   function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // JavaScript months are 0-based
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }





  return (
    <div id="appCapsule">
      <div className="section full ">
        <div className="section-title d-block text-left px-0 mb-2"><span><button onClick={backbtn} className="mx-0"><i class="fa-regular fa-arrow-left"></i></button></span>Statement</div>

                <div className="form-group">
                    <select className="form-control custom-select" value={filter} onChange={handleFilterChange}>
                        <option value="">All</option>
                        <option value="debit">Debit</option>
                        <option value="credit">Credit</option>
                    </select>
                </div>

                
          
        <ListGroup variant="flush" >
          <ListGroup.Item></ListGroup.Item>
          {
            client && client.length == 0 ? <p className='text-center'>No Data Found</p> :
              <>
                {client && client.map((item) => {
                  const { credit_debit, cash_debit, cash_credit, createdAt, order_profit_loss, brokerage, order_entry_fund_used, remaining_entry_am } = item
                  return <>

                 
                    {cash_credit !='0'  ? 
                    
                    <ListGroup.Item className='p-1' >
                    <div className='d-flex ' >

                      <div className='col-6  text-left flex-grow-1 flex-column'>
                        <h4 style={{ fontSize: '15px', fontWeight: '700', color: credit_debit == 'Credit' ? "#4BB543" : 'red' }}>{credit_debit == "Debit" && cash_debit != null ? "Debit(withdrawal)" : credit_debit}</h4>
                        <span style={{ fontSize: '13px' }}>{fDateTime(createdAt)}</span>
                      </div>

                      <div className='text-right col-6  '>
                        {/* <h4 style={{ fontSize: '15px', fontWeight: '700', color: parseInt(order_profit_loss) > 0 || parseInt(order_entry_fund_used) > 0 ? "#4BB543" : 'red' }}> &#8377; {order_profit_loss == null ? order_entry_fund_used : order_profit_loss} </h4> */}
                        {/* <h4 style={{ fontSize: '15px', fontWeight: '700', color: credit_debit == 'Credit' ? "#4BB543" : 'red' }}> &#8377; {order_profit_loss == null ? `-${order_entry_fund_used}` : `+${order_profit_loss}`} </h4> */}


                        {/* <h4 style={{ fontSize: '15px', fontWeight: '700', color: parseFloat(Color.val)> 0 ? "#4BB543" : 'red' }}> &#8377; { */}
                        <h4 style={{ fontSize: '15px', fontWeight: '700', color: order_entry_fund_used !=null? 'red': order_profit_loss !=null?  parseInt(order_profit_loss) > 0 ? '#4BB543' : 'red'  : cash_debit != null ?'red' :  '#4BB543'}}> &#8377; {
                          showFund(credit_debit, cash_credit, cash_debit,  order_entry_fund_used, order_profit_loss)
                          // credit_debit == 'Credit' ? order_profit_loss == null ? `+${cash_credit}` : `+${order_profit_loss}` : order_entry_fund_used == null ? `-${cash_debit}` : `-${order_entry_fund_used}`
                        } </h4>





                        {brokerage == null ? "" :
                          <>
                            < h4 style={{ fontSize: '12px', fontWeight: '700', color: 'red' }}>  Brokarge  &#8377; -{brokerage} </h4>
                          </>
                        }
                        {remaining_entry_am == null ? "" :
                          <>
                            {/* < h4 style={{ fontSize: '12px', fontWeight: '700', color: parseInt(remaining_entry_am) > 0 ? '#4BB543' : 'red' }}>Remain. Entry Amount : &#8377;{parseFloat(remaining_entry_am).toFixed(2)} </h4> */}
                          </>
                        }
                      </div>
                    </div>
                    {
                      cash_debit == null && cash_credit == null ?
                        <>
                       <div className='text-left text-primary d-flex mt-1 ms-2 pr-2' style={{display:"flex",justifyContent:"start",alignItems:"center",paddingLeft:"9px"}} ><span className="ps-3" style={{color:'#000',fontWeight:'bold'}}>Symbol: {item.symbol}</span></div>

                          <button href="#" className='text-left text-primary d-flex mt-1 ms-2 ' style={{ fontSize: '15px', fontWeight: '700' }} onClick={() => { handleShow(item) }} ><u>Order Details &#8594;</u></button>
                        </> : ""
                    }

                  </ListGroup.Item>
                    
                    
                    :   "" }

            
                  </>
                })
                }
              </>
          }
        </ListGroup >


        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"

        >
          <Modal.Header >
            <h3>Order Detail</h3>
            <i class="fa-solid fa-xmark fa-2x" onClick={() => setShow(false)}></i>
          </Modal.Header>
          <Modal.Body>
            <div>

              {/* //  <ListGroup.Item></ListGroup.Item> */}



              <div className='py-1 table-responsive' >
                <table className='table' >
                  <thead>

                    <tr className='text-white' style={{ background: '#2f55a3' }}>
                      <th><h4 className=' flex-grow-1 text-white ' style={{ fontSize: '13px' }}>Trade Date</h4></th>
                      <th >  <h4 className='text-white ' style={{ fontSize: '13px' }}>Trade Symbol</h4></th>
                      <th>  <h4 className=' text-white ' style={{ fontSize: '13px' }}>Net Amt.</h4></th>
                      <th>  <h4 className=' flex-grow-1 text-white ' style={{ fontSize: '13px' }}>Lot.</h4></th>
                      <th>  <h4 className=' flex-grow-1 text-white w-100 ' style={{ fontSize: '13px' }}>QTY.</h4></th>
                      <th className="text-white" style={{ width: '68px; !important;' }}>  <h4 className=' text-white' style={{ fontSize: '13px', width: '68px !important ' }}>Type</h4></th>
                      <th className="text-white" style={{ width: '68px; !important;' }}>  <h4 className=' text-white' style={{ fontSize: '13px', width: '68px !important ' }}>Profit/loss</h4></th>
                    </tr>
                  </thead>
                  <tr>
                    <td><span className='' style={{ fontSize: '13px' }}>{formatDate(ModelData.updatedAt)}</span></td>
                    <td><span className='  mb-0 text-muted' style={{ fontSize: '13px' }}>{ModelData.symbol}</span></td>
                    <td><span className='  mb-0 text-muted' style={{ fontSize: '13px' }}>{ModelData.price}</span></td>
                    <td><span className='  mb-0 text-muted' style={{ fontSize: '13px' }}>{ModelData.lot}</span></td>
                    <td><span className=' mb-0 text-muted' style={{ fontSize: '13px' }}>{ModelData.qty}</span></td>
                    <td><span className='  mb-0 text-muted' style={{ fontSize: '13px' }}>{ModelData.type}</span></td>
                    <td><span className='  mb-0 text-muted' style={{ fontSize: '13px' }}>{ModelData.order_profit_loss ? ModelData.order_profit_loss : "-"}</span></td>
                  </tr>
                </table>
                <div className='col-12 d-flex flex-grow-1 '>
                  {/* <h3 className='text-center'>IDEA25JAN23FUT</h3> */}
                  {/*<h4 className=' flex-grow-1 text-muted' style={{ fontSize: '15px' }}>Trade Date</h4>
                    <span className='float-right' style={{ fontSize: '15px' }}>{ModelData.expiry}</span> */}
                </div>
                <div className='col-12 d-flex flex-grow-1 my-3  border-bottom'>
                  {/* <h3 className='text-center'>IDEA25JAN23FUT</h3> */}
                  {/*   <h6 className=' flex-grow-1  mb-0  text-muted' style={{ fontSize: '11px' }}>Item</h6>
                    <span className='float-right  mb-0 text-muted' style={{ fontSize: '11px' }}>Net - Amount</span> */}
                </div>
                <div className='row w-100' >
                  <div className='col-12  text-center flex-grow-1 flex-column'>
                    {/* <h3 className='text-center'>IDEA25JAN23FUT</h3> */}
                    {/*  <h4 className=' flex-grow-1  my-1  text-muted' style={{ fontSize: '17px' }}>{ModelData.symbol}</h4>
                      <div className='d-flex'>
                        <h4 className=' flex-grow-1  my-1  text-muted' style={{ fontSize: '15px' }}>Lot.  {ModelData.qty}</h4>
                        <h4 className=' flex-grow-1   my-1  text-muted' style={{ fontSize: '15px' }}>Oty.  {ModelData.lot}</h4>
                      </div> */}
                    {/* <h4 className=' flex-grow-1   my-1 text-uppercase' style={{ fontSize: '15px', fontWeight: '700', color: ModelData.type == 'sell' ? "red" : '#4BB543' }}>{ModelData.type}</h4> */}
                    {/* <span className='text-muted'>12/12/12</span> */}
                  </div>

                  <div className='text-center col-12  '>
                    {/*  <h4 className=' flex-grow-1   my-1  text-muted' style={{ fontSize: '15px' }} > &#8377; {ModelData.price}</h4> */}
                    {/* <h4 className=' flex-grow-1  my-1  text-muted' style={{ fontSize: '15px' }}>LotSize {ModelData.qty * ModelData.lot}</h4>  */}
                    {/* {ModelData.order_profit_loss == null ? "" :
                      <h4 className=' flex-grow-1   my-1 text-muted ' style={{ fontSize: '15px' }}>profit/loss    {ModelData.order_profit_loss ? ModelData.order_profit_loss : "-"}</h4> */}

                    {/* <span className="text-muted" style={{ fontSize: '13px' }}>Closn.bal.  &#8377; 17000</span> */}
                  </div>
                </div>

              </div>


            </div>



          </Modal.Body>
        </Modal>
      </div ></div >
  )
}


export default Statement