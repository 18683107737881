import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AlertToast from "../.././../utils/AlertToast";
import { Icon } from "@iconify/react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import $ from "jquery";
import CryptoJS from "crypto-js";
import * as Config from "../common/Config";
import io from "socket.io-client";
import swal from 'sweetalert'
import socketIOClient from 'socket.io-client';

const Watchlist = () => {
  const endpoint = Config.base_url;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Establish WebSocket connection when component mounts
    const socket = socketIOClient(endpoint);

    // Define event handlers
    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('receive_data_forex', (message) => {
      //  console.log('Received message:', message.data);
     //  console.log('Received message:', message.type);
      // console.log('Received message:', message[5]);
      // console.log('Received message:', message[2]);
      // setMessages((prevMessages) => [...prevMessages, message]);

      if (message.type != undefined && message.type == 'crypto') {
        
        const old_val = $('.showdatalp' + message.data[1]).html();

        if (message.data[6] != undefined) {
          $(".showdatalp" + message.data[1]).html(message.data[6].toFixed(3));
        }

        const new_val = $('.showdatalp' + message.data[1]).html();

        if (new_val > old_val) {

          $('.showdatalp' + message.data[1]).css({ "color": "green" });

        } else if (new_val < old_val) {

          $('.showdatalp' + message.data[1]).css({ "color": "red" });
        } else if (new_val == old_val) {

          $('.showdatalp' + message.data[1]).css({ "color": "black" });
        }

      }
      else if (message.type != undefined && message.type == 'forex') {
        const old_val = $('.showdatalp' + message.data[1]).html();

        if (message.data[5] != undefined) {
          $(".showdatalp" + message.data[1]).html(message.data[5].toFixed(6));
        }

        const new_val = $('.showdatalp' + message.data[1]).html();

        if (new_val > old_val) {

          $('.showdatalp' + message.data[1]).css({ "color": "green" });

        } else if (new_val < old_val) {

          $('.showdatalp' + message.data[1]).css({ "color": "red" });
        } else if (new_val == old_val) {

          $('.showdatalp' + message.data[1]).css({ "color": "black" });
        }

      }
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    // Clean up WebSocket connection when component unmounts
    return () => {
      socket.disconnect();
    };
  }, [endpoint]); // Only re-run effect if endpoint changes
  
  

  const [auth, setAuth] = useState("");
  const [adddata, setAdddata] = useState([]);
  const [posts, setPosts] = useState([]);
  const [uid, setUid] = useState("");
  const navigate = useNavigate();
  const [searcdata, setSearcdata] = useState();
  // console.log("searcdata", searcdata);
  const [watchdata, setWatchdata] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [listData, setListData] = useState([]);
  const [listDataExcSeg, setListDataExcSeg] = useState([]);
  // console.log("listData", listData);
  const [tokenSymbol, setTokenSymbol] = useState([]);

  // For Alert Toast
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [tokenMatch, setTokenMatch] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [tokenMatchArray, setTokenMatchArray] = useState([]);
  const [arrayobject, setArrayobject] = useState([]);
  const [sockets, setSockets] = useState(null);
  const [allResponseSocket, setAllResponseSocket] = useState([]);
  const [socketuserid, setSocketuserid] = useState("");
  const [socketuserSession, setSocketuserSession] = useState("");
  const [listDataChannel, setListDataChannel] = useState("");
 

  
  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  var BASEURL = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/";
  let AuthorizationToken;
  let type = "API";

  function TokenAndClientCode(returntokenstring) {
    // alert(token);

    var config = {
      method: "get",
      url: `${Config.base_url}api/alicebluetoken`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
       
        if (response.data.status == "true") {
          //  console.log('Token Available', response.data.data[0].client_code + '  ' + response.data.data[0].access_token);
          setSocketuserid(response.data.data[0].client_code);
          setSocketuserSession(response.data.data[0].access_token);

          createSession(
            response.data.data[0].client_code,
            response.data.data[0].access_token,
            returntokenstring
          );
        } else {
          //  console.log('Token Not Available');
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function invalidateSession(userId, userSession, returntokenstring) {
    AuthorizationToken = "Bearer  " + userId + " " + userSession;
    // AuthorizationToken = token;

    // console.log('AuthorizationToken1',AuthorizationToken);
    let jsonObj = {
      loginType: type,
    };
    $.ajax({
      url: BASEURL + "api/ws/invalidateSocketSess",
      headers: {
        Authorization: AuthorizationToken,
      },
      type: "post",
      data: JSON.stringify(jsonObj),
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (msg) {
        var data = JSON.stringify(msg);

        if (msg.stat == "Ok") {
          createSession(userId, userSession, returntokenstring);
        }
      },
    });
  }

  function createSession(userId, userSession, returntokenstring) {
    //alert('create session')

    AuthorizationToken = "Bearer " + userId + " " + userSession;

    let jsonObj = {
      loginType: type,
    };
    $.ajax({
      url: BASEURL + "api/ws/createSocketSess",
      headers: {
        Authorization: AuthorizationToken,
      },
      type: "post",
      data: JSON.stringify(jsonObj),
      contentType: "application/json",
      dataType: "json",
      async: false,
      success: function (msg) {
        var data = JSON.stringify(msg);
        if (msg.stat == "Ok") {
          connect(userId, userSession, returntokenstring);
        } else {
          alert(msg);
        }
      },
    });
  }

  const url = "wss://ws1.aliceblueonline.com/NorenWS/";
  let socket;
  function connect(userId, userSession, returntokenstring) {
    socket = new WebSocket(url);

    socket.onopen = function () {
      connectionRequest(userId, userSession);

      setSockets(socket);
    };

    socket.onmessage = function (msg) {
      var response = JSON.parse(msg.data);

      if (response.s == "OK") {
        var channel = returntokenstring;
        //console.log("channel", channel);
        let json = {
          k: channel,
          t: "t",
        };

        socket.send(JSON.stringify(json));

        socket.onmessage = function (msg) {
          var response = JSON.parse(msg.data);
          //console.log('response socket ggg', response)

          if (response.tk) {
            if (response.lp != undefined) {
              $(".showdatalp" + response.tk).html(response.lp);
            }

            if (response.pc != undefined) {
              var pcValue = "";

              if (response.pc > 0) {
                // console.log('response socket ggg color ---', response)
                pcValue = "↑ ( " + response.pc + " % ) ";
                $(".showdatalp" + response.tk).css({ color: "green" });
                $(".showdatapc" + response.tk).css({ color: "green" });
              } else if (response.pc < 0) {
                pcValue = " ↓ ( " + response.pc + " % ) ";
                $(".showdatalp" + response.tk).css({ color: "red" });
                $(".showdatapc" + response.tk).css({ color: "red" });
              } else if (response.pc == 0) {
                pcValue = "( " + response.pc + " % ) ";
                $(".showdatalp" + response.tk).css({ color: "black" });
                $(".showdatapc" + response.tk).css({ color: "black" });
              }
              $(".showdatapc" + response.tk).html(pcValue);
              $(".showdatapcval" + response.tk).html(response.pc);
              //  colorpriceNew(response.tk)
            }
          }
        };

        socket.onclose = function (event) {
          if (event.wasClean) {
            TokenAndClientCode(returntokenstring);
          } else {
            TokenAndClientCode(returntokenstring);
          }
        };

        socket.onerror = function (error) {
          TokenAndClientCode(returntokenstring);
          // alert(returntokenstring);
          // alert(`[error]`);
        };
      }
    };
  }


  
  function connectionRequest(userId, userSession) {
    var encrcptToken = CryptoJS.SHA256(
      CryptoJS.SHA256(userSession).toString()
    ).toString();
    // alert(encrcptToken);
    var initCon = {
      susertoken: encrcptToken,
      t: "c",
      actid: userId + "_" + type,
      uid: userId + "_" + type,
      source: type,
    };
    // console.log('initCon', JSON.stringify(initCon));
    socket.send(JSON.stringify(initCon));
  }

  useEffect(() => {
    // TokenAndClientCode()
    userSymbolList();
  }, [refresh]);

  useEffect(() => {
    watchlistdata();
  }, [searcdata, sockets]);

  const trash = () => {
    setSearcdata(null);
    //setSearcdata('');
  };

  useEffect(() => {
    // socketuserid
    // socketuserSession
    //invalidateSession(socketuserid, socketuserSession)
    //invalidateSession("770911","sIQkZg1pyUqZTKeG3ooTvZcsrDk3eyG56W2laAWHUvKRNQtCWbNPPy3vcCtZ8QnYmnLbap6o2RboWjtOU2nKI8dURAzX8ileNMhmWNUyYgkzSIcBXA99oCr1h0CfhdfTW5UF3ntoNaIT1W6jNh4Bo7cEALuNDAinI6u4VaC7K0eTHdZkREalnuqiKCS2PEF3R9p6gt5GAjUAnm4dqB9hfPmJEYze3Wa4MbDhK69094Jfbjjf3d5XpfKCiO3N3ilt")
    // setSearcdata("@");
    // $(".eventabc").trigger("input")
  }, [refresh, sockets]);

  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const watchlistdata = () => {
    var config = {
      method: "get",
      //url: `http://180.149.241.128:5008/api/symbolSearch?symboleName=${searcdata}`,
      url: `${Config.base_url}api/symbolSearch?symboleName=${searcdata}`,
      headers: {
        "x-access-token": accesstoken,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log("search", response.data);
        setWatchdata(response.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const Listdata = (trading_symbol) => {
    var data = JSON.stringify({
      userid: userid,
      symbolname: trading_symbol,
    });

    var config = {
      method: "post",
      //url: "http://180.149.241.128:5008/api/addSymbol",
      url: `${Config.base_url}api/addSymbol`,
      headers: {
        "x-access-token": accesstoken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setAdddata(trading_symbol);
        userSymbolList();

        if (response.data.message == "Symbol Added Successfully!") {
          setShowAlert(true);
          setAlertColor("success");
          setTextAlert("Symbol Added Successfully!");
          setrefresh(!refresh);

          // console.log('data ',response.data.data.token)
        } else if (response.data.message == "Symbol Already Add!") {
          setShowAlert(true);
          setAlertColor("warning");
          setTextAlert("Symbol Alerady Added!");
          setrefresh(!refresh);
        }
      })
      .catch(function (error) {
        // console.log("hello", error);
      });

    setSearcdata(null);
  };

  var tokenPush = [];

  const userSymbolList = () => {
    var data = JSON.stringify({
      userid: userid,
    });

    var config = {
      method: "post",
      url: `${Config.base_url}api/usersymbollist`,
      headers: {
        "x-access-token": accesstoken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status == "true") {
          setPosts(response.data.data);
          var tokenArr = [];
          var tokenArrExcSeg = [];

          var tokenstring = "";
          response.data.data.map((cl) => {
            tokenstring += cl.exch_seg + "|" + cl.token + "#";

            tokenArr.push(cl.token);
            tokenArrExcSeg.push({ token: cl.token, exch_seg: cl.exch_seg });
            setListData(tokenArr);
            setListDataExcSeg({ tokenArrExcSeg });
          });

          var returntokenstring = tokenstring.substring(
            0,
            tokenstring.length - 1
          );

          TokenAndClientCode(returntokenstring);
        }
      })
      .catch(function (error) {});
  };



  
  
  const deletestock = async (symbol) => {
    try {
        const confirm = await swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });
        
        if (!confirm) {
            return;
        }
        
        const config = {
            method: "delete",
            url: `${Config.base_url}api/deletwatchlistsymbol/${symbol}/${userid}`,
            headers: {
                "x-access-token": accesstoken,
            },
        };

        const response = await axios(config);
        
        if (response.status === 200) {
            setShowAlert(true);
            setAlertColor("success");
            setTextAlert(response.data.message);
            setPosts("");
            setrefresh(!refresh);
        }
    } catch (error) {
        console.log(error);
    }
};
;





  const handleChange = (e) => {
    if (e.target.value == "") {
      trash();
    } else {
      setSearcdata(e.target.value);
    }
  };



  const buystock = () => {
    navigate("/stock_detail");
  };



  function UpDownPrice(token) {
    var rr;
    allResponseSocket &&
      allResponseSocket.forEach((element) => {
        if (element.tk == token) {
          rr = element.c;
        }
      });
    return rr;
  }

  function colorprice(val, token) {
    var val1 = $(".showdata" + token).html();
    var res;
    allResponseSocket &&
      allResponseSocket.forEach((element) => {
        if (element.tk == token) {
          res = val1 - element.c;
        }
      });

    // console.log(" res res",res)
    if (res > 0) {
      return "green";
    } else if (res < 0) {
      return "red";
    } else {
      return "black";
    }
  }

  function colorpriceNew(token) {
    var val = $(".showdatapcval" + token).html();
    // console.log('dd',val)
    if (val > 0) {
      return "green";
    } else if (val < 0) {
      return "red";
    } else if (val == 0) {
      return "black";
    }
  }

  function realtokenPrice(token) {
    var liveprice = $(".showdata" + token).html();
    return liveprice;
  }

  // console.log("user list state", posts && posts);

  

  if (AuthRole == "ROLE_USER") {
    // console.log("serchresultdata", adddata);
    return (
      <div id="appCapsule">
        <div className="section full">
          <div className="section-title">Watchlist</div>
          <div className="wide-block pt-2 pb-2">
            <div className="search-form">
              <div className="form-group searchbox">
                <input
                  type="search"
                  className="form-control eventabc"
                  placeholder="Search a stock"
                  value={searcdata}
                  onChange={handleChange}
                />
                <i className="input-icon">
                  <ion-icon
                    name="search-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="search outline"
                  />
                </i>
                <button onClick={trash}>
                  <i className="fa-solid fa-x"></i>
                </button>
              </div>
            </div>
          </div>

          {watchdata.length === 0 && posts.length === 0 ? (
            <div className="text-center my-5">
               <div className="no-data">
    <p className='text-center'>No Data Found!</p>
      <img src="/images/nodata.webp" className=""/>
      </div>
            </div>
          ) : (
            <>
              <ul className="p-0">
                {watchdata.map((items) => {
                  return (
                    <li
                      className="d-flex justify-content-between my-3 px-5"
                      value={items.trading_symbol}
                      onClick={() => Listdata(items.trading_symbol)}
                    >
                      {items.trading_symbol}
                      <button className="btn btn-primary">
                        <i className="fa fa-plus"></i>
                      </button>
                    </li>
                  );
                })}
              </ul>

              <ul className="listview link-listview search-result mb-2">
                {posts &&
                  posts.map((items) => {
                    return (
                      <li key={items.symbol}>
                        <div className="row w-100 mx-auto align-items-center">
                          <div className="col-11 px-0">
                            <Link
                              to={"/stock_detail"}
                              state={{
                                stock: items,
                                socket: socket,
                                userId: socketuserid,
                                userSession: socketuserSession,
                              }}
                              className="d-block"
                            >
                              <div className="row d-flex align-items-md-center align-items-start">
                                <div className="col-8 px-0">
                                  <h4 className="mb-05 symbol-title">{items.symbol}</h4>
                                  <div className="text-muted">
                                    <div className="mt-05">
                                      <div>
                                        <span className="badge badge-danger">
                                          {items.exch_seg}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4 px-0">
                                  <div className="text-muted text-right d-flex justify-content-end">
                                    <div
                                      style={{ display: "none" }}
                                      className={"showdatapcval" + items.token}
                                    ></div>
                                    <div className="row">
                                      <div className="col-md-12 d-flex justify-content-end">
                                        <span className="pr-1">
                                          <b style={{ color: "black" }}>₹</b>
                                        </span>
                                        <div
                                          className={"showdatalp" + items.token}
                                        ></div>
                                      </div>
                                      <div className="col-md-12 d-flex justify-content-end">
                                        <div
                                          className={"showdatapc" + items.token}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="col-1 px-0 border-left">
                            <div className="chip chip-outline chip-dark ml-05 mb-05">
                              <span className="chip-label px-0">
                                <button
                                  className=""
                                  onClick={() => deletestock(items.symbol)}
                                >
                                  <i className="fa fa-trash text-dark"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
        </div>

        {showAlert && (
          <AlertToast
            hideAlert={onAlertClose}
            showAlert={showAlert}
            message={textAlert}
            alertColor={alertColor}
          />
        )}
      </div>
    );
  } else {
    navigate("/");
  }
};

export default Watchlist;
