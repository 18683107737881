import axios from "axios";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { GetReports } from "../../../services";
import { fDateTime } from '../../../component/frontend/common/formatTime'
import { getUserCompleteAmmount } from "../../../services";


const Complete = () => {
  // console.log("props",Complete)
  const [alCompleteAmmount, setAllCompleteAmmount] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const GetTradeReports = async () => {
     
    const req = {
      "adminid": UserId,
      'type': 'withdraw'
    };

    const response = await getUserCompleteAmmount(req, AuthLogin);
    if (response.data.status) {
      setAllCompleteAmmount(response.data.data)
    
    }
  };



  const colums = [
    {
      name: "S. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name:' Date And Time',
      selector: row => fDateTime(row.updatedAt),
    },
    {
      name: "User Name",
      cell: row => row.username.username
    },
    {
      name: "Payment",
      selector: (row) => row.withdrawal_payment,
    },
    // {
    //   name: "Message",
    //   selector: (row) => row.admin_note,
    // },
    {
      name: "Status",
      selector: (row) => {
        if (row.status == 0) {
          return 'Pending'
         
        }
        else if (row.status == 1) {
          return 'Completed'
         
        }
        else if (row.status == 2) {
          return 'Reject'
        }
      },
    },


  ];


  useEffect(() => {
    GetTradeReports();
  }, [refresh]);


// Prevent dragging with CSS
const preventDragCSS = `
  .rdt_TableBody {
    user-select: none;
  }
  .rdt_TableRow {
    cursor: default;
  }
`;





// Inject the CSS
const styleTag = document.createElement("style");
styleTag.innerHTML = preventDragCSS;
document.head.appendChild(styleTag);



  // useEffect(() => {
  //   const table = document.querySelector('.rdt_Table');

  //   if (table) {
  //     const preventDrag = (e) => e.preventDefault();
  //     table.addEventListener('dragstart', preventDrag);
      

  //     return () => {
  //       table.removeEventListener('dragstart', preventDrag);
  //     };
  //   }
  // }, []);

 



  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="conteudo-box">
            <div className="conteudo-boxInner p-0">
              <DataTable
                columns={colums}
                data={alCompleteAmmount}
                fixedHeaderScrollHeight="400px"
                fixedHeader
                pagination
              />
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default Complete

