import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import { Link ,useNavigate} from "react-router-dom";
import { GetUserData,GetLicenseAccount } from "../../services";

import "./dashboard.css";


const Dashboard = () => {
 const [data, setData] = useState("");
 const [totatlLicense, setTotatlLicense] = useState("");
 const [usedLicense, setUsedLicense] = useState("");
  const navigate = useNavigate();
  const AuthLogin= localStorage.getItem("user_token");
  const UserId= localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  const Dashboarddata =async()=>{
    const dataa = {
      parentid: UserId,
    };
    const response = await GetUserData(dataa, AuthLogin);
    //console.log("response", response.data);
    setData(response.data)

    // var data = JSON.stringify({
    //   "parentid": "2"
    // });
    
    // var config = {
    //   method: 'post',
    //   url: 'http://180.149.241.128:5008/api/admin/getAllUserDetailsCount',
    //   headers: { 
    //     'x-access-token': AuthLogin, 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    
    // axios(config)
    // .then(function (response) {
    //   console.log("dashbojfdof",response.data);
    //   setData(response.data);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
 
    const req = {
      adminid: UserId,
    };
    const Licenseresponse = await GetLicenseAccount(req, AuthLogin);
    
    setTotatlLicense(Licenseresponse.data.totallicence)
    setUsedLicense(Licenseresponse.data.usedlicence)


  }
 useEffect(() => {
  Dashboarddata();
  
 }, [])

 console.log("usedlicence",usedLicense && usedLicense.usedlicence)
 
  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <strong className="conteudo-title">Dashboard</strong>
              
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="conteudo-box dashboard-box">
                  <div className="conteudo-boxInner">
                  <h1>{ data && data.allusercount}</h1>
                   <h3>Total Account</h3>
               {/* <Link to=""><i className="fa-sharp fa-solid fa-eye ps-2"></i> View All</Link> */}   
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="conteudo-box dashboard-box">
                  <div className="conteudo-boxInner">
                  <h1>{data && data.activeusercount}</h1>
                  <h3>Live Account</h3>
                {/*  <Link to=""><i className="fa-sharp fa-solid fa-eye ps-2"></i> View All</Link> */}   
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="conteudo-box dashboard-box">
                  <div className="conteudo-boxInner">
                  <h1>{ data && data.expiredusercount}</h1>
                  <h3>Expired Account</h3>
               {/*   <Link to=""><i className="fa-sharp fa-solid fa-eye ps-2"></i> View All</Link> */}
                  </div>
                </div>
              </div>
          
            </div>
            <div className="row ">
            <div className="col-md-4">
            <div className="conteudo-box dashboard-box">
              <div className="conteudo-boxInner">
              <h1>{ totatlLicense&& totatlLicense.totallicence}</h1>
               <h3> Total Licence</h3>
               {/*  <Link to=""><i className="fa-sharp fa-solid fa-eye ps-2"></i> View All</Link> */}
              </div>
            </div>
          </div>
            
              <div className="col-md-4">
                <div className="conteudo-box dashboard-box">
                  <div className="conteudo-boxInner">
                  <h1>{ totatlLicense && totatlLicense.totallicence - usedLicense.usedlicence}</h1>
                  <h3> Remaining Licence</h3>
                   {/*  <Link to=""><i className="fa-sharp fa-solid fa-eye ps-2"></i> View All</Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="conteudo-box dashboard-box">
                <div className="conteudo-boxInner">
                <h1>{ usedLicense && usedLicense.usedlicence!=null? usedLicense.usedlicence :"0"}</h1>
                <h3> Used Licence</h3>
               {/*   <Link to=""><i className="fa-sharp fa-solid fa-eye ps-2"></i> View All</Link> */}
                </div>
              </div>
            </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  );
        
};

export default Dashboard;
