import React, { useEffect, useMemo, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { getSystemUpdate } from '../../services';
import { encode } from 'js-base64';


const Sidebar = () => {

  const location = useLocation()
  const userid = localStorage.getItem('UserId');

  const [updatelogo,setUpdatelogo] = useState("")
 
  const getipdatedata = async () => {

  const data = {id: userid };
    try {
        const response = await getSystemUpdate(data);
        if (response.status) {
            
            setUpdatelogo(response.data[0].company_logo)
        } else {
            console.log("errr");
        }
    } catch (error) {
        console.error("Error in getipdatedata:", error);
    }
};



useEffect(() => {
    getipdatedata();
}, []);


  return (

    <div id="menu" className={` ${location.pathname === '/admin/adchangepassword' ? 'd-none' : 'd-block'}`}>
      <div className="menu-header">
        <span className="shape-remove-shadow_green" />
        <span className="shape-remove-shadow_white" />
        <div className="header-topo">
          <div className="header-controlMenu  col-sm-show col-xs-show">
            <span className="header-nomeEmpresa">
              {/* <img src="../../images/bearbulls.png" style={{ width: "75%" }} /> */}
              {updatelogo && <img src={updatelogo} alt="Updated Logo" style={{ width: "75%" }} />}
            </span>
            <button className="header-controlMenuButton">
              <i className="fa-sharp fa-solid fa-bars"></i>
            </button>
            <div className="clear" />
          </div>
        </div>
      </div>
      <div className="menu-box">
        <div className="menu-wrapper-inner">
          <div className="menu-wrapper">
            <div className="menu-slider">
              <div className="menu">
                <ul className="px-0" style={{ listStyleType: 'none' }}>
                  <li>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/admin/dashboard"
                      className="w-100"
                    >
                      <i className="fa-solid fa-gauge pr-1"></i>Dashboard
                    </NavLink></li>
                  <li>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/admin/reports"
                      className="w-100"
                    >
                      <i className="fa-solid fa-file-chart-column pr-1"></i>Reports
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/admin/services"
                      className="w-100"
                    >
                      <i className="fa-solid fa-gears pr-1"></i>Services
                    </NavLink></li> */}
                  {/* <li>
              <NavLink exact activeClassName="active" to="/admin/stratergy" className="w-100">
                  <i className="fa-solid fa-chess-board pr-1"></i>Strategy
                </NavLink></li>*/}
                  <li>
                    <NavLink exact activeClassName="active"
                      to="/admin/client"
                      className="w-100">
                      <i className="fa-solid fa-users pr-1"></i>Client
                    </NavLink></li>
                  <li>  <NavLink exact activeClassName="active" to="/admin/trade_history" className="w-100">
                    <i className="fa-solid fa-clock-rotate-left pr-1"></i>Trade
                    History
                  </NavLink></li>

                  <li>  <NavLink
                    exact
                    activeClassName="active"
                    to="/admin/trading_status"
                    className="w-100"
                  >
                    <i className="fa-solid fa-signal-bars pr-1"></i>Trading Status
                  </NavLink></li>

                  {/* <li> <NavLink
                    exact
                    activeClassName="active"
                    to="/admin/message_broadcast"
                    className="w-100"
                  >
                    <i className="fa-solid fa-tower-broadcast pr-1"></i>Message
                    Broadcast
                  </NavLink></li> */}
                  {/* <li>   <NavLink
                    exact
                    activeClassName="active"
                    to="/admin/transaction_license"
                    className="w-100"
                  >
                    <i className="fa-solid fa-id-card pr-1"></i>Transaction
                    License
                  </NavLink></li> */}

                  {/* <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/help_center"
                    className="w-100"
                  >
                    <i className="fa-solid fa-circle-info pr-1"></i>Help Center
                  </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                    exact
                    activeClassName="active"
                    to="/admin/authority"
                    className="w-100"
                  >
                    <i className="fa-solid fa-circle-info pr-1"></i> Admin Authority
                  </NavLink></li> */}
                  {/* <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/editsingls"
                    className="w-100"
                  >
                    <i class="fa-brands fa-think-peaks pr-1"></i>  Edit Signals
                  </NavLink></li> */}
                  <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/availableposition"
                    className="w-100"
                  >
                    <i class="fa-solid fa-chart-line  pr-1"></i> Available Positions
                  </NavLink></li>
                  <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/withdraw"
                    className="w-100"
                  >
                   <i class="fa-light fa-money-bill-transfer pr-1"></i> Withdrawal History
                  </NavLink></li>

                  <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/depositehistory"
                    className="w-100"
                  >
                  <i class="fa-light fa-money-bill-transfer pr-1"></i> Deposit History
                  </NavLink></li>

                  <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/system"
                    className="w-100"
                  >
                   
                  <i class="fa-solid fa-gear pr-1"></i> System
                  </NavLink></li>

                  <li><NavLink
                    exact
                    activeClassName="active"
                    to="/admin/signin"
                    className="w-100"
                  >
                  
                  <i class="fa-solid fa-user pr-1"></i>SignIn Request
                  </NavLink></li>

                </ul>




















                {/*      <ul>
                <li>
                  <div className="menu-item"><a href="#" className="menu-anchor" data-menu={1}>Dashboard</a>
                 
                  </div>
                </li>
                <li>
                  <div className="menu-item"><a href="#" className="menu-anchor" data-menu={2}>Item menu 2</a>
                    
                  </div>
                </li>
                <li>
                  <div className="menu-item"><a href="#" className>Item menu 3</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#" className="menu-anchor" data-menu={3}>Item menu 4</a>
                    </div>
                </li>
                <li>
                  <div className="menu-item"><a href="#" className>Item menu 5</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#" className="menu-anchor" data-menu={4}>Item menu 6</a>
                    
                  </div>
                </li>
              </ul>
            </div>
            <div className="submenu menu" data-menu={1}>
              <div className="submenu-back">
                <div className="menu-item">
                 
                  <a href="#" className="menu-back">BACK</a></div>
              </div>
              <ul>
                <li>
                  <div className="menu-item"><a href="#" className="menu-anchor" data-menu={5}>Subitem</a>
                    
                  </div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
              </ul>
            </div>
            <div className="submenu menu" data-menu={2}>
              <div className="submenu-back">
                <div className="menu-item">
                 
                  <a href="#" className="menu-back">BACK</a></div>
              </div>
              <ul>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
              </ul>
            </div>
            <div className="submenu menu" data-menu={3}>
              <div className="submenu-back">
                <div className="menu-item">
                 
                  <a href="#" className="menu-back">BACK</a></div>
              </div>
              <ul>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
              </ul>
            </div>
            <div className="submenu menu" data-menu={4}>
              <div className="submenu-back">
                <div className="menu-item">
                 
                  <a href="#" className="menu-back">BACK</a></div>
              </div>
              <ul>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
              </ul>
            </div>
            <div className="submenu menu" data-menu={5}>
              <div className="submenu-back">
                <div className="menu-item">
                  
                  <a href="#" className="menu-back">BACK</a></div>
              </div>
              <ul>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
                <li>
                  <div className="menu-item"><a href="#">Subitem</a></div>
                </li>
              </ul>*/}
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};


export default Sidebar;
