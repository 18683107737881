import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { GetReports } from "../../../services";
import { fDate } from '../../../component/frontend/common/formatTime';
import DataTableExtensions from "react-data-table-component-extensions";

const Active_clients = () => {
  const [allreports, setAllreports] = useState([]);
  const [search,setSearch] = useState("")




  const AuthLogin = localStorage.getItem("user_token");
  const UserId = localStorage.getItem("UserId");





  const GetTradeReports = async () => {
    const req = { parentid: UserId };
    const response = await GetReports(req, AuthLogin);
    // console.log("response", response.data.activeuser);
      const  filterdata = response.data.activeuser && response.data.activeuser.filter((item)=>{
          let match = search === "" ||  item.username.toLowerCase().includes(search.toLowerCase()) 
             
          return match
       })

    setAllreports(search ? filterdata :response.data.activeuser);

  };

  const columns = [
    { name: "S. No.", selector: (row, index) => index + 1, width: "80px" },
    { name: "User Name", selector: (row) => row.username },
    { name: "Start Date", selector: (row) => fDate(row.start_date) },
    { name: "End Date", selector: (row) => fDate(row.end_date) },
  ];

  const filterInput = document.querySelector('.filter-text');

  // Check if the input element exists
  if (filterInput) {
    // Set the new placeholder value
    filterInput.placeholder = "Search Here...";
  }

  useEffect(() => {
    GetTradeReports();
  }, [search]);

  useEffect(() => {
    const table = document.querySelector('.rdt_Table');

    if (table) {
      const preventDrag = (e) => e.preventDefault();
      table.addEventListener('dragstart', preventDrag);
      table.addEventListener('dragover', preventDrag);
      table.addEventListener('drop', preventDrag);

      return () => {
        table.removeEventListener('dragstart', preventDrag);
        table.removeEventListener('dragover', preventDrag);
        table.removeEventListener('drop', preventDrag);
      };
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="conteudo-box">
          <input
                  type="search"
                  className="form-control eventabc search-table"
                  placeholder="Search Here..."
                  value={search}
                  onChange={(e)=>setSearch(e.target.value)}
                  
                />
            <div className="conteudo-boxInner p-0">
           
              <DataTable
                columns={columns}
                data={allreports}
                fixedHeaderScrollHeight="400px"
                fixedHeader
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Active_clients;
