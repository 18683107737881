import axios from 'axios';
import React, {useState ,useEffect} from 'react'
import DataTable from 'react-data-table-component'
import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom'
import Live_clients from './Live_clients'
import Active_clients from './Active_clients'
import Expired_clients from './Expired_clients'


const Reports = () => {
  return (
    <div>
      <div id="conteudo">
        <div className="conteudo-inner">
          <div className="conteudo-wrapper">
            <div className="conteudo-titleSection">
              <strong className="conteudo-title">Reports</strong>
              
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="conteudo-box">
                  <div className="conteudo-boxInner">


                  <div className="section full mt-1">
                  
                    <div className="wide-block pt-2 pb-2">

                        <ul className="nav nav-tabs style1" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#liveclient" role="tab" aria-selected="true">
                                   All Live Clients
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#activeclient" role="tab" aria-selected="false">
                                  Active Live Clients
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#expiredclient" role="tab" aria-selected="false">
                                    Expired Live Clients
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content mt-0">
                            <div className="tab-pane fade active show" id="liveclient" role="tabpanel">
                           <Live_clients/>
                            </div>
                            <div className="tab-pane fade" id="activeclient" role="tabpanel">
                               <Active_clients/>
                            </div>
                            <div className="tab-pane fade" id="expiredclient" role="tabpanel">
                            <Expired_clients/>
                            </div>
                        </div>

                    </div>
                </div>
                 
                  </div>
                </div>
              </div>

            </div>
       

          </div>
        </div>
      </div>
    </div>
  )
}

export default Reports