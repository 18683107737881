import React, { useEffect, useState } from "react";
import {
  UserAvailabeFunds,
  PendingUserAvailabeFunds,
  RequestPaymentWithdrawal,
} from "../../../services";
import * as Config from "../common/Config";
import socketIOClient from "socket.io-client";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AlertToast from "../../../utils/AlertToast";
import Loader from "../../Loader/Loader";
import ShowPaymentHistory from "./ShowPaymentHistory";
// import * as Config from "../common/Config";
import { MdCurrencyRupee } from "react-icons/md";

const Dashboard_Home = () => {
  const [fund, setfund] = useState([]);
  const [userPendingBalresponse, setUserPendingBalresponse] = useState(0);

  // console.log("userPendingBalresponse", userPendingBalresponse);
  const [refresh, setRefresh] = useState(false);

  const [show, setShow] = useState(false);
  const [DepositeShow, setDepositeShow] = useState(false);
  const [upadateFund, setUpadateFund] = useState("");
  const [upadateDepositeFund, setUpadateDepositeFund] = useState("");
  const [upadateFundError, setUpadateFundError] = useState("");
  const [upadateDepositeFundError, setUpadateDepositeFundError] = useState("");
  const [upadateNote, setUpadateNote] = useState("");
  const [upadateDepositeNote, setUpadateDepositeNote] = useState("");
  // const [showLoder, setShowLoder] = useState(false);
  const [ButtonDisabled, setButtonDisabled] = useState(false);

  const handleClose = () => {
    setShow(false);
    setUpadateFundError("");
    setRefresh(!refresh);
  };
  const handleClose1 = () => {
    setDepositeShow(false);
    setUpadateDepositeFundError("");
    setRefresh(!refresh);
  };

  const handleShow = () => setShow(true);
  const handleShow1 = () => setDepositeShow(true);

  const accesstoken = localStorage.getItem("user_token");
  const userid = localStorage.getItem("UserId");
  const UserName = localStorage.getItem("UserName");

  // Alert Toast-----------------------------------------
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");

  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  const getUserAvilableFund = async () => {
    // const socket = socketIOClient(`${Config.base_url}`);
    // socket.on("res_pendingwithdraw_accept_message", (data) => {
    //   setShowAlert(true);
    //   setTextAlert(data);
    //   setAlertColor('success')
    //   setRefresh(!refresh)

    // });
    // socket.on("res_pendingwithdraw_reject_message", (data) => {
    //   setShowAlert(true);
    //   setTextAlert(data);
    //   setAlertColor('error')
    //   setRefresh(!refresh)

    // });
    // socket.on("res_pendingdeposite_accept_message", (data) => {
    //   setShowAlert(true);
    //   setTextAlert(data);
    //   setAlertColor('success')
    //   setRefresh(!refresh)
    // });
    // socket.on("res_pendingdeposite_reject_message", (data) => {
    //   setShowAlert(true);
    //   setTextAlert(data);
    //   setAlertColor('error')
    //   setRefresh(!refresh)

    //   // console.log("res_pendingdeposite_reject_message", data);
    // });

    const data = {
      userid: userid,
    };

    const response = await UserAvailabeFunds(data, accesstoken);
    if (response.data.status) {
      setfund(response.data);
    }

    const UserPendingBal_res = await PendingUserAvailabeFunds(
      data,
      accesstoken
    );
    // console.log("ddddd", UserPendingBal_res);
    if (UserPendingBal_res.data.status) {
      setUserPendingBalresponse(UserPendingBal_res.data.pendingBalance);
    }
  };

  const UpdateWithdrowFund = async (e) => {
    if (upadateFund === "") {
      setUpadateFundError("Please Enter A Valid  Amount");
      return;
    }
    if (
      upadateFund === "0" ||
      upadateFund === "00" ||
      upadateFund === "000" ||
      upadateFund === "0000" ||
      upadateFund === "00000" ||
      upadateFund === "000000" ||
      upadateFund === "0000000" ||
      upadateFund === "00000000" ||
      upadateFund === "000000000" ||
      upadateFund === "0000000000" ||
      upadateFund === "00000000000"
    ) {
      setUpadateFundError("Please Enter A Valid  Amount");
      return;
    }
    if (
      parseFloat(upadateFund) > parseFloat(fund?.availableCash?.toFixed(2)) ||
      parseFloat(upadateFund) < 0
    ) {
      alert("You Have A Insufficient Balance");
      return;
    }

    if (
      parseFloat(upadateFund) >
        parseFloat(fund?.availableCash?.toFixed(2) - userPendingBalresponse) ||
      parseFloat(upadateFund) < 0
    ) {
      alert(
        "You Have A Insufficient Balance , Already pending request Amount. " +
          userPendingBalresponse
      );
      return;
    }

    e.preventDefault();

    const socket = socketIOClient(Config.base_url);

    socket.emit(
      "Request withdrawal_message",
      `Rs.${upadateFund}  Withdraw Request from ${UserName} `
    );

    const data = {
      userid: userid,
      payment: upadateFund,
      note: upadateNote,
      type: "withdraw",
      notification_title: `Rs.${upadateFund}  Withdraw Request From  ${UserName} `,
    };

    const response = await RequestPaymentWithdrawal(
      data,
      accesstoken,
      setButtonDisabled(true)
    );

    if (response.data.status) {
      setButtonDisabled(false);
      setRefresh(!refresh);
      setShowAlert(true);
      setTextAlert(response.data.message);
      setAlertColor("success");
      setShow(false);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

 

  const SubmitDeposite = async (e) => {
    e.preventDefault();
    if (upadateDepositeFund === "") {
      setUpadateDepositeFundError("Please Enter A Valid  Amount");
      return;
    }

    if (
      upadateDepositeFund === "0" ||
      upadateDepositeFund === "00" ||
      upadateDepositeFund === "000" ||
      upadateDepositeFund === "0000" ||
      upadateDepositeFund === "00000" ||
      upadateDepositeFund === "000000" ||
      upadateDepositeFund === "0000000" ||
      upadateDepositeFund === "00000000" ||
      upadateDepositeFund === "000000000" ||
      upadateDepositeFund === "0000000000" ||
      upadateDepositeFund === "00000000000"
    ) {
      setUpadateDepositeFundError("Please Enter A Valid  Amount");
      return;
    }

    const socket = socketIOClient(Config.base_url);

    // console.log("data" , UserName );

    // socket.emit("req_deposite_message", `Rs.${upadateDepositeFund}  deposit request from ${fund.user_data[0].username} `);
    socket.emit(
      "Request deposite_message",
      `Rs.${upadateDepositeFund} Deposit Request From ${UserName} `
    );
    const data = {
      userid: userid,
      payment: upadateDepositeFund,
      note: upadateDepositeNote,
      type: "deposite",
      notification_title: `Rs.${upadateDepositeFund} Deposit Request from ${UserName} `,
    };

    const response = await RequestPaymentWithdrawal(
      data,
      accesstoken,
      setButtonDisabled(true)
    );

    if (response.data.status) {
      setButtonDisabled(false);
      // setShowLoder(false)
      setRefresh(!refresh);
      setShowAlert(true);
      setTextAlert(response.data.message);
      setAlertColor("success");
      setDepositeShow(false);
    }
  };

  useEffect(() => {
    getUserAvilableFund();
  }, [refresh]);

  const handleKeyPress = (event) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div>
      {/* {
        showLoder ?
          <Loader />
          : ""
      } */}
      {/* <Loader /> */}
      <div id="appCapsule">
        <div className="section full">
          {/* <img src="./images/Loader.gif" /> */}
          <div className="section-title">Dashboard</div>
        </div>
        {/* <div className="section inset text-center">
        <div className="wide-block pt-2 pb-2">
          <div className="content-header mb-05 pb-2">Market Today</div>
          <div className='row'>
          <div className='col-6'>
          <h4 className='mb-0'>NIFTY 50</h4>
          <p className='text-success mb-0'>18725.25</p>
          </div>
          <div className='col-6'>
          <h4 className='mb-0'>  BANK NIFTY</h4>
          <p className='text-danger mb-0'>18725.25</p>
          </div>
          </div>
          
          </div>
        </div> */}

        <div className="section full mt-4">
          <div className=" col-12 mx-auto">
            <div className="wide-block pt-2 pb-2 text-center">
              {/* <div className="content-header mb-05">Funds</div> */}
              <div className="rounded-lg relative bg-skin-lYellow">
                <div className="py-3 py-md-5 pl-5 d-flex items-center">
                  <div className="p-[10px] rounded-full bg-white shadow-sm">
                    <img
                      alt="icon-image"
                      src="https://cdn.angelone.in/bbe/homepage/web/user-star-fill.svg"
                    />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-medium text-left">Hello,</h3>
                    <p className="text-skin-base text-sm font-normal">
                      {UserName}
                    </p>
                  </div>
                </div>
                <div className="dashboard-img">
                  <img
                    className="h-full w-full"
                    alt="pattern-image"
                    src="https://cdn.angelone.in/bbe/homepage/web/img-refer-bg.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row py-3 align-items-center">
            <div className="col-md-3">
              <div className="d-flex justify-content-start justify-content-md-center  align-items-center py-3 py-md-0">
                <span className="invested portfolio-icon">
                  <i className="fa-light fa-wallet"></i>
                </span>
                <div className="pl-2 text-left">
                  {/* <h4 className='mb-0 text-left'>Invested</h4> */}
                  <h3 className="">Available Funds</h3>
                  <p className=" mb-0">
                    <MdCurrencyRupee />
                    {fund?.availableCash?.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-flex justify-content-start justify-content-md-center align-items-center py-3 py-md-0">
                <span className="bg-danger portfolio-icon">
                  <i className="fa-solid fa-chart-pie"></i>
                </span>
                <div className="pl-2 text-left">
                  {/* <h4 className='mb-0 text-left'>Invested</h4> */}
                  <h3 className="">Available Margin</h3>
                  <p className="text-success mb-0">
                    <MdCurrencyRupee />
                    {fund?.availablefund?.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="d-flex justify-content-start justify-content-md-center align-items-center py-3 py-md-0">
                <span className="return portfolio-icon">
                  <i className="fa-solid fa-arrow-trend-down"></i>
                </span>
                <div className="pl-2 text-left">
                  {/* <h4 className='mb-0 text-left'>Invested</h4> */}
                  <h3 className="">Margin Used</h3>
                  <p className="text-danger mb-0">
                    <MdCurrencyRupee />
                    {fund?.marginUsed?.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <div className="d-flex d-md-block  text-left py-3 py-md-0">
                <button
                  type="button"
                  className="btn btn-success mr-1 mb-1 mt-2 w-50"
                  onClick={() => handleShow1()}
                >
                  Deposit
                </button>
                <button
                  type="button"
                  className="btn btn-danger mr-1 mb-1 mt-2 w-50"
                  variant="primary"
                  onClick={handleShow}
                >
                  Withdraw
                </button>
              </div>
            </div>
            {/* <div className='col-12 text-center pb-5'>
                <h3>Available Funds</h3>
                <p className=' mb-0'><MdCurrencyRupee />{fund?.availableCash?.toFixed(2)}</p>

              </div> */}
          </div>
          {/* <div className='row'>
                <div className='col-md-8 col-12 mx-auto'>
                  <div className='row'>
                  <div className='col-6  text-center'>
                    <div className='dashboard-boxes dashboard-box'>
                <h4 className='mb-1'>Available Margin</h4>
                <p className='text-success mb-0'><MdCurrencyRupee />{fund?.availablefund?.toFixed(2)}</p>
                <button type="button" className="btn btn-success mr-1 mb-1 mt-2 w-50" onClick={() => handleShow1()} >Deposit</button>
              </div>
              </div>
              <div className='col-6 text-center'>
                <div className='dashboard-boxes dashboard-box'>
                <h4 className='mb-1'>Margin Used</h4>
                <p className='text-danger mb-0'><MdCurrencyRupee />{(fund?.marginUsed?.toFixed(2))}</p>
                <button type="button" className="btn btn-danger mr-1 mb-1 mt-2 w-50" variant="primary" onClick={handleShow}>Withdraw</button>
              </div>
              </div>
                  </div>
                </div>
              </div> */}
        </div>
      </div>

      {/* ----------------  WITHDRAW-FUND  ------------ */}

      <Modal
        show={show}
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation">
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="name5">
                  Payment
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="name5"
                  placeholder="Payment"
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    setUpadateFund(e.target.value);
                    setUpadateFundError("");
                  }}
                  onPaste={handlePaste}
                />
              </div>
              {upadateFundError ? (
                <p style={{ color: "red" }}>{upadateFundError}</p>
              ) : (
                ""
              )}
            </div>
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="email5">
                  Note
                </label>
                <textarea
                  placeholder="Note"
                  className="form-control"
                  onChange={(e) => setUpadateNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <button
            className="btn btn-primary btn-block w-auto"
            type="submit"
            onClick={(e) => UpdateWithdrowFund(e)}
            disabled={ButtonDisabled}
          >
            {" "}
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {/* ----------------  DEPOSITE-FUND  ------------ */}

      <Modal
        show={DepositeShow}
        onHide={() => {
          handleClose1();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Deposite Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="needs-validation">
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="name5">
                  Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="name5"
                  placeholder="Amount"
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    setUpadateDepositeFund(e.target.value);
                    setUpadateDepositeFundError("");
                  }}
                  onPaste={handlePaste}
                />
              </div>
              {upadateDepositeFundError ? (
                <p style={{ color: "red" }}>{upadateDepositeFundError}</p>
              ) : (
                ""
              )}
            </div>
            <div className="form-group boxed">
              <div className="input-wrapper">
                <label className="label" htmlFor="email5">
                  Note
                </label>
                <textarea
                  placeholder="Note"
                  className="form-control"
                  onChange={(e) => setUpadateDepositeNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose1()}>
            Close
          </Button>
          <button
            className="btn btn-primary w-auto"
            onClick={(e) => SubmitDeposite(e)}
            disabled={ButtonDisabled}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {/* <ShowPaymentHistory/> */}

      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </div>
  );
};

export default Dashboard_Home;
