import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { OrderList , EntryTokenEnterJson} from "../../../services";
import {dateFormate } from "../common/formatTime";
import * as Config from '../common/Config';

const Order = () => {

  const [odrdata,setOdrdata] = useState([]);

  const UserToken = localStorage.getItem("user_token");
  const user_id = localStorage.getItem("UserId");
  const AuthRole = localStorage.getItem("Role");

  // alert Toast
  // const [alertMsg, setAlertMsg] = useState(false)
  // const [alertColor, setAlertColor] = useState("")
  // const [textAlert, setTextAlert] = useState("")
  // const hideClose = () => {
  //   setAlertMsg(false)
  // }


  const GetOrderList = async () => {

    const data ={
      "userid" : user_id
    }
    const response = await OrderList(data ,UserToken);
    // console.log("OerderList ", response);
  
    if(response.data.status){
      setOdrdata(response.data.data)
    }
    // else{
    //         setOdrdata([response.data.data])
    // }


    const response1 = await EntryTokenEnterJson();
    // console.log("EntryTokenEnterJson ", response1);
    



  };


  
  useEffect(() => {
    GetOrderList();
  
  }, [])








return (
  <div id="appCapsule">
    
    <div className="section full">
  <div className="section-title">Orders</div>
 {/* <ul className="listview link-listview search-result mb-2">

  {
odrdata && odrdata.map((item)=>{
      const { type_entry_exit ,useridype ,totalamount ,symbol ,qty ,price ,order_id ,lot ,adminid ,expiry  } = item
      return(
        <li>
        <Link to="">
        <div className='row w-100 mx-auto'>
        <div className='col-8 px-0'>
          <div className='d-flex'>

        <h4 className="mb-05 ">{symbol}</h4>
        <h4 className="mb-05 ms-auto">{price}</h4>
       // <h4 className="mb-05 ">{totalamount}</h4> 
          </div>
    
            <div className="text-muted">
              <div className="mt-05"><strong className='badge badge-danger'>{price}</strong></div>
            </div></div>
        <div className='col-4 px-0'><div className="text-muted text-right">
      {item.phone}
        </div></div>
        </div>
        </Link>
        </li>
      )
    }

    )
  }
 
</ul> */ }


<div className="tab-pane " id="lined" role="tabpanel">
<div className="section full mt-2">
  <div className="wide-block pb-2 ">
    <ul className="nav nav-tabs lined" role="tablist">
      {/* <li className="nav-item">
        <a
          className="nav-link active" 
          data-toggle="tab"
          href="#all1"
          role="tab"
          style={{fontSize:'25px'}}
        >
       Executed
        </a>
      </li> */}
      {/* <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="tab"
          href="#progress1"
          role="tab"
        >
         Executed
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="tab"
          href="#success1"
          role="tab"
        >
          Trade Book
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link"
          data-toggle="tab"
          href="#failed1"
          role="tab"
        >
          Basket
        </a>
      </li> */}
    </ul>
    <div className="tab-content mt-2">
      <div
        className="tab-pane fade show active"
        id="all1"
        role="tabpanel"
      >
{
  odrdata &&  odrdata.length === 0 ? <div className="no-data">
  <p className='text-center'>No Data Found!</p>
    <img src="/images/nodata.webp" className=""/>
    </div>  :
         <>
        <ul className="listview image-listview">
  { odrdata.map((item)=>{
    const {createdAt,id,order_status, type_entry_exit ,useridype ,totalamount ,symbol ,qty ,price ,order_id ,lot ,adminid ,expiry ,type ,reject_reason } =item
return <>
      <li>
          <a  className="item">
             
              <div className="in align-items-center">
                  <div className='w-25 order-div'>
                      <header><div className="chip chip-outline chip-primary ml-05 mb-05">
                      <span className="chip-label">{type} </span>
                  </div>{order_status=="COMPLETED"?  <span className='ml-1'>{qty}/{qty}</span> :  <span className='ml-1'>0/{qty}</span>}</header>
                 <span className='order-symbol' >{symbol}</span> 
                <div>{order_status == 'REJECTED' ?
                  <span  style={{fontSize:'12px',fontWeight:'600'}}>REASON : {reject_reason}</span>
                   :""}
                   </div>  
                  </div>
                  <span  className='order-date'>{dateFormate (createdAt)}</span>
                  {/* {order_status == 'REJECTED' ?
                  <span  style={{fontSize:'12px'}}>Reason -{reject_reason}</span>
                   :""} */}
                  <span className="text-muted"><div className="chip chip-outline chip-danger ml-05 mb-05">
                  <span className="chip-label">{order_status}</span>
              </div>
              <p className='text-right mb-1' style={{fontSize:'14px'}}>{price}</p>
              </span>
              </div>
              
          </a>
          
      </li>
          </>
          })}
  </ul>
          </>
 } 

      </div>
    </div>
  </div>
</div>
</div>
  </div>

  {/* {
        alertMsg && (
          <AlertMessages
            hideAlert={hideClose}
            showAlert={alertMsg}
            message={textAlert}
            alertColor={alertColor}
          />
        )
      } */}
      </div>
  )
}

export default Order







// import React, { useState, useEffect } from 'react'
// import { Link, useParams } from 'react-router-dom'

// // import { OrderList } from "../../../services";



// const Order = () => {
//   const [odrdata, setOdrdata] = useState([]);

//   const UserToken = localStorage.getItem("user_token");
//   const user_id = localStorage.getItem("UserId");
//   // const AuthRole = localStorage.getItem("Role");


//   const GetOrderList = async () => {

//     const data ={
//       "userid" : user_id
//     }
//     const response = await OrderList(data ,UserToken);

//     console.log("orderLisdt ",  response.data );

 
//   };





//   useEffect(() => {
//     GetOrderList()

//   }, [])
//   return (
//     <div id="appCapsule">

//       <div className="section full">
//         <div className="section-title">Orders</div>
//         <ul className="listview link-listview search-result mb-2">

//           {
//             odrdata.map((item) => {
//               return (
//                 <li>
//                   <Link to="">
//                     <div className='row w-100 mx-auto'>
//                       <div className='col-8 px-0'>
//                         <h4 className="mb-05">{item.name}</h4>

//                         <div className="text-muted">
//                           <div className="mt-05"><strong className='badge badge-danger'>{item.website}</strong></div>
//                         </div></div>
//                       <div className='col-4 px-0'><div className="text-muted text-right">
//                         {item.phone}
//                       </div></div>
//                     </div>
//                   </Link>
//                 </li>
//               )
//             }

//             )
//           }

//         </ul>
//       </div>
//     </div>
//   )
// }

// export default Order