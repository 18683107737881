import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Watchlist from "../component/frontend/watchlist/Watchlist"; // import Footer from "./component/frontend/Footer";
import Dashboard_Home from "../component/frontend/dashboard/Dashboard_Home";
import Header from "../component/frontend/Header";
import Order from "../component/frontend/orders/Order";
import Portfolio from "../component/frontend/portfolio/Portfolio";
import BuySell from "../component/frontend/portfolio/BuySell";
import Profile from "../component/frontend/profile/Profile";
import Stock_detail from "../component/frontend/watchlist/Stock_detail";
import Confirm_trade from "../component/frontend/watchlist/Confirm_trade";
import Footer from "../component/frontend/Footer";
import Login from "../component/frontend/Login";
import ChangePassword from "../component/frontend/ChangePassword";
import SignIn from "../component/frontend/SignIn"
import Terms_condition from "../component/frontend/profile/Terms_condition";
import Privacy_policy from "../component/frontend/profile/Privacy_policy";
import Fund_history from "../component/frontend/profile/Fund_history";
import ClientProfile from "../component/frontend/profile/ClientProfile";
import Statement from "../component/frontend/profile/Statement";
import Errorpage from "../component/frontend/Errorpage";
import Payment from "../component/frontend/profile/Payment"
const Client = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role_id = localStorage.getItem("Role");
  const user_token = localStorage.getItem("user_token");

  return (
    <div>
      {location.pathname !== "/" && location.pathname !== "/signin" ? (
        <Header />
      ) : null}
      <Routes path="/user">

        <Route exact path="/" element={<Login />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/watchlist" element={<Watchlist />} />
        <Route exact path="/dashboard_home" element={<Dashboard_Home />} />
        <Route exact path="/order" element={<Order />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/buysell" element={<BuySell />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/stock_detail" element={<Stock_detail />} />
        <Route exact path="/statement" element={<Statement />} />
        <Route exact path="/terms_condition" element={<Terms_condition />} />
        <Route exact path="/privacy_policy" element={<Privacy_policy />} />
        <Route exact path="/fund_history" element={<Fund_history />} />
        <Route exact path="/clientprofile" element={<ClientProfile />} />
        <Route exact path="/confirm_trade" element={<Confirm_trade />} />
        <Route exact path="/changepassword" element={<ChangePassword />} />
        <Route exact path="/payment" element={<Payment/>} />
        <Route path="*" element={<Errorpage />} />
      </Routes>
      {location.pathname !== "/" &&
      location.pathname !== "*" &&
      location.pathname !== "/signin" ? (
        <Footer />
      ) : null}
    </div>
  );
};

export default Client;
