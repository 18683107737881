import React, { useState, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'
import AlertToast from '../../../utils/AlertToast'
import Modal from 'react-bootstrap/Modal';
import { getmargiRreqPerAdmin, UpdatemargiRreqPerAdmin } from "../../../services";

const MarginRequired = () => {



    const accesstoken = localStorage.getItem("user_token");
    const userid = localStorage.getItem("UserId");
    const AuthRole = localStorage.getItem("Role");


    const [NFOtime, setNFOtime] = useState('');
    const [MCXtime, setMCXtime] = useState('');
    const [CDStime, setCDStime] = useState('');



    const [show, setShow] = useState(false);
    const [margiPervalue, setMargiPerValue] = useState('');
    const [adminID, setAdminID] = useState('');
    const [refresh, setRefresh] = useState(false);

    //----------------- Alert Toast---------------------------
    const [showAlert, setShowAlert] = useState(false);
    const [textAlert, setTextAlert] = useState("");
    const [alertColor, setAlertColor] = useState("");

    const onAlertClose = e => {
        setShowAlert(false);
    }

    //----------------- Alert Toast---------------------------



    const handleClose = () => setShow(false);
    // const handleClose = () => setShow(true);
    const handleShow = (aid) => {
        
        setAdminID(aid)
        // alert(aid)
        setShow(true);
    }





    const UpdateMarginReqPer = async (e) => {
        e.preventDefault()

        const data = {
            "adminid": adminID.adminid,
            "NFO": NFOtime,
            "MCX": MCXtime,
            "CDS": CDStime
        }
        const response = await UpdatemargiRreqPerAdmin(data, accesstoken);
        if (response.data.status) {
            // console.log("UpdateMarginReqPer", response);
            setShowAlert(true);
            setTextAlert(response.data.msg);
            setAlertColor('success')
            setRefresh(!refresh)
            setShow(false);
        }
    }

    const getMarginReqPer = async () => {

        const data = {
            "adminid": userid
        }
        const response = await getmargiRreqPerAdmin(data, accesstoken);

        if (response.data.status) {
            setMargiPerValue(response.data.data[0]);
            // console.log("NFO", response.data);
            // console.log("MCX", response.datadata[0].MCX);
            // console.log("CDS", response.data.data[0].CDS);
            // setMcxmargin(response.data.data[0].MCX);
            // setCdsmargin(response.data.data[0].CDS);
        }

    }


    useEffect(() => {
        getMarginReqPer()
    }, [refresh])



    return (
        <div> <div className="row">
            <div className="col-md-12">
                <h2 className="p-2">Margin Required Percentage</h2>
                <table class="table table-bordered  ExittimeTable">
                    <thead class="exit_time_color">
                        <tr>
                            <th scope="col">NFO</th>
                            <th scope="col">MCX</th>
                            <th scope="col">CDS</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>

                            <td>{margiPervalue.NFO} %</td>
                            <td>{margiPervalue.MCX} %</td>
                            <td>{margiPervalue.CDS} %</td>
                            <td> <Tooltip title="View Detail" arrow><button className='rounded-circle' onClick={(e) => { handleShow(margiPervalue) }}><i class="fa-regular fa-pen-to-square" style={{ fontSize: '18px' }}></i></button></Tooltip>
                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
            <Modal size="lg" show={show} onHide={handleClose} className="trade-history-modal-table">
                <Modal.Header closeButton>

                    <button type="button" class="btn-close" aria-label="Close" onClick={handleClose}><i className="fa-solid fa-x"></i></button>
                    <Modal.Title>Margin Required Percentage</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div >
                        <ul className="list-group w-75  mx-auto  d-flex  justify-content-center ">
                            <li className="list-group-item  d-flex ms-5 align-items-center">
                                <span className='col-6'>
                                    NFO
                                </span >
                                <span className='col-6 ms-auto'>
                                    <input type="text" className=' form-control input-timer' onChange={(e) => setNFOtime(e.target.value.replace(/\D/g, ""))}  defaultValue={adminID.NFO}/>
                                </span>
                            </li>
                            <li className="list-group-item  d-flex ms-5 align-items-center ">
                                <span className='col-6'>
                                    MCX
                                </span >
                                <span className='col-6 ms-auto'>
                                    <input type="text" className=' form-control input-timer' onChange={(e) => setMCXtime(e.target.value.replace(/\D/g, ""))} defaultValue={adminID.MCX}/>
                                </span>
                            </li>
                            <li className="list-group-item  d-flex ms-5 align-items-center ">
                                <span className='col-6'>
                                    CDS
                                </span >
                                <span className='col-6 ms-auto'>
                                    <input type="text" className=' form-control input-timer' onChange={(e) => setCDStime(e.target.value.replace(/\D/g, ""))} defaultValue={adminID.CDS} 
/>
                                </span>
                            </li>
                        </ul>



                    </div>
                    <div className='col-md-12 mt-2  text-right  ms-auto'>
                        <button className='px-3  rounded  btn-5 ms' onClick={(e) => UpdateMarginReqPer(e)}>Update</button>
                    </div>
                </Modal.Body>

            </Modal>

            {showAlert &&
                <AlertToast
                    hideAlert={onAlertClose}
                    showAlert={showAlert}
                    message={textAlert}
                    alertColor={alertColor}
                />
            }

        </div>

    )
}

export default MarginRequired