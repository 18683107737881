import axios from 'axios';
import * as Config from "../utils/Config";


// Login Functionality 

export function login(data) {
    const user = {
        username: data.username,
        password: data.password
    }

    return axios.post(`${Config.baseUrl}api/auth/signin`, user)
        .then(res => {
            // console.log("msglogin",res);
            return res;
        })
        .catch(error => {
            // console.log('Login Error: ', error);
            return error.response
        })
}



export function GetLoginStatus(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/create/tradingstatuson`, data, {
        headers: {
            'x-access-token': token,
        },

        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export function GetTradingStatusOff(data, token) {
    return axios.post(`${Config.baseUrl}api/admin/create/tradingstatusoff`, data, {
        headers: {
            'x-access-token': token,
        },

        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export function ChangeAdminPassword(data, token) {
    return axios.post(`${Config.baseUrl}api/auth/changepassword`, data, {
        headers: {
            'x-access-token': token,
        },

        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}


export function getpassword(data, token) {

    return axios.post(`${Config.baseUrl}api/auth/getpassword`, data, {
        headers: {
            'x-access-token': token,
        },
          
        data: {},
    })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error.response
        })
}



