import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Errorpage = () => {
  const navigate = useNavigate();
  // const backbtn = () => {
  //   navigate('/');
  // }
  return (
    <div><div id="appCapsule">
      <div className="error-page">
        <img src="assets/img/404.png" alt="alt" className="m-auto imaged square w200" />
        <h1 className="title mb-1 mt-3">ERROR</h1>
        <div className="text mb-5">
          Page Not Found
        </div>
        <div className="fixed-footer">
          <div className="row">
            <div className="col-12">

              {/* <button onClick={backbtn} className="btn btn-primary btn-lg btn-block">Go Back</button> */}
            </div>
          </div>
        </div>
      </div>
    </div></div>

  )
}

export default Errorpage